// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".proactivePopup__proactivePopup___wZ8uh{width:100%}.proactivePopup__proactivePopupWrapper___lfpDy{width:100%;padding:2px 12px 0 2px;display:flex;flex-direction:column;align-items:stretch;justify-content:flex-end}.proactivePopup__proactivePopupWrapper___lfpDy:hover .proactivePopup__showCloseButton___oIqwO,.proactivePopup__proactivePopupWrapper___lfpDy.proactivePopup__mobile___\\+8EbH .proactivePopup__showCloseButton___oIqwO{margin-bottom:10px;transform:translateY(0);transition:transform 700ms,opacity 700ms;opacity:1}@media all and (-ms-high-contrast: none){.proactivePopup__proactivePopupWrapper___lfpDy:hover .proactivePopup__showCloseButton___oIqwO,.proactivePopup__proactivePopupWrapper___lfpDy.proactivePopup__mobile___\\+8EbH .proactivePopup__showCloseButton___oIqwO{margin-bottom:0;transform:none;transition:opacity 700ms;opacity:1}}body.align-chat-icon-left .proactivePopup__proactivePopupWrapper___lfpDy{align-items:flex-start;padding-right:2px;padding-left:12px}html[dir=\"rtl\"] body.align-chat-icon-left .proactivePopup__proactivePopupWrapper___lfpDy{align-items:flex-end}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"proactivePopupIframeWidth": "276px",
	"proactivePopup": "proactivePopup__proactivePopup___wZ8uh",
	"proactivePopupWrapper": "proactivePopup__proactivePopupWrapper___lfpDy",
	"showCloseButton": "proactivePopup__showCloseButton___oIqwO",
	"mobile": "proactivePopup__mobile___+8EbH"
};
export default ___CSS_LOADER_EXPORT___;

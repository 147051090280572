export function getSentry() {
  return import(/* webpackChunkName: "sentry" */ '@sentry/browser');
}

export function getSentryHttpClient() {
  return import(
    /* webpackChunkName: "sentryHttpClient" */ '@sentry/integrations'
  );
}

export function initSentry(key, orgId?) {
  return Promise.all([getSentry(), getSentryHttpClient()]).then(
    ([Sentry, { HttpClient }]) => {
      // todo set org and user info (tracking token? customer id?)
      if (!Sentry.getCurrentHub().getClient()) {
        Sentry.init({
          dsn: `https://${key}@o38471.ingest.sentry.io/6150287`, // 6150287 is project ID. not sure what o38471 is
          environment: process.env.SENTRY_ENV,
          release: process.env.VERSION,
          initialScope: {
            tags: { orgId },
          },
          beforeSend(event) {
            if (event.exception?.values?.length) {
              // Even though values is an array, we can safely assume that the first one is our error.
              // The only time there will be more than one exeption is with linked errors, which we are not using.
              const exception = event.exception.values[0];
              if (!exception.stacktrace) {
                return null;
              }
            }
            return event;
          },
          integrations: [
            new HttpClient({ failedRequestStatusCodes: [400, 599] }),
          ],
          ignoreErrors: [
            'TypeError: Failed to fetch',
            'TypeError: NetworkError when attempting to fetch resource.',
            'TypeError: cancelled',
            'TypeError: Abgebrochen', // German
            'TypeError: Kumottu', // Finnish
            'TypeError: annullato', // Italian
            'TypeError: annulleret', // Danish
            'TypeError: avbrutt', // Norwegian
            'TypeError: geannuleerd', // Dutch
            'TypeError: poništeno', // Bosnian
            'TypeError: vazgeçildi', // Turkish
            'TypeError: visszavonva', // Hungarian
            'TypeError: скасовано', // Ukrainian
            'TypeError: ถูกยกเลิก', // Thai
            'TypeError: キャンセルしました', // Japanese
            'TypeError: 已取消', // Chinese
            'TypeError: 취소됨', // Korean
          ],
        });
      }
      return Sentry;
    },
  );
}

export function captureException(error, context) {
  return getSentry().then((Sentry) => {
    return Sentry.captureException(error, context);
  });
}

import { srcDocSet } from './srcDocPolyfillBackup';

export const isBrowserIE = (): boolean => {
  const { userAgent } = window.navigator;
  return userAgent.indexOf('MSIE') > 0 || userAgent.indexOf('Trident') > 0;
};
export const supportsIntlRelativeTimeFormat = () => {
  return !!window?.Intl?.RelativeTimeFormat;
};

export const installRelativeTimeFormatPolyfills = (): Promise<unknown> => {
  if (supportsIntlRelativeTimeFormat()) return Promise.resolve();

  return import(
    /* webpackChunkName: "intl-relativetimeformat" */ '@formatjs/intl-relativetimeformat/polyfill'
  );
};

export const installPolyfills = (): Promise<unknown> => {
  if (isBrowserIE()) {
    return import(/* webpackChunkName: "polyfills" */ './polyfills').catch(
      (error) => `An error occurred while loading polyfills, ${error}`,
    );
  }

  return Promise.resolve();
};

export const setIframeSrcDoc = (
  iframe: HTMLIFrameElement,
  content: string,
): void => {
  const iframeToUpdate = iframe;
  if (isBrowserIE()) {
    srcDocSet(iframeToUpdate, content);
  }

  iframeToUpdate.srcdoc = content;
};

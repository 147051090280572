import { createOptimisticMessage } from 'widget_main/screens/MessageThread/helpers';
import { MessageCallbackResponse } from 'core_main/sdk/send_message/types';
import { ConversationEntity } from 'widget_main/store/types';

import type {
  FetchMessageHistoryHelperResponse,
  MessageHistoryEntity,
} from '../types';

import { fetchAllMessagesFromKApi, fetchAllMessagesFromPubNub } from './api';
import * as assistantHelpers from './assistant';

export const selectOldestMessage = (options: MessageHistoryEntity) => {
  const { messages, messagesById } = options;
  const oldestMessageId = messages[0];
  return messagesById?.[oldestMessageId];
};

export const mergeAndSortByMessageTimestamps = (
  previousMessages: Record<string, MessageCallbackResponse>,
  newMessages: Record<string, MessageCallbackResponse>,
) => {
  const messagesById = {
    ...previousMessages,
  };
  Object.assign(messagesById, newMessages);
  const messages = Object.keys(messagesById)
    .slice()
    .sort((firstId, secondId) => {
      const firstMsg = messagesById[firstId];
      const secondMsg = messagesById[secondId];

      const firstDate = new Date(firstMsg.createdAt);
      const secondDate = new Date(secondMsg.createdAt);
      return firstDate < secondDate ? -1 : 1;
    });

  return {
    messagesById,
    messages,
  };
};

const constructAssistedConversationWithOptimisticMessageError = (
  conversation: ConversationEntity,
) => {
  const { conversationId } = conversation;
  return assistantHelpers.getActiveAssistant().then((initAssistantPayload) => {
    if (initAssistantPayload) {
      const optimisticMessage = createOptimisticMessage({
        conversationId,
        body: conversation.preview,
        isInAssistantMode: true,
        initAssistantPayload,
      });
      optimisticMessage.showError = true;
      return {
        initialMessages: initAssistantPayload.initialMessages,
        optimisticMessages: [optimisticMessage],
      };
    }
    const optimisticMessage = createOptimisticMessage({
      conversationId,
      body: conversation.preview,
      isInAssistantMode: false,
    });
    optimisticMessage.showError = true;
    return {
      optimisticMessages: [optimisticMessage],
    };
  });
};

const checkForFailedAssistedConversation = (
  conversation: ConversationEntity,
): Promise<FetchMessageHistoryHelperResponse> => {
  const initialMessageMissingOnAssistedConversation =
    conversation?.isInAssistantMode && !conversation?.initialMessages?.length;
  const emptyMessageState = {
    messages: [],
    messagesById: {},
    hasAllMessages: true,
  };
  if (initialMessageMissingOnAssistedConversation) {
    return constructAssistedConversationWithOptimisticMessageError(
      conversation,
    ).then((assistedConversationWithOptimisticMessage) => {
      return {
        ...emptyMessageState,
        ...assistedConversationWithOptimisticMessage,
      };
    });
  }
  return Promise.resolve(emptyMessageState);
};

const getMessagesFromPubNub = (
  conversation: ConversationEntity,
  messagesById: Record<string, MessageCallbackResponse>,
): Promise<FetchMessageHistoryHelperResponse> => {
  return fetchAllMessagesFromPubNub(conversation.conversationId).then(
    (response) => {
      const newMessagesById = response.messages.reduce((acc, val) => {
        acc[val.messageId] = acc[val.messageId] || val;
        return acc;
      }, {});

      const merged = mergeAndSortByMessageTimestamps(
        messagesById,
        newMessagesById,
      );
      const oldestMessage = selectOldestMessage({
        messages: merged.messages,
        messagesById: merged.messagesById,
      });

      // JB TODO: not all conversations have directionType currently, so this will often fallback to the conversation
      // - this is fine temporarily since `hasAllMessages` will be set after fetching messages from our API
      // - followup: the BE needs to always place directionType on the message, both inbound and outbound
      const hasFirstMessage =
        oldestMessage?.directionType?.startsWith('initial-') ||
        conversation.hasAllMessages;
      return {
        ...merged,
        satisfaction: response.satisfaction,
        hasAllMessages: hasFirstMessage,
      };
    },
  );
};

const getMessagesFromKApi = (
  conversation: ConversationEntity,
  messagesById: Record<string, MessageCallbackResponse>,
): Promise<FetchMessageHistoryHelperResponse> => {
  const options = { count: 100 }; // use large page size to reduce number of requests
  return fetchAllMessagesFromKApi(conversation.conversationId, options).then(
    (response) => {
      const newMessagesById = response.messages.reduce((acc, val) => {
        acc[val.messageId] = acc[val.messageId] || val;
        return acc;
      }, {});

      const merged = mergeAndSortByMessageTimestamps(
        messagesById,
        newMessagesById,
      );

      const oldestMessage = selectOldestMessage({
        messages: merged.messages,
        messagesById: merged.messagesById,
      });

      // JB TODO: this should always be true, if it's not perhaps we want to log to Sentry
      // - this will not be true until we add directionType to the api response, so we can't log it yet
      const hasFirstMessage =
        oldestMessage?.directionType?.startsWith('initial-');
      if (!hasFirstMessage) {
        // console.log('ERROR: no first message found in our API!!!');
      }
      return {
        ...merged,
        satisfaction: response.satisfaction?.timetoken // TODO: this isn't actually on the response view, so this won't work. We need to fetch satisfaction separately on init
          ? response.satisfaction
          : conversation.satisfaction,
        hasAllMessages: true, // trust that our API has all the messages that we know about
      };
    },
  );
};

// Historical Message fetching logic:
// 1. Check pubnub's history API first to see if it has all historical messages
// 2. If error with Pubnub API, fallback to our API
// 3. If Pubnub API doesn't have all messages, fallback to our API. It is source of truth
const fetchAllMessages = (
  conversation: ConversationEntity,
  messagesById: Record<string, MessageCallbackResponse>,
): Promise<FetchMessageHistoryHelperResponse> => {
  return getMessagesFromPubNub(conversation, messagesById)
    .catch((_) => {
      // swallowing error as they are logged to sentry in core_main
      return getMessagesFromKApi(conversation, messagesById);
    })
    .then((result) => {
      if (!result.hasAllMessages) {
        return getMessagesFromKApi(conversation, messagesById);
      }
      return result;
    })
    .then((result) => {
      // if there are no messages on this conversation, check if we had a failed assisted convo
      // this is from a legacy implementation, thus it's original logic is preserved
      const noMessages = result.messages?.length === 0;
      if (noMessages) {
        return checkForFailedAssistedConversation(conversation);
      }

      return result;
    });
};

// JB TODO: deprecate this name and just use fetchAllMessages
// eventually we should poll w/ this function for reliability
export const fetchMessageHistoryHelper = (
  conversation: ConversationEntity,
  messagesById: Record<string, MessageCallbackResponse>,
): Promise<FetchMessageHistoryHelperResponse> => {
  return fetchAllMessages(conversation, messagesById);
};

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".markdown__markdownBody___dH3bf p,.markdown__markdownBody___dH3bf ol,.markdown__markdownBody___dH3bf ul{margin-bottom:20px}.markdown__markdownBody___dH3bf ul,.markdown__markdownBody___dH3bf ol{margin-top:20px}.markdown__markdownBody___dH3bf p:last-child{margin-bottom:0}.markdown__markdownBody___dH3bf img{cursor:zoom-in}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"markdownBody": "markdown__markdownBody___dH3bf"
};
export default ___CSS_LOADER_EXPORT___;

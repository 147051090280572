// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".satisfactionMessage__container___rzXPT{display:flex;align-items:center;flex-direction:column;text-align:center;max-width:280px}.satisfactionMessage__satisfactionContainer___GQED9{padding:15px;width:100%}.satisfactionMessage__feedbackButton___qsBir{margin:0;border-top-left-radius:0;border-top-right-radius:0;border-bottom-left-radius:0}.satisfactionMessage__changeFeedback___YKhNB{border-top:.5px solid #e9eff5;padding:15px 0}.satisfactionMessage__introduction___s4Ns3{font-size:14px;color:var(--agent-bubble-primary-font-color);font-weight:500}.satisfactionMessage__ratingPrompt___qW9Rb{font-size:12px;color:var(--agent-bubble-primary-font-color);margin:10px 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"container": "satisfactionMessage__container___rzXPT",
	"satisfactionContainer": "satisfactionMessage__satisfactionContainer___GQED9",
	"feedbackButton": "satisfactionMessage__feedbackButton___qsBir",
	"changeFeedback": "satisfactionMessage__changeFeedback___YKhNB",
	"introduction": "satisfactionMessage__introduction___s4Ns3",
	"ratingPrompt": "satisfactionMessage__ratingPrompt___qW9Rb"
};
export default ___CSS_LOADER_EXPORT___;

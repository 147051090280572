import { h, Component, createRef, RefObject } from 'preact';
import debounce from 'lodash.debounce';
import produce, { Draft } from 'immer';
import classNames from 'classnames';
import SendIcon from 'widget_main/globals/assets/send.svg';
import Button from 'widget_main/components/ui/Button';
import TextArea from 'widget_main/components/ui/TextArea';
import Text from 'widget_main/components/Text';
import { Localizer } from 'preact-i18n';
import { getKustomerCore } from 'widget_main/globals/helpers';
import { MessageThreadInputProps, MessageThreadInputState } from './types';
import AttachmentPreviews from './AttachmentPreviews';
import AttachmentButton from './AttachmentButton';
import styles from './messageThreadInput.scss';
import messages from './messages';
import EmojiPicker from './EmojiPicker/EmojiPicker';

const TEXT_AREA_ID = 'messageThreadInputTextArea';
let textAreaElement;

const kustomerCore = getKustomerCore();

class MessageThreadInput extends Component<
  MessageThreadInputProps,
  MessageThreadInputState
> {
  textAreaRef: RefObject<HTMLTextAreaElement>;

  debouncedUserTyping: () => void;

  debouncedUserStoppedTyping: () => void;

  constructor() {
    super();
    this.state = {
      body: '',
      attachments: [],
      errorMessage: undefined,
    };
    this.textAreaRef = createRef();
    this.debouncedUserTyping = debounce(this.userTyping, 1000, {
      leading: true,
      maxWait: 2500,
    });

    this.debouncedUserStoppedTyping = debounce(this.userStoppedTyping, 5000);
  }

  focusOnTextArea = (cursorLocation?) => {
    textAreaElement = document.getElementById(TEXT_AREA_ID);

    if (textAreaElement && this.props.autoFocus !== false) {
      textAreaElement.focus();
      if (cursorLocation) {
        textAreaElement.selectionStart = cursorLocation;
        textAreaElement.selectionEnd = cursorLocation;
      }
    }
  };

  componentDidUpdate(prevProps) {
    const { isInputHidden: prevIsInputHidden } = prevProps;
    const { isInputHidden } = this.props;

    if (prevIsInputHidden === true && isInputHidden === false) {
      this.focusOnTextArea();
    }
  }

  componentDidMount() {
    this.focusOnTextArea();
  }

  componentWillUnmount() {
    const { attachments } = this.state;
    attachments.forEach((attachment) => {
      if (attachment.url) URL.revokeObjectURL(attachment.url);
    });
  }

  showError = (errorMessage) => {
    this.setState(
      produce((draftState: Draft<MessageThreadInputState>) => {
        draftState.errorMessage = errorMessage;
      }),
    );
  };

  addAttachment = (file) => {
    const previewUrl = URL.createObjectURL(file);

    this.setState(
      produce((draftState: Draft<MessageThreadInputState>) => {
        draftState.attachments.push({ file, url: previewUrl });
      }),
    );
  };

  removeAttachment = (removedAttachment) => {
    URL.revokeObjectURL(removedAttachment.url);

    this.setState(
      produce((draftState: Draft<MessageThreadInputState>) => {
        draftState.attachments = draftState.attachments.filter(
          (attachment) => attachment.file !== removedAttachment.file,
        );
      }),
    );
  };

  userTyping = () => {
    const { conversationId } = this.props;

    if (!conversationId) return;

    kustomerCore?.sendTypingActivity({ conversationId, typing: true });
  };

  userStoppedTyping = () => {
    const { conversationId } = this.props;

    if (!conversationId) return;

    kustomerCore?.sendTypingActivity({ conversationId, typing: false });
  };

  handleEmoji = (emoji) => {
    const { selectionStart, selectionEnd } = textAreaElement;
    const currentInputValue = this.state.body;
    // insert emoji at place of cursor
    const newValue =
      currentInputValue.slice(0, selectionStart) +
      emoji +
      currentInputValue.slice(selectionEnd);
    this.handleChange({ target: { value: newValue } }, () => {
      this.focusOnTextArea(selectionStart + emoji.length); // emojis can have different char lengths 🤯
    });
  };

  resizeTextarea() {
    // We are manipulating the dom directly here, which is frowned upon, but I couldn't get it working any other way.
    // If the person reading this in the future (even my future self) has a better way to do this, then by all means I
    // won't be insulted if you change this code.
    if (this.textAreaRef.current) {
      // We need to set it to 0 first in order to properly measure the scrollHeight, otherwise when a user DELETES text
      // the textarea will not shrink.
      this.textAreaRef.current.style.height = '0px';
      this.textAreaRef.current.style.height = `${this.textAreaRef.current?.scrollHeight}px`;
    }
  }

  handleChange = (e, cb?: () => void) => {
    const { conversationId } = this.props;
    const body = e.target.value;

    if (conversationId) {
      this.debouncedUserTyping();
      this.debouncedUserStoppedTyping();
    }

    this.setState(
      produce((draftState: Draft<MessageThreadInputState>) => {
        draftState.body = body;
      }),
      () => {
        this.resizeTextarea();
        if (cb) cb();
      },
    );
  };

  handleSubmit = () => {
    const { body, attachments } = this.state;
    const { onSubmit, isSendDisabled } = this.props;

    if (isSendDisabled) return;

    if (body || attachments.length) {
      onSubmit({
        body,
        attachments,
      });

      this.setState(
        produce((draftState: Draft<MessageThreadInputState>) => {
          draftState.body = '';
          draftState.attachments = [];
          draftState.errorMessage = undefined;
        }),
        () => {
          this.resizeTextarea();
        },
      );
    }
  };

  renderAttachmentsButton() {
    const { showAttachmentButton } = this.props;
    const { attachments } = this.state;
    return showAttachmentButton ? (
      <div className={styles.attachmentButtonWrapper}>
        <AttachmentButton
          addAttachment={this.addAttachment}
          attachments={attachments}
          showError={this.showError}
        />
      </div>
    ) : null;
  }

  renderAttachmentsPreview() {
    const { attachments } = this.state;

    if (!attachments.length) {
      return null;
    }

    return (
      <AttachmentPreviews
        attachments={attachments}
        removeAttachment={this.removeAttachment}
      />
    );
  }

  render(
    {
      isInputHidden,
      isSendDisabled,
      className,
      chatAvailability,
      draft,
    }: MessageThreadInputProps,
    { body, attachments, errorMessage }: MessageThreadInputState,
  ) {
    if (draft?.message) {
      this.setState(
        produce((draftState: Draft<MessageThreadInputState>) => {
          draftState.body = draft.message || '';
          if (typeof draft?.callback === 'function') {
            draft.callback();
          }
        }),
      );
    }

    const placeholder = (chatAvailability === 'offline'
      ? Text({
          id: 'messageThreadInputLeaveAMessage',
          defaultMessage: messages.messageThreadInputLeaveAMessage,
        })
      : Text({
          id: 'messageThreadInputSendAMessage',
          defaultMessage: messages.messageThreadInputSendAMessage,
        })) as unknown as string;

    return (
      <div
        class={classNames(
          styles.messageInput,
          {
            [styles.hasError]: !!errorMessage,
            [styles.isInputHidden]: isInputHidden,
          },
          className,
        )}
      >
        {this.renderAttachmentsPreview()}
        <div className={styles.lowerSection}>
          {this.renderAttachmentsButton()}
          <div className={styles.attachmentButtonWrapper}>
            <EmojiPicker
              onEmoji={this.handleEmoji}
              onClose={this.focusOnTextArea}
            />
          </div>
          <Localizer>
            <TextArea
              ref={this.textAreaRef}
              id={TEXT_AREA_ID}
              className={styles.textArea}
              ariaLabel={placeholder}
              placeholder={errorMessage || placeholder}
              onChange={this.handleChange}
              onClick={this.props.onClick}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={this.props.autoFocus}
              onEnter={this.handleSubmit}
              value={body}
            />
          </Localizer>
          <div class={styles.buttonWrapper}>
            <Localizer>
              <Button
                variant="primary"
                className={styles.submitButton}
                ariaLabel={Text({
                  id: 'submitButtonAriaLabel',
                  defaultMessage: messages.submitButtonAriaLabel,
                })}
                onClick={this.handleSubmit}
                disabled={(!body && !attachments.length) || isSendDisabled}
              >
                <SendIcon className={styles.sendIcon} />
              </Button>
            </Localizer>
          </div>
        </div>
      </div>
    );
  }
}

export default MessageThreadInput;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".satisfactionMessageModal__modal___gMiqF{display:flex;align-items:center;justify-content:center;flex-direction:column;width:100%;max-height:100%}.satisfactionMessageModal__modalBody___Z1JC5{overflow-y:auto}.satisfactionMessageModal__followupQuestions___\\+-6ay{padding:0 10px}.satisfactionMessageModal__questionContainer___iKnsk{margin:25px 0}.satisfactionMessageModal__questionLabel___JiuDQ{font-size:14px;color:#222;margin-bottom:10px}.satisfactionMessageModal__textArea___82j0D{height:90px;width:100%;border-radius:8px;padding:10px}.satisfactionMessageModal__submitButton___LAzas{width:100%;height:40px;margin-top:15px;font-size:16px;box-shadow:0 2px 6px 0 rgba(201,201,201,.5)}.satisfactionMessageModal__checkCircleIcon___N-cAz{width:50px;height:50px;color:#00bd67;margin-bottom:15px}.satisfactionMessageModal__requiredTag___cfmM-{color:red}.satisfactionMessageModal__successScreen___vh7fM{text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"modal": "satisfactionMessageModal__modal___gMiqF",
	"modalBody": "satisfactionMessageModal__modalBody___Z1JC5",
	"followupQuestions": "satisfactionMessageModal__followupQuestions___+-6ay",
	"questionContainer": "satisfactionMessageModal__questionContainer___iKnsk",
	"questionLabel": "satisfactionMessageModal__questionLabel___JiuDQ",
	"textArea": "satisfactionMessageModal__textArea___82j0D",
	"submitButton": "satisfactionMessageModal__submitButton___LAzas",
	"checkCircleIcon": "satisfactionMessageModal__checkCircleIcon___N-cAz",
	"requiredTag": "satisfactionMessageModal__requiredTag___cfmM-",
	"successScreen": "satisfactionMessageModal__successScreen___vh7fM"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chatRootIcon__chatIconWrapper___clZcp{display:flex;justify-content:center;align-items:center;position:relative;padding:10px;transition:transform 150ms ease-in-out 0s;filter:drop-shadow(rgba(0, 0, 0, 0.3) 1px 1px 3px)}.chatRootIcon__chatIconWrapper___clZcp:hover{transform:translateY(-4px) scale(1.04)}.chatRootIcon__pointer___QslJf{cursor:pointer}@keyframes chatRootIcon__bounce___bIU9S{0%{transform:scale(1)}50%{transform:translateY(-4px) scale(1.04)}100%{transform:scale(1)}}.chatRootIcon__proactivePopupActive___kYx8Y{animation-name:chatRootIcon__bounce___bIU9S;animation-duration:500ms;animation-timing-function:ease-in-out}.chatRootIcon__proactivePopupWrapper___OBAgl{display:flex;flex-direction:column}.chatRootIcon__unreadProp___oFBiW{position:absolute;top:6px;right:7px;background-color:var(--primary-button-background-color)}html[dir=\"rtl\"] .chatRootIcon__unreadProp___oFBiW{left:7px;right:initial}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"chatIconWrapper": "chatRootIcon__chatIconWrapper___clZcp",
	"pointer": "chatRootIcon__pointer___QslJf",
	"proactivePopupActive": "chatRootIcon__proactivePopupActive___kYx8Y",
	"bounce": "chatRootIcon__bounce___bIU9S",
	"proactivePopupWrapper": "chatRootIcon__proactivePopupWrapper___OBAgl",
	"unreadProp": "chatRootIcon__unreadProp___oFBiW"
};
export default ___CSS_LOADER_EXPORT___;

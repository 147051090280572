import { LocalesModules } from './types';

const EMPTY_OBJECT = {};

const locales: LocalesModules = {
  en_us: {
    getMessages: () => Promise.resolve(EMPTY_OBJECT), // force use of defaultMessage
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_en" */ '@formatjs/intl-relativetimeformat/locale-data/en'
      ),
  },
  en: {
    getMessages: () => Promise.resolve(EMPTY_OBJECT), // force use of defaultMessage
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_en" */ '@formatjs/intl-relativetimeformat/locale-data/en'
      ),
  },
  en_gb: {
    getMessages: () => Promise.resolve(EMPTY_OBJECT), // force use of defaultMessage
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_en" */ '@formatjs/intl-relativetimeformat/locale-data/en'
      ),
  },
  en_ca: {
    getMessages: () => Promise.resolve(EMPTY_OBJECT), // force use of defaultMessage
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_en" */ '@formatjs/intl-relativetimeformat/locale-data/en'
      ),
  },
  es: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_es" */ './translations/split/es.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_es" */ '@formatjs/intl-relativetimeformat/locale-data/es'
      ),
  },
  es_es: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_es" */ './translations/split/es.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_es" */ '@formatjs/intl-relativetimeformat/locale-data/es'
      ),
  },
  fr: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_fr" */ './translations/split/fr.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_fr" */ '@formatjs/intl-relativetimeformat/locale-data/fr'
      ),
  },
  fr_ca: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_fr" */ './translations/split/fr.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_fr" */ '@formatjs/intl-relativetimeformat/locale-data/fr'
      ),
  },
  de: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_de" */ './translations/split/de.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_de" */ '@formatjs/intl-relativetimeformat/locale-data/de'
      ),
  },
  pt: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_pt" */ './translations/split/pt.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_pt" */ '@formatjs/intl-relativetimeformat/locale-data/pt'
      ),
  },
  ar: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_ar" */ './translations/split/ar.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_ar" */ '@formatjs/intl-relativetimeformat/locale-data/ar'
      ),
  },
  he: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_he" */ './translations/split/he.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_he" */ '@formatjs/intl-relativetimeformat/locale-data/he'
      ),
  },
  af: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_af" */ './translations/split/af.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "loclae_af" */ '@formatjs/intl-relativetimeformat/locale-data/af'
      ),
  },
  be: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_be" */ './translations/split/be.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_be" */ '@formatjs/intl-relativetimeformat/locale-data/be'
      ),
  },
  bg: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_bg" */ './translations/split/bg.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_bg" */ '@formatjs/intl-relativetimeformat/locale-data/bg'
      ),
  },
  bs: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_bs" */ './translations/split/bs.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_bs" */ '@formatjs/intl-relativetimeformat/locale-data/bs'
      ),
  },
  ca: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_ca" */ './translations/split/ca.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_ca" */ '@formatjs/intl-relativetimeformat/locale-data/ca'
      ),
  },
  cs: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_cs" */ './translations/split/cs.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_cs" */ '@formatjs/intl-relativetimeformat/locale-data/cs'
      ),
  },
  da: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_da" */ './translations/split/da.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_da" */ '@formatjs/intl-relativetimeformat/locale-data/da'
      ),
  },
  nl: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_nl" */ './translations/split/nl.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_nl" */ '@formatjs/intl-relativetimeformat/locale-data/nl'
      ),
  },
  el: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_el" */ './translations/split/el.json'),

    getLocale: () =>
      import(
        /* webpackChunkName: "locale_el" */ '@formatjs/intl-relativetimeformat/locale-data/el'
      ),
  },
  et: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_et" */ './translations/split/et.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_et" */ '@formatjs/intl-relativetimeformat/locale-data/et'
      ),
  },
  fi: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_fi" */ './translations/split/fi.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_fi" */ '@formatjs/intl-relativetimeformat/locale-data/fi'
      ),
  },
  fil: {
    getMessages: () =>
      import(
        /* webpackChunkName: "lang_fil" */ './translations/split/fil.json'
      ),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_fil" */ '@formatjs/intl-relativetimeformat/locale-data/fil'
      ),
  },
  hi: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_hi" */ './translations/split/hi.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_hi" */ '@formatjs/intl-relativetimeformat/locale-data/hi'
      ),
  },
  hr: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_hr" */ './translations/split/hr.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_hr" */ '@formatjs/intl-relativetimeformat/locale-data/hr'
      ),
  },
  hu: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_hu" */ './translations/split/hu.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_hu" */ '@formatjs/intl-relativetimeformat/locale-data/hu'
      ),
  },
  id: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_id" */ './translations/split/id.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_id" */ '@formatjs/intl-relativetimeformat/locale-data/id'
      ),
  },
  is: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_is" */ './translations/split/is.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_is" */ '@formatjs/intl-relativetimeformat/locale-data/is'
      ),
  },
  it: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_it" */ './translations/split/it.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_it" */ '@formatjs/intl-relativetimeformat/locale-data/it'
      ),
  },
  ja: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_ja" */ './translations/split/ja.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_ja" */ '@formatjs/intl-relativetimeformat/locale-data/ja'
      ),
  },
  ka: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_ka" */ './translations/split/ka.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_ka" */ '@formatjs/intl-relativetimeformat/locale-data/ka'
      ),
  },
  kk: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_kk" */ './translations/split/kk.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_kk" */ '@formatjs/intl-relativetimeformat/locale-data/kk'
      ),
  },
  kn: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_kn" */ './translations/split/kn.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_kn" */ '@formatjs/intl-relativetimeformat/locale-data/kn'
      ),
  },
  ko: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_ko" */ './translations/split/ko.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_ko" */ '@formatjs/intl-relativetimeformat/locale-data/ko'
      ),
  },
  ky: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_ky" */ './translations/split/ky.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_ky" */ '@formatjs/intl-relativetimeformat/locale-data/ky'
      ),
  },
  lt: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_lt" */ './translations/split/lt.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_lt" */ '@formatjs/intl-relativetimeformat/locale-data/lt'
      ),
  },
  lv: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_lv" */ './translations/split/lv.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_lv" */ '@formatjs/intl-relativetimeformat/locale-data/lv'
      ),
  },
  no: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_no" */ './translations/split/no.json'),
  },
  pa: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_pa" */ './translations/split/pa.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_pa" */ '@formatjs/intl-relativetimeformat/locale-data/pa'
      ),
  },
  pl: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_pl" */ './translations/split/pl.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_pl" */ '@formatjs/intl-relativetimeformat/locale-data/pl'
      ),
  },
  pt_br: {
    getMessages: () =>
      import(
        /* webpackChunkName: "lang_pt_br" */ './translations/split/pt_br.json'
      ),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_pt" */ '@formatjs/intl-relativetimeformat/locale-data/pt'
      ),
  },
  ro: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_ro" */ './translations/split/ro.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_ro" */ '@formatjs/intl-relativetimeformat/locale-data/ro'
      ),
  },
  ru: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_ru" */ './translations/split/ru.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_ru" */ '@formatjs/intl-relativetimeformat/locale-data/ru'
      ),
  },
  sk: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_sk" */ './translations/split/sk.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_sk" */ '@formatjs/intl-relativetimeformat/locale-data/sk'
      ),
  },
  sl: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_sl" */ './translations/split/sl.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_sl" */ '@formatjs/intl-relativetimeformat/locale-data/sl'
      ),
  },
  sq: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_sq" */ './translations/split/sq.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_sq" */ '@formatjs/intl-relativetimeformat/locale-data/sq'
      ),
  },
  sr: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_sr" */ './translations/split/sr.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_sr" */ '@formatjs/intl-relativetimeformat/locale-data/sr'
      ),
  },
  sv: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_sv" */ './translations/split/sv.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_sv" */ '@formatjs/intl-relativetimeformat/locale-data/sv'
      ),
  },
  sw: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_sw" */ './translations/split/sw.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_sw" */ '@formatjs/intl-relativetimeformat/locale-data/sw'
      ),
  },
  te: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_te" */ './translations/split/te.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_te" */ '@formatjs/intl-relativetimeformat/locale-data/te'
      ),
  },
  th: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_th" */ './translations/split/th.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_th" */ '@formatjs/intl-relativetimeformat/locale-data/th'
      ),
  },
  tr: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_tr" */ './translations/split/tr.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_tr" */ '@formatjs/intl-relativetimeformat/locale-data/tr'
      ),
  },
  tw: {
    getMessages: () =>
      import(
        /* webpackChunkName: "lang_tw" */ './translations/split/zh_tw.json'
      ),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_ak" */ '@formatjs/intl-relativetimeformat/locale-data/zh'
      ),
  },
  uk: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_uk" */ './translations/split/uk.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_uk" */ '@formatjs/intl-relativetimeformat/locale-data/uk'
      ),
  },
  ur: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_ur" */ './translations/split/ur.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_ur" */ '@formatjs/intl-relativetimeformat/locale-data/ur'
      ),
  },
  uz: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_uz" */ './translations/split/uz.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_uz" */ '@formatjs/intl-relativetimeformat/locale-data/uz-Latn'
      ),
  },
  vi: {
    getMessages: () =>
      import(/* webpackChunkName: "lang_vi" */ './translations/split/vi.json'),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_vi" */ '@formatjs/intl-relativetimeformat/locale-data/vi'
      ),
  },
  zh_cn: {
    getMessages: () =>
      import(
        /* webpackChunkName: "lang_zh_cn" */ './translations/split/zh_cn.json'
      ),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_zh" */ '@formatjs/intl-relativetimeformat/locale-data/zh'
      ),
  },
  zh_tw: {
    getMessages: () =>
      import(
        /* webpackChunkName: "lang_zh_tw" */ './translations/split/zh_tw.json'
      ),
    getLocale: () =>
      import(
        /* webpackChunkName: "locale_zh" */ '@formatjs/intl-relativetimeformat/locale-data/zh'
      ),
  },
};

export default locales;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button__button___TubGu{border-radius:8px}.button__button___TubGu[aria-disabled=true]{opacity:.7;cursor:default}.button__block___PNJTT{width:100%}.button__primary___8WBD3{background-color:var(--primary-button-background-color);color:var(--primary-button-font-color)}.button__primary___8WBD3:hover:not([aria-disabled=true]){background-color:var(--primary-button-background-hover-color)}.button__primary___8WBD3:focus:not([aria-disabled=true]){background-color:var(--primary-button-background-hover-color)}.button__secondary___9iJ7w{background-color:#717070;color:#fff}.button__secondary___9iJ7w:hover:not([aria-disabled=true]){background-color:#4d4d51}.button__danger___FR4Lt{background-color:#fff;border:1px solid red;color:red}.button__danger___FR4Lt:hover:not([aria-disabled=true]){background-color:red;color:#fff}.button__link___IysyO{padding:0;background-color:#fff;border:0;color:var(--primary-button-background-color)}.button__link___IysyO:hover:not([aria-disabled=true]){text-decoration:underline}.button__outlinePrimary___T54\\+y{border:1px solid var(--primary-button-background-color);color:var(--primary-button-background-color);background-color:#fff}.button__outlinePrimary___T54\\+y:hover:not([aria-disabled=true]){background-color:var(--primary-button-background-color);color:var(--primary-button-font-color)}.button__outlinePrimary___T54\\+y:focus:not([aria-disabled=true]){background-color:var(--primary-button-background-color);color:var(--primary-button-font-color)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "button__primary___8WBD3",
	"secondary": "button__secondary___9iJ7w",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"button": "button__button___TubGu",
	"block": "button__block___PNJTT",
	"danger": "button__danger___FR4Lt",
	"link": "button__link___IysyO",
	"outlinePrimary": "button__outlinePrimary___T54+y"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chatBubble__chatBubble___3Sk1b{max-width:250px;border-radius:12px;padding:10px;overflow-wrap:break-word}.chatBubble__chatBubble___3Sk1b ul{margin-left:20px}.chatBubble__chatBubble___3Sk1b ol{margin-left:20px}.chatBubble__chatBubble___3Sk1b img{width:100%}.chatBubble__inbound___tuLth,.chatBubble__outbound___4CScV{display:flex;flex-direction:column;margin-bottom:5px;direction:ltr}.chatBubble__messageMeta___jJ9Y3{margin-top:5px;display:flex;font-size:12px;color:#717070}.chatBubble__botSparkle___x\\+riV{margin:5px 5px 0 0}.chatBubble__separator___i07q9{margin:0 5px}.chatBubble__optimisticError___iugjw{color:red;font-size:12px;margin-top:5px}.chatBubble__inbound___tuLth .chatBubble__avatarAndBubble___cNYb\\+{display:flex;align-items:flex-end}.chatBubble__inbound___tuLth .chatBubble__chatBubble___3Sk1b{border-bottom-left-radius:0;background-color:var(--agent-bubble-background-color);box-shadow:0 2px 6px 0 rgba(201,201,201,.5);margin-left:35px;color:var(--agent-bubble-primary-font-color)}.chatBubble__inbound___tuLth .chatBubble__chatBubble___3Sk1b.chatBubble__showAvatar___wd2Xf{margin-left:0}.chatBubble__inbound___tuLth .chatBubble__messageMeta___jJ9Y3{margin-left:35px}.chatBubble__inbound___tuLth a{color:#336dec}.chatBubble__identifier___b5gF6 strong{font-weight:600}.chatBubble__outbound___4CScV{justify-content:flex-end;align-items:flex-end}.chatBubble__outbound___4CScV .chatBubble__chatBubble___3Sk1b{border-bottom-right-radius:0;background-color:var(--customer-bubble-background-color);color:var(--customer-bubble-primary-font-color)}.chatBubble__avatarWrapper___dG-3L{margin-right:5px}html[dir=\"rtl\"] .chatBubble__botSparkle___x\\+riV .chatBubble__chatBubble___3Sk1b,html[dir=\"rtl\"] .chatBubble__inbound___tuLth .chatBubble__chatBubble___3Sk1b,html[dir=\"rtl\"] .chatBubble__outbound___4CScV .chatBubble__chatBubble___3Sk1b{direction:rtl}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"chatBubble": "chatBubble__chatBubble___3Sk1b",
	"inbound": "chatBubble__inbound___tuLth",
	"outbound": "chatBubble__outbound___4CScV",
	"messageMeta": "chatBubble__messageMeta___jJ9Y3",
	"botSparkle": "chatBubble__botSparkle___x+riV",
	"separator": "chatBubble__separator___i07q9",
	"optimisticError": "chatBubble__optimisticError___iugjw",
	"avatarAndBubble": "chatBubble__avatarAndBubble___cNYb+",
	"showAvatar": "chatBubble__showAvatar___wd2Xf",
	"identifier": "chatBubble__identifier___b5gF6",
	"avatarWrapper": "chatBubble__avatarWrapper___dG-3L"
};
export default ___CSS_LOADER_EXPORT___;

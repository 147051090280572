import { h } from 'preact';
import Button from 'widget_main/components/ui/Button';
import CloseIcon from 'widget_main/globals/assets/close.svg';
import PdfIcon from 'widget_main/globals/assets/pdfFile.svg';
import VideoIcon from 'widget_main/globals/assets/playBoxMultiple.svg';
import FilePresentIcon from 'widget_main/globals/assets/filePresent.svg';
import { useTranslations } from 'i18n/hooks';
import { Attachment } from 'core_main/sdk/send_message/types';
import * as helpers from './helpers';

import styles from './attachmentPreviews.scss';
import messages from './messages';

interface AttachmentPreviewsProps {
  attachments: Attachment[];
  removeAttachment: (attachment: Attachment) => void;
}

const AttachmentPreviews = ({
  attachments,
  removeAttachment,
}: AttachmentPreviewsProps) => {
  const translations = useTranslations(messages);

  const renderCloseButton = (attachment) => {
    return (
      <Button
        variant="secondary"
        className={styles.removeAttachmentButton}
        ariaLabel={translations.attachmentPreviewsAriaLabel}
        onClick={() => removeAttachment(attachment)}
      >
        <CloseIcon class={styles.closeIcon} />
      </Button>
    );
  };

  const renderImagePreview = (attachment: Attachment) => {
    return (
      <div
        role="img"
        className={styles.imagePreview}
        style={{
          backgroundImage: `url(${attachment.url})`,
        }}
      />
    );
  };

  const renderFilePreview = (attachment: Attachment) => {
    const contentType = attachment?.file?.type;
    const isVideo = contentType?.includes('video');
    const isPDF = contentType?.includes('pdf');

    let icon = <FilePresentIcon className={styles.pdfIcon} />;

    if (isVideo) {
      icon = <VideoIcon class={styles.pdfIcon} width={32} height={32} />;
    } else if (isPDF) {
      icon = <PdfIcon class={styles.pdfIcon} width={32} height={32} />;
    }

    return (
      <div class={styles.file}>
        {icon}
        <div class={styles.pdfDetails}>
          <div class={styles.fileName}>{attachment.file.name}</div>
          <div class={styles.fileSize}>
            {helpers.getFileSizeInKB(attachment.file.size)}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.attachments}>
      {attachments.map((attachment, index) => {
        return (
          <div class={styles.attachment} key={index}>
            {renderCloseButton(attachment)}
            {attachment.file.type.includes('image')
              ? renderImagePreview(attachment)
              : renderFilePreview(attachment)}
          </div>
        );
      })}
    </div>
  );
};

export default AttachmentPreviews;

import { h } from 'preact';
import ErrorPlane from '../../globals/assets/errorPlane.svg';
import Button from '../ui/Button';
import Text from '../Text';
import styles from './errorBoundary.scss';
import messages from './messages';

export const ErrorBoundaryComponent = ({ restartApp }) => {
  const showRefreshButton = typeof restartApp === 'function';
  return (
    <div className={styles.container}>
      <ErrorPlane />
      <p className={styles.header}>
        <Text
          id="errorBoundaryHeader"
          defaultMessage={messages.errorBoundaryHeader}
        />
      </p>
      <p className={styles.subHeader}>
        {showRefreshButton ? (
          <Text
            id="errorBoundaryWithRefreshSubHeader"
            defaultMessage={messages.errorBoundaryWithRefreshSubHeader}
          />
        ) : (
          <Text
            id="errorBoundarySubHeader"
            defaultMessage={messages.errorBoundarySubHeader}
          />
        )}
      </p>
      {showRefreshButton ? (
        <Button
          className={styles.refreshButton}
          variant={'primary'}
          type={'button'}
          onClick={restartApp}
          ariaLabel={messages.refreshButtonLabel}
        >
          <Text
            id="refreshButtonLabel"
            defaultMessage={messages.refreshButtonLabel}
          />
        </Button>
      ) : null}
    </div>
  );
};

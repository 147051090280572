import {
  KB_ARTICLE_PATH,
  KB_DEFLECT_ARTICLE_PATH,
} from 'widget_main/globals/constants';
import { Page } from 'widget_main/store/types';
import { AppState } from './types';

export const INITIAL_STATE: AppState = {
  widgetActions: {} as AppState['widgetActions'],
  isChatHidden: true,
  isMobile: false,
  showWidget: false,
  navigation: {
    currentConversationId: undefined,
    page: undefined,
  } as AppState['navigation'],
  conversations: {
    data: {},
    pages: {
      next: 1,
    },
  } as AppState['conversations'],
  messages: {
    messageData: {},
  } as AppState['messages'],
  unreadCounts: {
    total: 0,
    conversations: {},
  },
  kb: {
    categories: {},
    articles: {},
    articleSearchResults: undefined,
    articleSearchInput: '',
    currentCategoryId: undefined,
    currentArticleId: undefined,
  } as AppState['kb'],
  connection: 'online',
  loading: false,
  retryCount: 0,
};

export const DEFAULT_CHAT_ICON_SIZE = 60;

export const ALLOWED_MESSAGE_EVENT_ORIGINS = [
  'https://cdn.kustomerapp.com', // Prod
  'https://cdn.helpapp.io', // Staging
  'https://cdn.helpsimply.com', // QA
  'http://localhost:9000', // dev
];

export const EXPAND_NAV_ITEMS: Page[] = [
  KB_ARTICLE_PATH,
  KB_DEFLECT_ARTICLE_PATH,
];

export const MESSAGE_POLL_SETTINGS = {
  interval: 60000,
};

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".attachmentButton__attachmentButton___sL2gk{cursor:pointer;opacity:.8}.attachmentButton__attachmentButton___sL2gk:hover:enabled{opacity:1}.attachmentButton__fileInput___eUirA{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"attachmentButton": "attachmentButton__attachmentButton___sL2gk",
	"fileInput": "attachmentButton__fileInput___eUirA"
};
export default ___CSS_LOADER_EXPORT___;

import { h } from 'preact';
import { useContext } from 'preact/hooks';
import {
  SettingsContext,
  NavigationContext,
  ConversationsContext,
  ChatAvailabilityContext,
  MessagesContext,
} from 'widget_main/store';
import Avatar from 'widget_main/components/Avatar';
import {
  getLastAgentMessageInConversation,
  getMessageAgentInformation,
} from 'widget_main/globals/helpers';
import styles from './messageThreadHeader.scss';

const MessageThreadHeader = () => {
  const chatAvailability = useContext(ChatAvailabilityContext);
  const chatSettings = useContext(SettingsContext);
  const messagesState = useContext(MessagesContext);
  const navigation = useContext(NavigationContext);
  const conversations = useContext(ConversationsContext);

  const waitMessage = chatSettings?.waitMessage;

  const { currentConversationId } = navigation;
  const currentConversation = currentConversationId
    ? conversations.data[currentConversationId]
    : undefined;

  const agentMessage = getLastAgentMessageInConversation(
    currentConversation,
    messagesState,
  );
  const lastMessageAgentData = getMessageAgentInformation(
    agentMessage?.sentBy ?? currentConversation?.lastResponder,
    chatSettings,
    navigation.initAssistantPayload,
  );

  const renderWaitMessage = () => {
    if (agentMessage && !agentMessage?.appGenerated) {
      return null;
    }

    return waitMessage;
  };

  return (
    <div class={styles.messageThreadHeader}>
      <Avatar
        className={styles.avatar}
        size={45}
        avatarUrl={lastMessageAgentData?.avatarUrl}
        userDisplayName={lastMessageAgentData?.displayName}
      />
      <div class={styles.info}>
        <h4 class={styles.teamName}>{lastMessageAgentData?.displayName}</h4>
        <span class={styles.waitMessage}>
          {chatAvailability === 'online'
            ? renderWaitMessage()
            : chatSettings?.offhoursMessage}
        </span>
      </div>
    </div>
  );
};

export default MessageThreadHeader;

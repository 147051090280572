import { h } from 'preact';
import {
  createPortal,
  memo,
  useEffect,
  useCallback,
  useRef,
} from 'preact/compat';
import * as keyboardEventHelpers from 'widget_main/globals/keyboardEvents';
import CloseIcon from 'widget_main/globals/assets/close.svg';
import { useTranslations } from 'i18n/hooks';

import { useFocusLock } from 'widget_main/globals/dom';
import messages from './messages';
import { MediaContent } from '../types';

const modalStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 2147483001,
  border: '0 none',
  padding: '0',
  cursor: 'pointer',
};

const closeButtonStyles = {
  position: 'fixed',
  top: '20px',
  backgroundColor: 'transparent',
  border: 0,
  cursor: 'pointer',
  left: 'initial',
  right: '20px',
  padding: '0',
};

const imageStyles = {
  maxWidth: '95%',
  maxHeight: '90%',
};

interface MediaPreviewModalProps {
  src: string;
  show: boolean;
  onHide: () => void;
  type: MediaContent;
}

const MediaPreviewModal = ({
  show,
  onHide,
  src,
  type,
}: MediaPreviewModalProps) => {
  const parentBody = window.parent.document.getElementsByTagName('body')[0];
  const translations = useTranslations(messages);

  const containerRef = useRef<HTMLButtonElement>(null);

  const handleCloseKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (
        keyboardEventHelpers.isEnterKey(e) ||
        keyboardEventHelpers.isEscKey(e)
      ) {
        e.preventDefault();
        e.stopPropagation();

        onHide();
      }
    },
    [onHide],
  );
  const focusLockRef = useFocusLock();

  useEffect(() => {
    if (show) {
      containerRef.current?.focus();
    }
  }, [show]);

  if (!show || !parentBody) {
    return null;
  }

  const renderCloseButton = () => {
    if (document.dir === 'rtl') {
      closeButtonStyles.left = '20px';
      closeButtonStyles.right = 'initial';
    }

    return (
      <button type="button" style={closeButtonStyles}>
        <CloseIcon width={30} height={30} />
      </button>
    );
  };

  const onContainerClick = (e: Event) => {
    const target = e?.target as HTMLElement;
    const id = target?.id;

    if (id === 'videoPreview') {
      return;
    }

    onHide();
  };

  const renderPreview = () => {
    if (type === 'image') {
      return (
        <img
          style={imageStyles}
          alt={translations.mediaPreviewAltText}
          src={src}
        />
      );
    }

    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        id="videoPreview"
        autoPlay
        playsInline
        style={imageStyles}
        alt={translations.mediaPreviewAltText}
        controls
        src={src}
      />
    );
  };
  return createPortal(
    <div
      ref={focusLockRef}
      role="dialog"
      aria-label={translations.mediaPreviewAltText}
      aria-modal="true"
    >
      <button
        ref={containerRef}
        onClick={onContainerClick}
        onKeyDown={handleCloseKeyDown}
        style={modalStyles}
        type="button"
      >
        {renderCloseButton()}
        {renderPreview()}
      </button>
    </div>,
    parentBody,
  );
};

export default memo(MediaPreviewModal);

import { h, JSX, Ref } from 'preact';
import { memo } from 'preact/compat';
import classNames from 'classnames';
import * as keyboardEventHelpers from 'widget_main/globals/keyboardEvents';
import styles from './titleBarButton.scss';

interface TitleBarButtonProps {
  ariaLabel: string;
  onClick?: (e: Event) => unknown;
  href?: string;
  className?: string;
  children?: JSX.Element | JSX.Element[] | string;
  disabled?: boolean;
  buttonRef?: Ref<HTMLButtonElement>;
}

const TitleBarButton = ({
  ariaLabel,
  onClick,
  href,
  className,
  children,
  disabled,
  buttonRef,
}: TitleBarButtonProps) => {
  const handleKeyPress = (e: KeyboardEvent) => {
    if (onClick && keyboardEventHelpers.isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();

      onClick(e);
    }
  };
  return href ? (
    <a
      type="button"
      tabIndex={0}
      title={ariaLabel}
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      className={classNames(styles.titleBarButton, className)}
    >
      {children}
    </a>
  ) : (
    <button
      ref={buttonRef}
      type="button"
      tabIndex={0}
      aria-label={ariaLabel}
      className={classNames(styles.titleBarButton, className)}
      onClick={onClick}
      onKeyPress={handleKeyPress}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

TitleBarButton.defaultProps = {
  variant: 'primary',
  type: 'button',
};

export default memo(TitleBarButton);

import { h } from 'preact';
import { useState, useContext } from 'preact/hooks';
import classNames from 'classnames';

import {
  ChatAvailabilityContext,
  ConnectionContext,
  ProactiveChatContext,
} from 'widget_main/store';
import { OFFLINE } from 'widget_main/globals/constants';

import styles from './proactiveChatInput.scss';
import { isInputHidden } from './helpers';
import MessageThreadInput from '../../MessageThreadInput/MessageThreadInput';
import { MessageOptions } from '../../MessageThreadInput/types';
import TemplateActions from '../../../screens/MessageThread/TemplateActions';

const ProactiveChatInput = ({ isMobile, show }) => {
  const connection = useContext(ConnectionContext);
  const chatAvailability = useContext(ChatAvailabilityContext);

  const [isCreatingConversation, setIsCreatingConversation] = useState(false);
  const { proactiveChat, handoffToMessageThread, onCloseProactiveChat } =
    useContext(ProactiveChatContext);

  if (!proactiveChat || !proactiveChat.initResponse) return null;
  const handleSubmit = (messageOptions: MessageOptions) => {
    setIsCreatingConversation(true);
    if (proactiveChat.initResponse) {
      handoffToMessageThread(
        proactiveChat.initResponse?.assistant,
        messageOptions.body,
        messageOptions.payload,
        proactiveChat.initResponse,
        null,
        (/* result, error */) => {
          setIsCreatingConversation(false);
          onCloseProactiveChat();
        },
      );
    }
  };
  const handleOnClickInput = isMobile
    ? () => handleSubmit({ body: '' })
    : undefined;

  return (
    <div
      className={classNames(styles.inputContainer, {
        [styles.expand]: show,
      })}
    >
      <TemplateActions
        onSubmit={handleSubmit}
        disabled={connection === OFFLINE || isCreatingConversation}
        supportedTypes={['quick_replies']}
        className={styles.templateActions}
        initialMessages={proactiveChat.initResponse?.initialMessages}
      />

      <MessageThreadInput
        onSubmit={handleSubmit}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={false}
        onClick={handleOnClickInput}
        showAttachmentButton={false}
        isInputHidden={isInputHidden(proactiveChat)}
        isSendDisabled={isCreatingConversation || connection === OFFLINE}
        chatAvailability={chatAvailability}
        className={styles.inputField}
      />
    </div>
  );
};

export default ProactiveChatInput;

import { h, Component } from 'preact';
import produce, { Draft } from 'immer';
import globalMessages from 'widget_main/globals/messages';
import Modal from 'widget_main/components/ui/Modal';
import TextArea from 'widget_main/components/ui/TextArea';
import Checkbox from 'widget_main/components/ui/Checkbox';
import Radio from 'widget_main/components/ui/Radio/Radio';
import Button from 'widget_main/components/ui/Button';
import Text from 'widget_main/components/Text';
import CheckCircleIcon from 'widget_main/globals/assets/checkCircle.svg';

import {
  SatisfactionCallbackResponse,
  SatisfactionFormAnswer,
} from 'core_main/api/satisfaction/types';
import { SubmitSatisfactionFormParameters } from 'core_main/sdk/submit_satisfaction_form/types';
import styles from './satisfactionMessageModal.scss';
import { MAX_ANSWER_LENGTH } from './constants';
import * as helpers from './helpers';
import messages from './messages';

interface SatisfactionMessageModalProps {
  show: boolean;
  onSubmit: (options: Partial<SubmitSatisfactionFormParameters>) => void;
  onHide: () => void;
  satisfaction: SatisfactionCallbackResponse;
}

interface SatisfactionMessageModalState {
  showSuccess: boolean;
  answers: SatisfactionFormAnswer[];
}

class SatisfactionMessageModal extends Component<
  SatisfactionMessageModalProps,
  SatisfactionMessageModalState
> {
  constructor(props) {
    super(props);

    this.state = {
      showSuccess: false,
      answers: helpers.getInitialAnswersFromSatisfaction(props.satisfaction),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.satisfaction !== this.props.satisfaction) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        produce((draftState: Draft<SatisfactionMessageModalState>) => {
          draftState.answers = helpers.getInitialAnswersFromSatisfaction(
            this.props.satisfaction,
          );
        }),
      );
    }
  }

  isSubmitDisabled() {
    const { answers } = this.state;

    const unAnswered = answers
      .filter((answer) => answer.required)
      .find((answer) => {
        return !answer.answer && !answer.selections?.length;
      });

    return !!unAnswered;
  }

  toggleSuccess = (bool) => {
    this.setState({
      showSuccess: bool,
    });
  };

  handleChangeTextAnswer = (idx) => (e) => {
    this.setState(
      produce((draftState: Draft<SatisfactionMessageModalState>) => {
        draftState.answers[idx].answer = e.target.value;
      }),
    );
  };

  handleChangeRadioAnswer = (idx, selection, selectionRaw) => {
    this.setState(
      produce((draftState: Draft<SatisfactionMessageModalState>) => {
        draftState.answers[idx].answer = selection;
        draftState.answers[idx].answerRaw = selectionRaw;
      }),
    );
  };

  handleChangeCheckboxAnswer = (idx, checked, value, valueRaw) => {
    this.setState(
      produce((draftState: Draft<SatisfactionMessageModalState>) => {
        const { selections, selectionsRaw } = draftState.answers[idx];
        if (checked) {
          selections?.push(value);
          selectionsRaw?.push(valueRaw);
        } else {
          draftState.answers[idx].selections = selections?.filter(
            (selection) => selection !== value,
          );
          draftState.answers[idx].selectionsRaw = selectionsRaw?.filter(
            (selection) => selection !== valueRaw,
          );
        }
      }),
    );
  };

  handleSubmit = () => {
    const { satisfaction, onSubmit } = this.props;
    const { answers } = this.state;

    onSubmit({
      rating: satisfaction.response?.rating,
      answers,
    });
    this.toggleSuccess(true);
  };

  renderRequired = () => {
    return <sup className={styles.requiredTag}>*</sup>;
  };

  renderSuccess() {
    const { onHide } = this.props;
    return (
      <div className={styles.successScreen}>
        <CheckCircleIcon class={styles.checkCircleIcon} />
        <p>
          <Text
            id="satisfactionMessageModalFeedback"
            defaultMessage={messages.satisfactionMessageModalFeedback}
          />
        </p>
        <Button className={styles.submitButton} onClick={onHide}>
          <Text id="done" defaultMessage={globalMessages.done} />
        </Button>
      </div>
    );
  }

  renderForm() {
    const { satisfaction } = this.props;
    const { answers } = this.state;

    const disableForm = satisfaction?.lockedAt
      ? new Date().getTime() >= new Date(satisfaction.lockedAt).getTime()
      : false;

    return (
      <form className={styles.followupQuestionsContainer}>
        {satisfaction.form?.questions?.map((question, idx) => {
          return (
            <div key={question.id} className={styles.questionContainer}>
              <p className={styles.questionLabel}>
                {question.prompt}
                {question.isRequired && this.renderRequired()}
              </p>
              {question.displayType === 'radio' && (
                <div>
                  {question.enumeration.map((opt, i) => {
                    const selectionRaw = question.enumerationRaw
                      ? question.enumerationRaw[i]
                      : question.enumeration[i];
                    return (
                      <Radio
                        disabled={disableForm}
                        name={question.prompt}
                        id={opt + i}
                        ariaLabel={opt}
                        label={opt}
                        key={opt + i}
                        checked={answers?.[idx]?.answer === opt}
                        onClick={() => {
                          return this.handleChangeRadioAnswer(
                            idx,
                            opt,
                            selectionRaw,
                          );
                        }}
                      />
                    );
                  })}
                </div>
              )}
              {question.displayType === 'checkbox' && (
                <div>
                  {question.enumeration.map((opt, i) => {
                    const selectionRaw = question.enumerationRaw
                      ? question.enumerationRaw[i]
                      : question.enumeration[i];
                    return (
                      <Checkbox
                        disabled={disableForm}
                        name={opt}
                        label={opt}
                        key={opt}
                        checked={answers?.[idx]?.selections?.includes(opt)}
                        value={opt}
                        onClick={(checked) => {
                          return this.handleChangeCheckboxAnswer(
                            idx,
                            checked,
                            opt,
                            selectionRaw,
                          );
                        }}
                      />
                    );
                  })}
                </div>
              )}
              {question.displayType === 'text' && (
                <TextArea
                  disabled={disableForm}
                  className={styles.textArea}
                  ariaLabel={`Type ${question.prompt}`}
                  onChange={(e) => {
                    return this.handleChangeTextAnswer(idx)(e);
                  }}
                  value={answers?.[idx]?.answer}
                  maxLength={MAX_ANSWER_LENGTH}
                />
              )}
            </div>
          );
        })}

        {!disableForm && (
          <Button
            className={styles.submitButton}
            disabled={this.isSubmitDisabled()}
            onClick={this.handleSubmit}
          >
            <Text
              id="submitSatisfactionSubmitButton"
              defaultMessage={messages.submitSatisfactionSubmitButton}
            />
          </Button>
        )}
      </form>
    );
  }

  render() {
    const { show, onHide } = this.props;
    const { showSuccess } = this.state;
    return (
      <Modal
        className={styles.modal}
        bodyClassName={styles.modalBody}
        show={show}
        onHide={onHide}
        ariaLabel={messages.satisfactionModalAriaLabel}
        closeButton
      >
        {showSuccess ? this.renderSuccess() : this.renderForm()}
      </Modal>
    );
  }
}

export default SatisfactionMessageModal;

import { h, Fragment } from 'preact';
import { useContext, useState } from 'preact/hooks';
import classNames from 'classnames';
import {
  NavigationContext,
  ConversationsContext,
  MessagesContext,
  SettingsContext,
} from 'widget_main/store';
import ChevronRight from 'widget_main/globals/assets/chevronRight.svg';
import Button from 'widget_main/components/ui/Button';

import ChatBubble from 'widget_main/components/ChatMessage/ChatBubble';
import { MessageOptions } from 'widget_main/components/MessageThreadInput/types';
import { MessageCallbackResponse } from 'core_main/sdk/send_message/types';
import { getMessageAgentInformation } from 'widget_main/globals/helpers';
import { InitialMessage } from 'core_main/api/assistant/types';

import {
  ListTemplateAction,
  MessageAnswerFeedbackAction,
  MessageTemplateAction,
  MLLChild,
  MLLTemplateAction,
  TemplateType,
} from 'core_main/api/messages/types';
import { IN_THREAD_TEMPLATE_ACTIONS } from './constants';

import MLLModal from './MLLModal';
import ListAction from './ListAction';
import * as helpers from './helpers';
import styles from './templateActions.scss';

interface TemplateActionsProps {
  onSubmit: (options: MessageOptions) => void;

  disabled?: boolean;

  supportedTypes: TemplateType[];

  initialMessages?: InitialMessage[];

  className?: string;
}

const TemplateActions = ({
  onSubmit,
  disabled,
  supportedTypes,
  initialMessages,
  className,
}: TemplateActionsProps) => {
  const chatSettings = useContext(SettingsContext);
  const navigation = useContext(NavigationContext);
  const conversations = useContext(ConversationsContext);
  const messages = useContext(MessagesContext);

  const { currentConversationId } = navigation;

  const [showMLLModal, toggleMLLModal] = useState(false);
  const [selectedMLLChild, setMLLChild] = useState<MLLChild | null>(null);

  const closeMLLModal = () => {
    toggleMLLModal(false);
    setMLLChild(null);
  };

  const getCurrentConversation = () => {
    if (currentConversationId) {
      return conversations?.data?.[currentConversationId];
    }

    return undefined;
  };

  const getConversationMessages = () => {
    const currentConversation = getCurrentConversation();
    if (!currentConversation) {
      return null;
    }

    const messageIds = currentConversation.messages;

    const conversationMessages = messageIds?.map(
      (messageId) => messages.messageData[messageId],
    );

    return conversationMessages;
  };

  const handleClickQuickReply = (action: MessageTemplateAction) => {
    onSubmit({
      body: action.displayText,
      payload: action.value,
    });
  };

  const handleClickDeflectionReply = (
    action,
    deflectionTemplateMessage: MessageCallbackResponse,
  ) => {
    onSubmit({
      body: action.displayText,
      payload: action.value,
      lastDeflection: {
        articles: deflectionTemplateMessage.meta?.articles?.map((article) => {
          return {
            id: article.articleId,
            version: article.version,
            visited: article.visited ? 1 : 0,
            lang: article.lang,
            title: article.title,
            url: `${article.kbUrl}/${article.lang}/${article.slug}-${article.hash}`,
          };
        }),
      },
    });
  };
  const handleClickListReply = (body, value) => {
    onSubmit({
      body,
      payload: value,
    });
  };

  const handleClickMLLReply = (mllChild: MLLChild, mllPath?: string) => {
    if (!mllChild.children) {
      onSubmit({
        body: mllChild.displayName,
        payload: mllPath,
      });

      toggleMLLModal(false);
      return setMLLChild(null);
    }

    setMLLChild(mllChild);
    return toggleMLLModal(true);
  };

  const renderMLLModal = () => {
    if (!selectedMLLChild) {
      return null;
    }

    return (
      <MLLModal
        disabled={disabled}
        show={showMLLModal}
        onHide={closeMLLModal}
        selectedMLLChild={selectedMLLChild}
        onSubmitMLLReply={handleClickMLLReply}
      />
    );
  };

  const renderActions = (
    lastMessageWithTemplateAction: MessageCallbackResponse | InitialMessage,
  ) => {
    const template = lastMessageWithTemplateAction.meta?.template;

    const actions = template?.actions;
    const templateType = template?.templateType;

    switch (templateType) {
      case 'quick_replies':
        return (actions as MessageTemplateAction[])?.map((action) => {
          return (
            <Button
              disabled={disabled}
              className={styles.quickReplyButton}
              ariaLabel={action.value}
              key={action.value}
              variant="outlinePrimary"
              onClick={() => handleClickQuickReply(action)}
              data-kt="quickReplyButton"
            >
              {action.displayText}
            </Button>
          );
        });
      case 'list':
        return (actions as ListTemplateAction[])?.map((action) => {
          return (
            <ListAction
              detailsText={action.detailsText}
              titleText={action.titleText}
              key={action.value}
              imageUrl={action.imageUrl}
              value={action?.value}
              onClick={handleClickListReply}
            />
          );
        });
      case 'deflection':
        return (actions as MessageTemplateAction[])?.map((action) => {
          return (
            <Button
              disabled={disabled}
              className={styles.quickReplyButton}
              ariaLabel={action.value}
              key={action.value}
              variant="outlinePrimary"
              onClick={() =>
                handleClickDeflectionReply(
                  action,
                  lastMessageWithTemplateAction as MessageCallbackResponse,
                )
              }
              data-kt="quickReplyButton"
            >
              {action.displayText}
            </Button>
          );
        });
      case 'mll':
        return (actions as MLLTemplateAction)?.tree?.children?.map(
          (mllChild) => {
            return (
              <Button
                disabled={disabled}
                className={classNames(styles.mllButton, {
                  [styles.mllButtonWithChevron]: !!mllChild.children,
                })}
                ariaLabel={mllChild.displayName}
                key={mllChild.id}
                variant="outlinePrimary"
                onClick={() => handleClickMLLReply(mllChild, mllChild.id)}
              >
                <Fragment>
                  <div className={styles.mllText}>{mllChild.displayName}</div>
                  {mllChild.children && (
                    <ChevronRight height={12} viewBox="0 6 24 12" />
                  )}
                </Fragment>
              </Button>
            );
          },
        );
      case 'answer_button_feedback': {
        const agentData = getMessageAgentInformation(
          (
            lastMessageWithTemplateAction as
              | MessageCallbackResponse
              | { sentBy: undefined }
          )?.sentBy,
          chatSettings,
          navigation.initAssistantPayload,
        );
        return (
          <Fragment>
            <ChatBubble
              avatarUrl={agentData.avatarUrl}
              userDisplayName={agentData.displayName}
              showAvatar
            >
              {template?.followupText}
            </ChatBubble>
            <div className={styles.answerFeedbackContainer}>
              {(actions as MessageAnswerFeedbackAction[]).map((action) => {
                const isText = action.valueType === 'text';
                return (
                  <Button
                    variant="outlinePrimary"
                    ariaLabel={!isText ? action.value : undefined}
                    className={classNames({
                      [styles.quickReplyButton]: isText,
                      [styles.emojiReplyButton]: !isText,
                    })}
                    key={action.value}
                    onClick={() =>
                      handleClickQuickReply(action as MessageTemplateAction)
                    }
                  >
                    {action.displayText}
                  </Button>
                );
              })}
            </div>
          </Fragment>
        );
      }
      default:
        return null;
    }
  };
  const conversationMessages = getConversationMessages();
  const lastMessageWithTemplateAction = conversationMessages?.length
    ? helpers.getLastMessageWithTemplateAction(conversationMessages)
    : helpers.getLastMessageWithTemplateAction(initialMessages);

  if (lastMessageWithTemplateAction) {
    const templateActionReplyMessage =
      helpers.getLastTemplateActionOptimisticReply(
        lastMessageWithTemplateAction,
        getCurrentConversation()?.optimisticMessages,
      ) ||
      helpers.getLastTemplateActionReply(
        lastMessageWithTemplateAction,
        conversationMessages,
      );

    const template = lastMessageWithTemplateAction.meta?.template;

    const templateType = template?.templateType;

    if (templateType && !supportedTypes.includes(templateType)) return null;

    if (!templateActionReplyMessage) {
      return templateType === 'answer_button_feedback' ? (
        <div className={classNames(styles.inThread)}>
          {renderActions(lastMessageWithTemplateAction)}
        </div>
      ) : (
        <div
          className={classNames(
            styles.templateActions,
            {
              [styles.inThread]:
                templateType &&
                IN_THREAD_TEMPLATE_ACTIONS.includes(templateType),
            },
            className,
          )}
        >
          {renderActions(lastMessageWithTemplateAction)}
          {renderMLLModal()}
        </div>
      );
    }
  }

  return null;
};

export default TemplateActions;

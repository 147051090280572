// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".attachmentPreviews__attachments___aHbjI{padding:15px;display:flex;overflow-x:auto}.attachmentPreviews__attachment___9HyeJ{position:relative;margin:0 15px 0 0}.attachmentPreviews__imagePreview___Mx4I\\+,.attachmentPreviews__file___7jJMZ{height:60px;border-radius:8px;border:1px solid #e9eff5}.attachmentPreviews__file___7jJMZ{width:160px;padding:10px 5px;display:flex;align-items:center}.attachmentPreviews__file___7jJMZ .attachmentPreviews__pdfIcon___qinHK{color:#336dec;margin:0 5px 0 0;flex-shrink:0}.attachmentPreviews__file___7jJMZ .attachmentPreviews__pdfDetails___AbecK{font-size:11px}.attachmentPreviews__file___7jJMZ .attachmentPreviews__fileName___QqVvR{width:110px;text-overflow:ellipsis;white-space:nowrap;overflow:hidden}.attachmentPreviews__file___7jJMZ .attachmentPreviews__fileSize___0JBoe{color:#717070}.attachmentPreviews__imagePreview___Mx4I\\+{width:60px;background-size:cover;background-position:center center;background-repeat:no-repeat}.attachmentPreviews__removeAttachmentButton___WGpcI{border-radius:50%;width:18px;height:18px;position:absolute;top:-9px;right:-9px;display:flex;align-items:center;justify-content:center;padding:3px}.attachmentPreviews__closeIcon___J-YPh{width:100%;height:100%}html[dir=\"rtl\"] .attachmentPreviews__attachment___9HyeJ{margin:0 0 0 15px}html[dir=\"rtl\"] .attachmentPreviews__pdfIcon___qinHK{margin:0 0 0 5px}html[dir=\"rtl\"] .attachmentPreviews__removeAttachmentButton___WGpcI{left:-9px;right:initial}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"attachments": "attachmentPreviews__attachments___aHbjI",
	"attachment": "attachmentPreviews__attachment___9HyeJ",
	"imagePreview": "attachmentPreviews__imagePreview___Mx4I+",
	"file": "attachmentPreviews__file___7jJMZ",
	"pdfIcon": "attachmentPreviews__pdfIcon___qinHK",
	"pdfDetails": "attachmentPreviews__pdfDetails___AbecK",
	"fileName": "attachmentPreviews__fileName___QqVvR",
	"fileSize": "attachmentPreviews__fileSize___0JBoe",
	"removeAttachmentButton": "attachmentPreviews__removeAttachmentButton___WGpcI",
	"closeIcon": "attachmentPreviews__closeIcon___J-YPh"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".proactiveChatInput__inputContainer___GW5jj{width:100%;flex:0;overflow:hidden;transition:flex 500ms ease-out;will-change:flex;margin-top:-2px;height:0}.proactiveChatInput__inputContainer___GW5jj.proactiveChatInput__expand___dJ5kD{flex:1;padding:2px;height:min-content}@media all and (-ms-high-contrast: none){.proactiveChatInput__inputContainer___GW5jj{opacity:0;transition:opacity 500ms ease-out;height:auto;flex:auto}.proactiveChatInput__inputContainer___GW5jj.proactiveChatInput__expand___dJ5kD{opacity:1;flex:auto;height:auto}.proactiveChatInput__inputContainer___GW5jj textarea{overflow:auto}}.proactiveChatInput__inputField___n48eh,.proactiveChatInput__templateActions___zw-Ok{overflow:hidden}.proactiveChatInput__inputField___n48eh{border-radius:10px;box-shadow:0 1px 4px rgba(0,0,0,.15),0 2px 4px rgba(0,0,0,.08)}.proactiveChatInput__templateActions___zw-Ok{padding:0;justify-content:flex-end}body.align-chat-icon-left .proactiveChatInput__templateActions___zw-Ok{justify-content:flex-start}html[dir=\"rtl\"] body.align-chat-icon-left .proactiveChatInput__templateActions___zw-Ok{justify-content:flex-end}html[dir=\"rtl\"] body.align-chat-icon-right .proactiveChatInput__templateActions___zw-Ok{align-items:flex-start}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"inputContainer": "proactiveChatInput__inputContainer___GW5jj",
	"expand": "proactiveChatInput__expand___dJ5kD",
	"inputField": "proactiveChatInput__inputField___n48eh",
	"templateActions": "proactiveChatInput__templateActions___zw-Ok"
};
export default ___CSS_LOADER_EXPORT___;

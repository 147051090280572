import sizings from 'widget_main/styles/sizings.scss';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { isShiftKey, isTabKey } from 'widget_main/globals/keyboardEvents';

const MOBILE_SCREEN_WIDTH = Number(sizings.mobileScreenWidth.slice(0, -2));
const MOBILE_SCREEN_HEIGHT = Number(sizings.mobileScreenHeight.slice(0, -2));

export const focusableElements =
  'button:not(:disabled), [href], input:not(:disabled), select:not(:disabled), textarea:not(:disabled), [tabindex]:not([tabindex="-1"])';

export const isMobileViewport = () => {
  const isMobile =
    window.parent.innerWidth <= MOBILE_SCREEN_WIDTH ||
    window.parent.innerHeight <= MOBILE_SCREEN_HEIGHT;

  return isMobile;
};

export type FocusableElement =
  | HTMLInputElement
  | HTMLButtonElement
  | HTMLAnchorElement
  | HTMLSelectElement
  | HTMLTextAreaElement;

export const findFocusableElements = (parent: HTMLElement): Element[] => {
  // query all of our focusable elements, then ensure they are visible by checking their offsetParent which
  //  will be null if the element's parent is hidden
  //  https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetParent
  //  NOTE: This is an issue for `position: fixed` elements, but should not be an issue for this project (so far)
  return [...parent.querySelectorAll(focusableElements)].filter(
    (element) => (element as HTMLElement).offsetParent,
  );
};

// For accessibility purposes, we want to lock focus within the widget and modals while it is active
// for customers using a keyboard or accessibility tools
export const useFocusLock = <T extends HTMLElement>() => {
  const [ref, updateRef] = useState<T | null>(null);

  const getDocument = (): Document => {
    if (ref) {
      return ref.ownerDocument;
    }
    return document;
  };

  const eventListener = useCallback(
    (e: KeyboardEvent) => {
      if (!ref || !isTabKey(e)) {
        return;
      }

      const activeDocument = getDocument();
      const focusableContent = findFocusableElements(ref);
      const firstFocusableElement = focusableContent[0] as FocusableElement;
      const lastFocusableElement = focusableContent[
        focusableContent.length - 1
      ] as FocusableElement;

      // if shift key is pressed for shift + tab combination
      if (isShiftKey(e)) {
        if (activeDocument.activeElement === firstFocusableElement) {
          lastFocusableElement.focus(); // add focus for the last focusable element
          e.preventDefault();
        }
      } else if (activeDocument.activeElement === lastFocusableElement) {
        // if focused has reached the last focusable element then focus first focusable element after pressing tab
        firstFocusableElement.focus();
        e.preventDefault();
      }
    },
    [ref],
  );

  useEffect(() => {
    if (ref) {
      ref.addEventListener('keydown', eventListener);
    }
    return () => {
      ref?.removeEventListener('keydown', eventListener);
    };
  }, [ref]);

  return updateRef;
};

/**
 * This is a patch function to solve for an issue with the useIntersectionObserver custom hook that returns a
 *  zeroed-out rootBounds (and intersectionRect + boundingClientRect) causing a false negative when checking
 *  for an intersection which is immediately resolved upon next render.
 *
 * TODO: Remove this function and fix the useIntersectionObserver hook
 * @param intersectionObserver
 */
export const isIntersectionObserverUnmounted = (
  intersectionObserver?: IntersectionObserverEntry,
) => {
  if (!intersectionObserver) return true;
  if (!intersectionObserver.rootBounds) return true;
  return (
    intersectionObserver.rootBounds.bottom === 0 &&
    intersectionObserver.rootBounds.height === 0 &&
    intersectionObserver.rootBounds.left === 0 &&
    intersectionObserver.rootBounds.right === 0 &&
    intersectionObserver.rootBounds.top === 0 &&
    intersectionObserver.rootBounds.width === 0 &&
    intersectionObserver.rootBounds.x === 0 &&
    intersectionObserver.rootBounds.y === 0
  );
};

export const focusOnFirstElementInWidget = (parent: HTMLElement) => {
  const focusableContent = findFocusableElements(parent);
  if (focusableContent[0]) {
    (focusableContent[0] as FocusableElement).focus();
  }
};

export const isEnterKey = (e: KeyboardEvent) => {
  const { which } = e;
  const { key } = e;
  const { keyCode } = e;

  return which === 13 || key === 'Enter' || keyCode === 13;
};

export const isTabKey = (e: KeyboardEvent) => {
  const { which } = e;
  const { key } = e;
  const { keyCode } = e;

  return which === 9 || key === 'Tab' || keyCode === 9;
};

export const isEscKey = (e: KeyboardEvent) => {
  const { which } = e;
  const { key } = e;
  const { keyCode } = e;
  const escapeText = key === 'Escape' || key === 'Esc'; // "Esc" is IE/Edge specific

  return which === 27 || escapeText || keyCode === 27;
};

export const isShiftKey = (e: KeyboardEvent) => {
  const { which } = e;
  const { key } = e;
  const { keyCode } = e;
  const { shiftKey } = e;
  const escapeText =
    key === 'Shift' || key === 'ShiftLeft' || key === 'ShiftRight';

  return which === 16 || escapeText || keyCode === 16 || shiftKey;
};

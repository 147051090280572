export default {
  satisfactionRatingOption: 'Satisfaction rating {{option}}',
  highestSatisfactionRatingOption: 'Highest satisfaction rating {{option}}',
  lowestSatisfactionRatingOption: 'Lowest satisfaction rating {{option}}',
  satisfactionRatingOptionDisabled: 'Satisfaction rating {{option}} Disabled',
  highestSatisfactionRatingOptionDisabled:
    'Highest satisfaction rating {{option}} Disabled',
  lowestSatisfactionRatingOptionDisabled:
    'Lowest satisfaction rating {{option}} Disabled',
};

import { h } from 'preact';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'preact/hooks';
import { KBContext, NavigationContext } from 'widget_main/store';
import Loader from 'widget_main/components/ui/Loader';
import { getMappedLanguage } from 'i18n/helpers';
import { KB_ARTICLE_PATH, KB_SEARCH_PATH } from 'widget_main/globals/constants';

import { getKustomerCore } from 'widget_main/globals/helpers';
import styles from './kbArticle.scss';

const kustomerCore = getKustomerCore();
export const createKBArticleUrl = ({
  baseDomain,
  lang,
  slug,
  hash,
  embed,
  anchorHash,
}: {
  baseDomain: string;
  lang: string;
  slug: string;
  hash: string;
  embed?: boolean;
  anchorHash?: string;
}) => {
  const params = new URLSearchParams();
  let domain = baseDomain;
  if (embed) {
    params.set('web', '1');
    // always want to load domains over https
    if (domain.match(/^http:/gm)) {
      domain = domain.replace(/^http:/gm, 'https:');
    }
  }

  const slashSeparator = domain.match(/\/$/) ? '' : '/';
  const path = `${domain}${slashSeparator}${lang}/embed/articles/`;

  const kbUrlPath = `${path}${slug}-${hash}?${params.toString()}`;

  if (anchorHash) return `${kbUrlPath}${anchorHash}`;

  return kbUrlPath;
};

const KBArticle = () => {
  const kb = useContext(KBContext);
  const navigation = useContext(NavigationContext);
  const [loading, setLoading] = useState(true);
  const [anchorHash, setAnchorHash] = useState<string | undefined>();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { articles, currentArticleId, config } = kb;

  const lang = getMappedLanguage();

  const navigateToArticle = (articleId) => {
    if (articleId !== currentArticleId) setLoading(true);
    kb.updateCurrentArticleId(articleId);
    navigation.updatePage(KB_ARTICLE_PATH);
  };

  /* We need to listen to a message emitted from window.postMessage() to process when a link is clicked within the KB
   * article embed in order to determine if it is a KB article link we should navigate to within the widget or open in
   * a new tab. A similar piece of functionality exists in the iOS and Android SDKs, and so we should match.
   *    https://github.com/kustomer/chat-sdk-ios/blob/main/SDK/KustomerSDK/KustomerChat/KustomerUI/KnowledgeBase/ViewControllers/KBArticleViewer.swift#L51
   *    https://github.com/kustomer/customer-android/blob/master/kustomersdk/src/main/java/com/kustomer/kustomersdk/Activities/KUSKnowledgeBaseActivity.java#L169
   *
   * (The Android URL override function doesn't function the same, but it _does_ allow for navigation within the UI Webview)
   *
   * This work, however, does not keep track of a stack of pages if you navigate to multiple articles within one
   * "KB Article session" meaning, if you navigate from KB Article 1 -> KB Article 2 -> KB Article 3 then click
   * the "Back" arrow, we will take you back to the Article list, not KB Article 2.
   *
   * There is room for improvement here to manage navigation to cover the case above, specifically with the work being
   * done here:
   *    https://github.com/kustomer/chat-web/pull/614
   */
  const messageListener = useCallback(
    (event: MessageEvent<{ url: string; target: string }>) => {
      const { knowledgeBaseDomain, id } = config;

      if (!knowledgeBaseDomain) {
        return;
      }
      const { url, target } = event.data;

      if (!url) return;

      const articleUrl = new URL(url);
      const articleUrlHash = articleUrl.hash;
      const articleUrlPathname = articleUrl.pathname;
      const kbBaseUrl = new URL(knowledgeBaseDomain);
      const targetBlank = target === '_blank';

      const currentIframe = iframeRef?.current;
      const iframeSrc = currentIframe?.src;

      if (iframeSrc && iframeSrc.length && articleUrlHash && !targetBlank) {
        const currentIframePathname = new URL(iframeSrc)?.pathname;

        if (currentIframePathname === articleUrlPathname) {
          currentIframe.src = iframeSrc.split('#')[0] + articleUrlHash;
          return;
        }
      }

      if (targetBlank || articleUrl.hostname !== kbBaseUrl.hostname) {
        window.open(url, '_blank');
        return;
      }

      setAnchorHash(articleUrlHash);
      // removes the lang of the article from the pathname to get the slug
      const articleSlugHash = articleUrlPathname
        .replaceAll(/^\/[a-z_]*\//g, '')
        .replace('embed/articles/', '');

      const articleSlug = articleSlugHash.slice(
        0,
        articleSlugHash.lastIndexOf('-'),
      );
      const articleHash = articleSlugHash.slice(
        articleSlugHash.lastIndexOf('-') + 1,
      );

      const articleInStore = Object.keys(kb.articles || []).find(
        (a) => kb.articles?.[a].slug === articleSlug,
      );
      if (articleInStore) {
        navigateToArticle(articleInStore);
        return;
      }

      kustomerCore
        .getArticleBySlug({
          articleSlug,
          articleHash,
          lang,
          knowledgeBaseId: id,
        })
        .then((article) => {
          const articleId = article?.articleId;
          if (articleId) {
            if (kb.articles?.[articleId]) {
              navigateToArticle(articleId);
              return;
            }
            kb.updateArticles([article]);
            navigateToArticle(articleId);
          } else {
            navigation.updatePage(KB_SEARCH_PATH);
          }
        });
    },
    [kb],
  );

  useEffect(() => {
    window.self.addEventListener('message', messageListener);
    return () => {
      window.self.removeEventListener('message', messageListener);
    };
  }, [kb]);

  if (!currentArticleId) return null;

  const article = articles?.[currentArticleId];

  if (!article) return null;

  const kbArticleUrl = createKBArticleUrl({
    baseDomain: article.kbUrl || kb?.config.knowledgeBaseDomain || '',
    lang: article.lang || lang,
    slug: article.slug,
    hash: article.hash,
    embed: true,
    anchorHash,
  });

  return (
    <div className={styles.articleContainer}>
      {loading ? (
        <div className={styles.loaderWrapper}>
          <Loader className={styles.loader} />
        </div>
      ) : null}
      <iframe
        ref={iframeRef}
        data-kt="kbArticleBody"
        onLoad={() => setLoading(false)}
        className={styles.articleIframe}
        title={article.title}
        src={kbArticleUrl}
      />
    </div>
  );
};

export default KBArticle;

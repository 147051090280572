import { setIframeSrcDoc } from 'polyfills/helpers';

// eslint-disable-next-line import/prefer-default-export
export const setupIframeForApi = (
  iframeId: string,
  iframeStyles: string,
  content: string,
  iframeTitle: string,
): undefined => {
  const scriptEmbedElement = document.querySelectorAll(
    '[data-kustomer-api-key]',
  )[0] as HTMLScriptElement | null;

  if (!scriptEmbedElement || !scriptEmbedElement.parentNode) return;

  const iframe = document.createElement('iframe');

  iframe.setAttribute('id', iframeId);
  iframe.setAttribute('title', iframeTitle);
  iframe.setAttribute('aria-label', iframeTitle);
  iframe.setAttribute('role', 'application');
  iframe.setAttribute('tabindex', '0');

  iframe.style.cssText = iframeStyles;

  scriptEmbedElement.parentNode.insertBefore(iframe, scriptEmbedElement);

  setIframeSrcDoc(iframe, content);
};

export const getIframeForWidgetMain = (): HTMLIFrameElement | undefined => {
  const iframe = document.getElementById(
    'kustomer-ui-sdk-iframe',
  ) as HTMLIFrameElement;

  if (!iframe) return undefined;

  return iframe;
};

export const getIframeForCoreApi = (): HTMLIFrameElement | undefined => {
  const iframe = document.getElementById(
    'kustomer-core-sdk-iframe',
  ) as HTMLIFrameElement;

  if (!iframe) return undefined;

  return iframe;
};

export const setTrackingTokenInLocalStorage = (token) => {
  try {
    localStorage.setItem('kustomer-chat-tracking-token', token);
  } catch {
    throw new Error('Setting tracking token in local storage');
  }
};

export const getTrackingTokenFromLocalStorage = () => {
  try {
    return localStorage.getItem('kustomer-chat-tracking-token');
  } catch {
    throw new Error('Getting tracking token from local storage');
  }
};

export const removeTrackingTokenFromLocalStorage = () => {
  try {
    return localStorage.removeItem('kustomer-chat-tracking-token');
  } catch {
    throw new Error('Removing tracking token from local storage');
  }
};

interface NavigatorWithStandalone extends Navigator {
  standalone: boolean;
}

/**
 * Currently only needed as a check for iOS where we are seeing sentry errors caused by embedded views
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator#navigator.standalone
 */
export const isInAppBrowser = () => {
  // this is something of a hack to get around TypeScript for a Safari/WebKit-only
  const userAgent = window.navigator.userAgent.toLowerCase();
  const ios = /iphone|ipod|ipad/.test(userAgent);
  if (!ios) return false;

  // If the device is considered "standalone", then we are in an embedded view
  return (
    'standalone' in window.navigator &&
    (window.navigator as NavigatorWithStandalone).standalone
  );
};

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/Neutral/WF-028702-009830-001251.woff";
import ___CSS_LOADER_URL_IMPORT_1___ from "./fonts/Neutral/WF-028702-009830-001253.woff";
import ___CSS_LOADER_URL_IMPORT_2___ from "./fonts/Neutral/WF-028702-009830-001255.woff";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Neutral Std\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\");font-weight:400;font-style:normal;font-display:swap}@font-face{font-family:\"Neutral Std\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:500;font-style:normal;font-display:swap}@font-face{font-family:\"Neutral Std\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\");font-weight:600;font-style:normal;font-display:swap}html,body{font-family:\"Neutral Std\",\"Helvetica Neue\",\"Arial\",sans-serif;font-weight:400;font-size:14px;color:#222;width:100%;height:100%;box-sizing:border-box;overflow:hidden;line-height:normal}strong{font-weight:500}em{font-style:italic}ul{list-style-type:disc}ol{list-style-type:decimal}button,textarea,input{font-size:100%;font-family:inherit;color:inherit}button{border:0;cursor:pointer}svg{overflow:visible}*,*::before,*::after{box-sizing:inherit}#root{width:100%;height:100%;display:flex;justify-content:flex-end;align-items:flex-end;flex-direction:column}#root:not(.userIsTabbing) *{outline:none}body.align-chat-icon-left #root{align-items:flex-start}html[dir=\"rtl\"] body.align-chat-icon-left #root{align-items:flex-end}html[dir=\"rtl\"] body.align-chat-icon-right #root{align-items:flex-start}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px"
};
export default ___CSS_LOADER_EXPORT___;

import {
  installRelativeTimeFormatPolyfills,
  isBrowserIE,
} from 'polyfills/helpers';
import { Ie11Navigator } from 'polyfills/types';
import { getKustomer } from 'widget_main/globals/helpers';
import { LANGUAGES } from '../languageMap';
import * as locales from '../locales';

export const getBrowserLocale = () => {
  const windowNavigator = window.navigator as Ie11Navigator;
  const navigatorLanguage = isBrowserIE()
    ? windowNavigator?.browserLanguage
    : windowNavigator?.languages?.[0];

  return navigatorLanguage;
};

export const getAppLocale = () => {
  const startLang = getKustomer()?.startParameters?.lang;

  return startLang || getBrowserLocale();
};

const formatLocale = (lang) => {
  const langLowerCase = lang.toLowerCase();
  const withoutDash = langLowerCase.replace('-', '_');

  if (withoutDash.indexOf('_') === -1) {
    return langLowerCase;
  }

  if (!LANGUAGES[withoutDash]) {
    const parts = withoutDash.split('_');

    return parts[0];
  }

  return withoutDash;
};
export const getMappedLanguage = (langCode?: string) => {
  if (langCode) {
    return LANGUAGES[langCode];
  }

  const startLang = getKustomer()?.startParameters?.lang;
  const startLangLocale =
    startLang && typeof startLang === 'string' && formatLocale(startLang);

  if (startLangLocale && LANGUAGES[startLangLocale]) {
    return LANGUAGES[startLangLocale];
  }

  const browserLocale = formatLocale(getBrowserLocale());

  return LANGUAGES[browserLocale];
};
export const getMessagesForLanguage = () => {
  const mappedLanguage = getMappedLanguage();

  return locales?.default?.[mappedLanguage]?.getMessages();
};

export const getLocaleData = () => {
  const mappedLanguage = getMappedLanguage();

  const localeFunction = locales?.default?.[mappedLanguage]?.getLocale;

  if ((Intl.RelativeTimeFormat as any).polyfilled && localeFunction) {
    return localeFunction();
  }

  return Promise.resolve();
};

export const setLocaleDirection = () => {
  const mappedLanguage = getMappedLanguage();

  if (mappedLanguage === 'he' || mappedLanguage === 'ar') {
    document.dir = 'rtl';
  }
};

export const initLocale = () => {
  setLocaleDirection();

  return installRelativeTimeFormatPolyfills()
    .then(() => {
      return getLocaleData();
    })
    .catch((error) => `An error occurred while getting locale data, ${error}`);
};

import { Component, createRef, h, RefObject } from 'preact';
import produce, { Draft } from 'immer';
import { IntlProvider, translate } from 'preact-i18n';
import debounce from 'lodash.debounce';
import {
  getKustomer,
  getKustomerCore,
  getKustomerLocalStorage,
  runKustomerListenersForEvent,
  setKustomerLocalStorage,
} from 'widget_main/globals/helpers';
import Widget from 'widget_main/components/Widget';

import {
  ChatAvailabilityContext,
  ConnectionContext,
  ConversationsContext,
  HideNewConversationButtonSettingsContext,
  KBContext,
  MessagesContext,
  NavigationContext,
  ProactiveChatContext,
  SettingsContext,
  UnreadCountsContext,
  WidgetActionsContext,
} from 'widget_main/store';
import {
  ConversationEntity,
  ConversationsState,
  Page,
  ProactiveChatCallbackResponse,
} from 'widget_main/store/types';
import {
  CONVERSATIONS_PATH,
  DEFAULT_SESSION_PAGE_SIZE,
  KB_ARTICLE_PATH,
  KB_SEARCH_PATH,
  kustomerWidgetEventTypes,
  MESSAGE_THREAD_PATH,
  OFFLINE,
  WIDGET_TYPE_CHAT,
  WIDGET_TYPE_CHAT_KB,
  WIDGET_TYPE_KB,
} from 'widget_main/globals/constants';
import ChatRootIcon from 'widget_main/components/ChatRootIcon';
import { SatisfactionCallbackResponse } from 'core_main/api/satisfaction/types';
import { MessageAttachmentCallbackResponse } from 'core_main/sdk/send_message/types';
import { InitAssistantCallbackResponse } from 'core_main/sdk/init_assistant/types';
import { getMappedLanguage } from 'i18n/helpers';
import { handleError } from 'globals/errors';

import {
  CreateConversationEventDetail,
  CreateConversationWidgetApiParameters,
  CreateConversationInDraftEventDetail,
  CreateConversationInDraftWidgetApiParameters,
  OpenConversationByIdEventDetail,
} from 'widget/widget_api/types';
import {
  ConversationDeletedCallbackResponse,
  ConversationMergedCallbackResponse,
} from 'core_main/pubnub/types';
import { isMobileViewport } from 'widget_main/globals/dom';
import {
  appendChatNotificationToParentTitle,
  clearChatNotificationFromParentTitle,
  focusOnIframe,
  focusOnWindowContainingIframe,
  isWidgetInFocus,
  setIframeSizeChatIcon,
  setIframeSizeHidden,
} from 'helpers/kustomer_api_iframe';
import { resizeWidgetHelper } from 'helpers/kustomer_api_iframe/helpers';

import { GetConversationsCallbackResponse } from 'core_main/sdk/get_conversations/types';
import { createCategoryTree } from 'widget_main/screens/KBSearchList/helpers';
import { ArticleCallbackResponse } from 'core_main/sdk/get_articles/types';
import * as helpers from './helpers';
import * as assistantHelpers from './helpers/assistant';
import { AppProps, AppState } from './types';
import { INITIAL_STATE, MESSAGE_POLL_SETTINGS } from './constants';
import defaultMessages from './messages';
import {
  fetchMessageHistoryHelper,
  mergeAndSortByMessageTimestamps,
} from './helpers/message';
import { publicConstants } from '../../widget_api/constants';
import { lastOpenedPageIsSupportedByWidgetType } from './helpers';

const kustomerCore = getKustomerCore();

const DEFAULT_PROACTIVE_CHAT_INTERVAL = 1000;
const PROACTIVE_CHAT_RETRY_MULTIPLIER = 250;
const MAX_PROACTIVE_CHAT_FAILURES = 16;

class App extends Component<AppProps, AppState> {
  proactiveChatInterval;

  proactiveChatTries = 0;

  chatRootIconRef: RefObject<HTMLDivElement>;

  pollIntervalTimer: NodeJS.Timer | undefined;

  constructor() {
    super();
    this.state = {
      ...INITIAL_STATE,
      navigation: {
        ...INITIAL_STATE.navigation,
        updatePage: this.updatePage,
        updateCurrentConversationId: this.updateCurrentConversationId,
        updateInitAssistantPayload: this.updateInitAssistantPayload,
        updateConversationListScrollLocation: debounce(
          this.updateConversationListScrollLocation,
          50,
        ),
      },
      widgetActions: {
        openWidget: this.openWidget,
        closeWidget: this.closeWidget,
      },
      conversations: {
        ...INITIAL_STATE.conversations,
        addConversation: this.addConversation,
        endConversation: this.endConversation,
        updateConversation: this.updateConversation,
        addOptimisticMessage: this.addOptimisticMessage,
        toggleOptimisticMessageError: this.toggleOptimisticMessageError,
        removeOptimisticMessage: this.removeOptimisticMessage,
        fetchMessageHistory: this.fetchMessageHistory,
        fetchNextPageOfHistory: this.fetchNextPageOfHistory,
        refreshConversations: this.refreshConversations,
      },
      messages: {
        ...INITIAL_STATE.messages,
        receiveMessage: this.messageReceivedHandler,
        markArticleVisited: this.markArticleVisited,
        resetHandoff: this.resetHandoff,
        resetDraftHandoff: this.resetDraftHandoff,
      },
      kb: {
        ...INITIAL_STATE.kb,
        updateCategories: this.updateCategories,
        updateArticleSearchResults: this.updateArticleSearchResults,
        updateArticleSearchFetching: this.updateArticleSearchFetching,
        updateFeaturedArticles: this.updateFeaturedArticles,
        updateArticles: this.updateArticles,
        updateCurrentCategoryId: this.updateCurrentCategoryId,
        updateCurrentArticleId: this.updateCurrentArticleId,
        updateArticleSearchInput: this.updateArticleSearchInput,
        articleSearchFetching: false,
      },
    };

    this.resizeHandler = debounce(this.resizeHandler, 150);
    this.chatRootIconRef = createRef();
  }

  resizeWidget = (page?: Page) => {
    const { navigation, isChatHidden, showWidget } = this.state;
    const { hideChatIcon } = this.props;
    resizeWidgetHelper({
      page: page || navigation.page,
      isChatHidden,
      showWidget,
      hideChatIcon,
    });
  };

  resizeHandler = () => {
    if (this.state.showWidget) {
      this.resizeWidget();
      this.setState(
        produce((draftState: Draft<AppState>) => {
          draftState.isMobile = isMobileViewport();
        }),
      );
    }
  };

  addKustomerCoreListeners = () => {
    kustomerCore.addListener(
      'onMessageReceived',
      this.agentMessageReceivedHandler,
    );

    kustomerCore.addListener(
      'onUnreadCountChange',
      this.unreadCountChangeHandler,
    );

    kustomerCore.addListener(
      'onConversationEnded',
      this.conversationEndedHandler,
    );

    kustomerCore.addListener(
      'onConversationUnended',
      this.conversationEndedHandler,
    );

    kustomerCore.addListener(
      'onSatisfactionReceived',
      this.satisfactionReceivedHandler,
    );

    kustomerCore.addListener(
      'onConversationMerged',
      this.conversationMergedHandler,
    );

    kustomerCore.addListener('onCustomerDelete', this.customerDeleteHandler);

    kustomerCore.addListener(
      'onConversationDeleted',
      this.conversationDeletedHandler,
    );

    kustomerCore.addListener('onLogout', this.handleLogout);

    kustomerCore.addListener('onAssistantEnded', this.endAssistantHandler);
  };

  removeKustomerCoreListeners = () => {
    kustomerCore.removeListener(
      'onMessageReceived',
      this.agentMessageReceivedHandler,
    );

    kustomerCore.removeListener(
      'onUnreadCountChange',
      this.unreadCountChangeHandler,
    );

    kustomerCore.removeListener(
      'onConversationEnded',
      this.conversationEndedHandler,
    );

    kustomerCore.removeListener(
      'onConversationUnended',
      this.conversationEndedHandler,
    );

    kustomerCore.removeListener('onLogout', this.handleLogout);

    kustomerCore.removeListener('onAssistantEnded', this.endAssistantHandler);

    kustomerCore.removeListener(
      'onConversationMerged',
      this.conversationMergedHandler,
    );

    kustomerCore.removeListener('onCustomerDelete', this.customerDeleteHandler);

    kustomerCore.removeListener(
      'onConversationDeleted',
      this.conversationDeletedHandler,
    );
  };

  agentMessageReceivedHandler = (response, error) => {
    const { messages } = this.state;
    const { conversationId, messageId } = response;

    const currentConversationId =
      messages.messageData[messageId]?.conversationId;
    const conversationIdChanged =
      currentConversationId && conversationId !== currentConversationId;

    if (response.sentBy.type === 'user' || conversationIdChanged) {
      this.messageReceivedHandler(response, error);
    }
  };

  handleNotifications = (response) => {
    const { conversationId, body, notificationOverrides } = response;
    const { showWidget } = this.state;
    const { chatSettings, intlDefinitions } = this.props;
    const { teamName, teamIconUrl } = chatSettings;

    // only enable browser notifications if flag is set to true
    const showNotifications =
      getKustomer()?.startParameters?.showBrowserNotifications;

    const sentNotification = (permission: NotificationPermission) => {
      if (permission === 'granted' && (!document.hasFocus() || !showWidget)) {
        const dictionary = intlDefinitions || {
          ...defaultMessages,
        };
        const notificationTitle =
          notificationOverrides.titleOverride ||
          translate(
            'browserNotificationTitle',
            '',
            dictionary,
            {
              teamName,
            },
            0,
            `${teamName} Chat`,
          );
        const notificationBody = notificationOverrides.bodyOverride || body;
        const notification = new Notification(notificationTitle, {
          body: notificationBody,
          icon: teamIconUrl,
        });
        notification.onclick = () => {
          focusOnWindowContainingIframe();
          focusOnIframe();
          this.openConversationById({
            detail: {
              conversationId,
            },
          } as CustomEvent);
          notification.close();
        };
      }
    };

    if (showNotifications && 'Notification' in window) {
      if (Notification.permission === 'granted') {
        sentNotification(Notification.permission);
      } else {
        try {
          Notification.requestPermission().then((permission) =>
            sentNotification(permission),
          );
        } catch (error) {
          // Safari < 15 doesn't return a promise for requestPermissions, when called this way,
          // it will throw a TypeError.
          // If we catch this error, we will try to pass a callback as the first argument instead.
          //  https://developer.mozilla.org/en-US/docs/Web/API/Notification/requestPermission#syntax
          if (error instanceof TypeError) {
            Notification.requestPermission((permission) => {
              sentNotification(permission);
            });
          } else {
            throw error;
          }
        }
      }
    }
  };

  messageReceivedHandler = (response, error) => {
    if (error) {
      handleError(error);
      return;
    }
    const {
      conversationId,
      direction,
      messageId,
      createdAt,
      isInAssistantMode,
    } = response;

    if (direction === 'out') {
      this.handleNotifications(response);
    }
    const { conversations } = this.state;
    if (!conversations.data[conversationId]) {
      kustomerCore.getConversation(
        { conversationId },
        (conversation: ConversationEntity | null, error) => {
          if (!conversation || error) return;
          this.setState(
            produce((draftState: Draft<AppState>) => {
              try {
                conversation.messages = [];
                conversation.optimisticMessages = [];
                draftState.conversations.data[conversationId] = conversation;
                draftState.conversations.data[
                  conversationId
                ].isInAssistantMode = isInAssistantMode;
              } catch (e) {
                handleError(e, {
                  message: 'Error receiving message',
                });
              }
            }),
            () => {
              this.fetchMessageHistory(
                conversationId,
                this.state.conversations.data,
              ).catch((e) =>
                handleError(e, {
                  message:
                    'Message Receive Handler: Error fetching message history',
                }),
              );
            },
          );
        },
      );
      return;
    }
    this.setState((prevState) => {
      const { conversations } = prevState;
      const conversation = conversations.data[conversationId];
      const previousMessagesById = conversation.messages.reduce(
        (acc, messageId) => {
          acc[messageId] = prevState.messages.messageData[messageId];
          return acc;
        },
        {},
      );
      const { messagesById, messages } = mergeAndSortByMessageTimestamps(
        previousMessagesById,
        {
          [messageId]: response,
        },
      );

      return produce(prevState, (draftState: Draft<AppState>) => {
        const lastMessageId = messages[messages.length - 1];
        const lastMessage = messagesById[lastMessageId];
        const conversation = draftState.conversations.data[conversationId];
        conversation.preview = lastMessage?.body;
        conversation.lastMessageAt = createdAt;
        conversation.messages = messages;
        Object.assign(draftState.messages.messageData, messagesById);
      });
    });
  };

  unreadCountChangeHandler = (response) => {
    const { total } = response;
    const { conversationId, count } = response.change;
    const { intlDefinitions } = this.props;

    const showTitleNotifications =
      getKustomer()?.startParameters?.showTitleNotifications;

    // we want to update the state before we notify the customer with a title notification
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.unreadCounts.total = total;
        draftState.unreadCounts.conversations[conversationId] = count;
      }),
      () => {
        runKustomerListenersForEvent(
          kustomerWidgetEventTypes.onUnread,
          response,
        );
      },
    );

    if (showTitleNotifications) {
      const dictionary = intlDefinitions || {
        ...defaultMessages,
      };
      const chatCountString = translate(
        'pageTitleNotification',
        '',
        dictionary,
        {
          number: total,
        },
        total,
      );
      if (!isWidgetInFocus() && total > 0) {
        appendChatNotificationToParentTitle(chatCountString);
      } else {
        clearChatNotificationFromParentTitle(chatCountString);
      }
    }
  };

  conversationEndedHandler = (response) => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        const { conversationId, ended } = response;
        const conversation = draftState.conversations.data[conversationId];
        if (conversation) {
          conversation.ended = ended;
        }
      }),
    );
  };

  endAssistantHandler = (response) => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        const { conversationId, isInAssistantMode } = response;
        const conversation = draftState.conversations.data[conversationId];
        if (conversation) {
          conversation.isInAssistantMode = isInAssistantMode;
        }
      }),
    );
  };

  satisfactionReceivedHandler = (response: SatisfactionCallbackResponse) => {
    const { conversationId } = response;

    if (conversationId)
      this.updateConversation(conversationId, { satisfaction: response });
  };

  conversationMergedHandler = (
    response: ConversationMergedCallbackResponse,
  ) => {
    const { sourceConversationId, targetConversationId } = response;
    kustomerCore.getConversation(
      {
        conversationId: targetConversationId,
      },
      (conversation) => {
        this.setState(
          produce((draftState: Draft<AppState>) => {
            if (draftState.conversations.data[targetConversationId]) return;
            conversation.messages = [];
            conversation.optimisticMessages = [];
            draftState.conversations.data[targetConversationId] = conversation;
          }),
          () => {
            this.updateConversation(sourceConversationId, {
              movedToConversationId: targetConversationId,
            });
            this.fetchMessageHistory(
              targetConversationId,
              this.state.conversations.data,
            ).catch((e) =>
              handleError(e, {
                message: 'Error fetching history after conversation merge',
              }),
            );
          },
        );
      },
    );
  };

  customerDeleteHandler = () => {
    kustomerCore.logout(() => null);
  };

  conversationDeletedHandler = (
    response: ConversationDeletedCallbackResponse,
  ) => {
    const { conversationId } = response;
    this.setState(
      produce((draftState: Draft<AppState>) => {
        const conversation = draftState.conversations.data[conversationId];
        if (conversation) {
          conversation.deleted = true;
          conversation.ended = true;
        }
        if (draftState.navigation.currentConversationId !== conversationId) {
          delete draftState.conversations.data[conversationId];
        }
      }),
    );
  };

  setInitialNavigation = (settings, startParameters) => {
    const { widgetType } = settings;

    let page;

    switch (widgetType) {
      case WIDGET_TYPE_KB:
        page = KB_SEARCH_PATH;
        break;
      case WIDGET_TYPE_CHAT:
        page = CONVERSATIONS_PATH;
        break;
      case WIDGET_TYPE_CHAT_KB:
        if (
          startParameters.preferredView === publicConstants.CHAT_HISTORY_VIEW
        ) {
          page = CONVERSATIONS_PATH;
        } else {
          page = KB_SEARCH_PATH;
        }
        break;
      default:
        page = CONVERSATIONS_PATH;
    }

    this.updatePage(page);
  };

  handleProactiveChatTrigger = (
    proactiveChat?: ProactiveChatCallbackResponse,
  ) => {
    const isMobile = isMobileViewport();

    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.proactiveChat = proactiveChat;
        draftState.isMobile = isMobile;
      }),
    );
  };

  evaluateProactiveChatRules = () => {
    assistantHelpers
      .getActiveAssistant({
        proactiveOnly: true,
      })
      .then((response) => {
        if (response) {
          const initResponse = {
            ...response,
            initialMessages: response?.initialMessages,
          };
          const callbackResponse: ProactiveChatCallbackResponse = {
            initResponse,
          };
          const { hideHistory } = this.props;
          const { navigation } = this.state;

          // if the customer has an active conversation and the 'hideHistory' setting is enabled,
          //  we need to wait for the Proactive chat rules to be evaluated but do not want to
          //  trigger proactive chat so the end user does not lose their conversation so return early
          if (hideHistory && Boolean(navigation.currentConversationId)) {
            if (this.proactiveChatInterval)
              clearInterval(this.proactiveChatInterval);
            return;
          }

          this.handleProactiveChatTrigger(callbackResponse);
          if (this.proactiveChatInterval)
            clearInterval(this.proactiveChatInterval);
        }

        // if we get a success after a series of failures, restart the interval back to defaults
        if (this.proactiveChatTries > 0) {
          this.proactiveChatTries = 0;
          clearInterval(this.proactiveChatInterval);
          this.proactiveChatInterval = setInterval(
            this.evaluateProactiveChatRules,
            DEFAULT_PROACTIVE_CHAT_INTERVAL,
          );
        }
      })
      .catch((error) => {
        this.proactiveChatTries++;
        let errorMessage = 'Failed to trigger proactive chat:';
        if (this.proactiveChatTries > 1) {
          clearInterval(this.proactiveChatInterval);

          // after 16 tries we will stop trying to ping the assistant endpoint
          if (this.proactiveChatTries <= MAX_PROACTIVE_CHAT_FAILURES) {
            // restart the interval with backoff
            this.proactiveChatInterval = setInterval(
              this.evaluateProactiveChatRules,
              DEFAULT_PROACTIVE_CHAT_INTERVAL +
                PROACTIVE_CHAT_RETRY_MULTIPLIER * this.proactiveChatTries,
            );
          } else {
            errorMessage = 'Proactive chat error maximum reached';
          }
        }
        handleError(error, {
          message: errorMessage,
          tags: { proactiveChat: true },
        });
      });
  };

  startProactiveChatEvaluation = () => {
    if (this.proactiveChatTries === 0) {
      this.evaluateProactiveChatRules();
    }
    this.proactiveChatInterval = setInterval(
      this.evaluateProactiveChatRules,
      DEFAULT_PROACTIVE_CHAT_INTERVAL,
    );
  };

  fetchPageOfHistory = (page: number) => {
    return new Promise<GetConversationsCallbackResponse>((resolve, reject) => {
      kustomerCore.getConversations(
        {
          page,
          pageSize: DEFAULT_SESSION_PAGE_SIZE,
        },
        (res, error) => {
          if (error) {
            reject(error);
          } else {
            resolve(res);
          }
        },
      );
    })
      .then((res) => {
        const newConversationState = helpers.mapConversationState(
          res.conversations,
          this.state.conversations.data,
        );

        this.setState(
          produce((draftState: Draft<AppState>) => {
            draftState.unreadCounts = kustomerCore.getUnreadCount();
            Object.assign(draftState.conversations.data, newConversationState);
          }),
        );

        return res;
      })
      .catch((e) => {
        // we do not throw here due to a *very fun* issue with mobile browsers that throw an error on _any_ kind of request
        //  cancellation (eg. page close, navigation, bad connection) during a fetch call and the error call is extremely
        //  vague (see also: "TypeError: Load failed" errors)
        //  example: visit this page in an iOS browser https://request-cancellation-test.vercel.app
        //  For now, we will log the error
        handleError(e, {
          message: 'Error fetching history:',
          tags: { fetchPageOfHistory: true },
        });
      });
  };

  fetchNextPageOfHistory = () => {
    const { next } = this.state.conversations?.pages || {};
    if (!next) return Promise.resolve();
    return this.fetchPageOfHistory(next).then((res) => {
      this.setState(
        produce((draftState: Draft<AppState>) => {
          draftState.conversations.pages = res?.pages;
        }),
      );
      return res;
    });
  };

  setConnectionToOnline = () => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.connection = 'online';
      }),
    );
  };

  refreshConversations = () => {
    const currentConversations = this.state.conversations.data;
    if (currentConversations && Object.keys(currentConversations).length) {
      const upToPage = this.state.conversations.pages?.current || 1;
      for (let i = 0; i < upToPage; i++) {
        this.fetchPageOfHistory(i);
      }
    }
  };

  handleNetworkOnline = () => {
    this.refreshConversations();
    this.setConnectionToOnline();
  };

  handleNetworkOffline = () => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.connection = 'offline';
      }),
    );
  };

  handleLogin = (event) => {
    const { parameters, callback } = event.detail;
    const brandId = getKustomer()?.startParameters?.brandId;
    const assistantId = getKustomer()?.startParameters?.assistantId;

    kustomerCore.login(
      { brandId, assistantId, ...parameters },
      (res, error) => {
        if (error) {
          if (typeof callback === 'function') {
            callback(null, error);

            runKustomerListenersForEvent(
              kustomerWidgetEventTypes.onLogin,
              null,
              error,
            );
          }
          return;
        }
        this.resetEndCustomerState(() => {
          this.fetchNextPageOfHistory()
            .then(() => {
              if (typeof callback === 'function') {
                callback(res);
              }

              runKustomerListenersForEvent(
                kustomerWidgetEventTypes.onLogin,
                res,
              );
            })
            .catch((historyError) => {
              if (typeof callback === 'function') {
                callback(res, historyError);
              }

              runKustomerListenersForEvent(
                kustomerWidgetEventTypes.onLogin,
                res,
                historyError,
              );
            });
        });
      },
    );
  };

  resetEndCustomerState = (cb) => {
    this.setState((prevState) => {
      return produce(prevState, (draftState: Draft<AppState>) => {
        draftState.conversations = {
          ...prevState.conversations,
          ...INITIAL_STATE.conversations,
        };

        draftState.messages = {
          ...prevState.messages,
          ...INITIAL_STATE.messages,
        };

        draftState.unreadCounts = INITIAL_STATE.unreadCounts;

        draftState.navigation = {
          ...prevState.navigation,
          ...INITIAL_STATE.navigation,
        };
      });
    }, cb);
  };

  handleLogout = (_, error) => {
    if (error) return;
    this.resetEndCustomerState(() => {
      runKustomerListenersForEvent(
        kustomerWidgetEventTypes.onLogout,
        null,
        error,
      );
    });
  };

  checkParentLocationInterval() {
    const { chatSettings } = this.props;
    const { domainCriteria } = chatSettings;

    if (!domainCriteria) return;

    setInterval(() => {
      const parentWindowLocation = window.parent.location.href;
      const { currentLocation, isChatHidden } = this.state;

      if (parentWindowLocation !== currentLocation) {
        const isAllowed = kustomerCore.isUrlAllowed({
          url: parentWindowLocation,
        })?.allowed;

        if (isAllowed) {
          this.setState(
            produce((draftState: Draft<AppState>) => {
              draftState.isChatHidden = false;
              draftState.currentLocation = parentWindowLocation;
            }),
          );
        } else if (isChatHidden === false) {
          this.setState(
            produce((draftState: Draft<AppState>) => {
              draftState.isChatHidden = true;
              draftState.currentLocation = parentWindowLocation;
            }),
          );
        }
      }
    }, 1000);
  }

  restoreWidgetLastOpenState = () => {
    if (assistantHelpers.isAssistantPreview()) return;

    if (getKustomerLocalStorage('wasWidgetOpen') && !isMobileViewport()) {
      const lastOpenedPage = getKustomerLocalStorage('lastOpenPage');
      const { widgetType } = this.props.chatSettings;

      if (
        lastOpenedPageIsSupportedByWidgetType(
          lastOpenedPage as Page,
          widgetType,
        )
      ) {
        if (lastOpenedPage) {
          this.updatePage(lastOpenedPage as Page);
        }

        if (lastOpenedPage === MESSAGE_THREAD_PATH) {
          const lastOpenConversationId = getKustomerLocalStorage(
            'lastOpenConversationId',
          );

          if (
            lastOpenConversationId &&
            this.state.conversations?.data?.[lastOpenConversationId]
          ) {
            this.updateCurrentConversationId(lastOpenConversationId as string);
          } else {
            this.updatePage(CONVERSATIONS_PATH);
          }
        }
      }

      this.openWidget();
    }
  };

  resetHandoff = () => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        const { messages } = draftState;
        const { handoff } = messages;

        if (handoff?.assistantId && !handoff?.message) {
          draftState.messages.handoff = {
            assistantId: handoff?.assistantId,
          };
        } else {
          draftState.messages.handoff = undefined;
        }
      }),
    );
  };

  resetDraftHandoff = () => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.messages.draft = undefined;
      }),
    );
  };

  handoffToMessageThread = (
    assistantId,
    message,
    payload,
    initResponse?,
    initError?,
    callback?: CreateConversationWidgetApiParameters['callback'],
    custom?,
  ) => {
    const handoffCallback = (res, error) => {
      if (typeof callback === 'function') {
        callback(res, error);
      }

      this.state.messages.resetHandoff();
    };

    this.setState(
      produce((draftState: Draft<AppState>) => {
        if (!initError) {
          draftState.messages.handoff = {
            assistantId,
            message,
            payload,
            callback: handoffCallback,
            custom,
          };
          draftState.navigation.currentConversationId = undefined;
          draftState.navigation.page = MESSAGE_THREAD_PATH;
          draftState.navigation.initAssistantPayload = initResponse;
        }
      }),
      () => {
        this.openWidget();
      },
    );
  };

  handoffDraftToMessageThread = (
    message,
    callback?: CreateConversationInDraftWidgetApiParameters['callback'],
  ) => {
    const handoffCallback = (res, error) => {
      if (typeof callback === 'function') {
        callback(res, error);
      }
      this.state.messages.resetDraftHandoff();
    };

    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.messages.draft = {
          message,
          callback: handoffCallback,
        };
        draftState.navigation.currentConversationId = undefined;
        draftState.navigation.page = MESSAGE_THREAD_PATH;
      }),
      () => {
        this.openWidget();
      },
    );
  };

  createConversation = (event) => {
    const detail: CreateConversationEventDetail = event?.detail;
    const message = detail?.message;
    const payload = detail?.payload;
    const { custom } = detail;
    const callback = detail?.callback;

    if (this.state.connection === OFFLINE) {
      if (typeof callback === 'function') {
        return callback(null, new Error('No Internet Connection'));
      }
      return null;
    }
    return assistantHelpers
      .getActiveAssistant({
        assistant: detail?.assistantId,
        dialog: detail.dialogId,
      })
      .then((response) => {
        if (response) {
          this.handoffToMessageThread(
            response.assistant,
            message,
            payload,
            response,
            null,
            callback,
            custom,
          );
        } else {
          this.handoffToMessageThread(
            detail?.assistantId,
            message,
            payload,
            null,
            null,
            callback,
            custom,
          );
        }
      })
      .catch((error) => {
        if (error && typeof callback === 'function') {
          callback(null, error);
        }
      });
  };

  createConversationInDraft = (event) => {
    const detail: CreateConversationInDraftEventDetail = event?.detail;
    const message = detail?.message;
    const callback = detail?.callback;
    if (this.state.connection === OFFLINE) {
      if (typeof callback === 'function') {
        return callback(null, new Error('No Internet Connection'));
      }
      return null;
    }
    return this.handoffDraftToMessageThread(message, callback);
  };

  openKbArticle = (e: { detail: { articleId: string; cb } }) => {
    const { articleId, cb } = e.detail;
    const lang = getMappedLanguage();
    const { knowledgeBaseId } = this.props.chatSettings;
    kustomerCore.getArticleById(
      {
        articleId,
        lang,
        knowledgeBaseId,
      },
      (response, error) => {
        if (error) {
          if (typeof cb === 'function') {
            cb(null, error);
          } else {
            throw error;
          }
          return;
        }
        this.state.kb.updateArticles([response]);
        this.state.kb.updateCurrentArticleId(articleId);
        this.state.navigation.updatePage(KB_ARTICLE_PATH);

        this.openWidget();

        if (typeof cb === 'function') {
          cb(response);
        }
      },
    );
  };

  fetchCategoryArticles(categoryId, categories, cb) {
    const lang = getMappedLanguage();

    const { kb } = this.state;
    const { rootCategory } = kb;
    const { knowledgeBaseId } = this.props.chatSettings;
    kustomerCore.getArticles(
      { categoryId, lang, knowledgeBaseId },
      (res, err) => {
        const updatedArticles = err ? [] : res.articles;

        kb.updateArticles(updatedArticles);

        kb.updateCategories(
          {
            ...categories,
            [categoryId]: {
              ...categories[categoryId],
              articles: updatedArticles,
            },
          },
          rootCategory,
        );
        cb();
      },
    );
  }

  navigateToCategory(categoryId: string, cb?) {
    const { kb, navigation } = this.state;

    kb.updateCurrentCategoryId(categoryId);
    navigation.updatePage(KB_SEARCH_PATH);
    this.openWidget();
    if (typeof cb === 'function') {
      cb(null);
    }
  }

  openKbCategory = (e: { detail: { categoryId: string; cb } }) => {
    const { categoryId, cb } = e.detail;

    const lang = getMappedLanguage();

    const { kb, navigation } = this.state;
    const { categories } = kb;
    const { knowledgeBaseId } = this.props.chatSettings;

    // No categories. This means the KB has never been opened yet, either because the widget wasn't opened or the use
    // was only on the chat screen. Load all categories and then load articles for that category.
    if (!Object.keys(categories).length) {
      kustomerCore.getCategories({ lang, knowledgeBaseId }, (res, err) => {
        if (err) {
          const langNotFound =
            err?.title === 'Not Found' && err?.source === 'lang';

          // Don't callback with error if issue is with lang. Component will gracefully handle that error.
          this.navigateToCategory(categoryId);
          if (!langNotFound && typeof cb === 'function') {
            cb(null, err);
          }
          return;
        }

        const tree = createCategoryTree(res.categories);
        kb.updateCategories(tree, res.rootCategory);

        if (!tree[categoryId]) {
          // Category doesn't exist.
          // Callback with error, but still open kb to root category list so user doesn't have degraded experience.
          this.navigateToCategory(res.rootCategory.categoryId);
          if (typeof cb === 'function') {
            cb(null, new Error('Category not found'));
          }
          return;
        }

        this.fetchCategoryArticles(categoryId, tree, () => {
          this.navigateToCategory(categoryId, cb);
        });
      });
    } else if (!categories[categoryId]) {
      // Category doesn't exist.
      // Callback with error, but still open kb to root category list so user doesn't have degraded experience.
      navigation.updatePage(KB_SEARCH_PATH);
      this.openWidget();
      if (typeof cb === 'function') {
        cb(null, new Error('Category not found'));
      }
    } else if (!categories[categoryId].articles) {
      // Categories have been fetched already, but we need to load the articles.
      this.fetchCategoryArticles(categoryId, categories, () => {
        this.navigateToCategory(categoryId, cb);
      });
    } else {
      // Everything already loaded, just navigate.
      this.navigateToCategory(categoryId, cb);
    }
  };

  handleRestart = () => {
    this.setState({ loading: true, retryCount: this.state.retryCount + 1 });
    kustomerCore.stop().then(() => {
      this.removeKustomerCoreListeners();
      this.resetEndCustomerState(() => {
        this.props.reloadSettings().then(() => {
          this.initApp();
        });
      });
    });
  };

  componentDidMount() {
    window.addEventListener('online', this.handleNetworkOnline);
    window.addEventListener('offline', this.handleNetworkOffline);
    window.addEventListener('kustomerOpen', this.openWidget);
    window.addEventListener('kustomerClose', this.closeWidget);
    window.addEventListener(
      'kustomerCreateConversation',
      this.createConversation,
    );
    window.addEventListener(
      'kustomerOpenDraftConversation',
      this.createConversationInDraft,
    );
    window.addEventListener('kustomerLogin', this.handleLogin);
    window.addEventListener(
      'kustomerOpenConversationById',
      this.openConversationById,
    );
    window.addEventListener('kustomerOpenKbArticle', this.openKbArticle);
    window.addEventListener('kustomerOpenKbCategory', this.openKbCategory);
    window.addEventListener(
      'kustomerGetOpenConversations',
      this.getOpenConversations,
    );
    window.parent.addEventListener('resize', this.resizeHandler);

    if (kustomerCore) {
      this.initApp();
    }
  }

  initApp = () => {
    this.setState({
      isChatHidden:
        ['hidden', 'disabled'].includes(this.props.chatAvailability || '') ||
        !kustomerCore.isUrlAllowed({
          url: window.parent.location.href,
        })?.allowed,
    });

    this.fetchNextPageOfHistory().then(() => {
      const kustomer = getKustomer();
      if (!assistantHelpers.isAssistantPreview()) {
        this.startProactiveChatEvaluation();
      }
      kustomer.startFinished = true;

      if (
        !['hidden', 'disabled'].includes(this.props.chatAvailability as string)
      ) {
        this.checkParentLocationInterval();
      }

      this.setInitialNavigation(
        this.props.chatSettings,
        kustomer.startParameters,
      );

      window.parent.dispatchEvent(new CustomEvent('kustomerStart'));

      assistantHelpers.startUpdatePageInterval(this.props.chatSettings);

      this.addKustomerCoreListeners();

      this.restoreWidgetLastOpenState();
      const startCallback = kustomer?.startCallback;

      this.setState({ loading: false });
      kustomerCore.kustomerEventCount({ name: 'app.initialization.finish' });
      if (typeof startCallback === 'function') startCallback();

      // poll for messages from pubnub, with our API as a backup on interval
      if (!this.pollIntervalTimer) {
        const poll = (data: ConversationsState['data']) => {
          Object.keys(data).forEach((conversationId) => {
            if (data[conversationId]?.deleted === true) return;

            this.fetchMessageHistory(conversationId, data).catch((e) =>
              handleError(e, {
                message:
                  'initApp Message Poller: Error fetching message history',
              }),
            );
          });
        };
        this.pollIntervalTimer = setInterval(() => {
          poll(this.state.conversations.data);
        }, MESSAGE_POLL_SETTINGS.interval);
      }
    });
  };

  componentDidUpdate(_, prevState) {
    const { isChatHidden } = this.state;
    if (!prevState.isChatHidden && isChatHidden) {
      setIframeSizeHidden();
    }

    if (prevState.isChatHidden && !isChatHidden) {
      this.resizeWidget();
    }
  }

  componentWillUnmount() {
    this.removeKustomerCoreListeners();
    window.removeEventListener('online', this.handleNetworkOnline);
    window.removeEventListener('offline', this.handleNetworkOffline);
    window.removeEventListener('kustomerOpen', this.openWidget);
    window.removeEventListener('kustomerClose', this.closeWidget);
    window.removeEventListener(
      'kustomerCreateConversation',
      this.createConversation,
    );
    window.removeEventListener(
      'kustomerOpenDraftConversation',
      this.createConversationInDraft,
    );
    window.removeEventListener('kustomerLogin', this.handleLogin);
    window.removeEventListener('kustomerOpenKbArticle', this.openKbArticle);
    window.removeEventListener(
      'kustomerOpenConversationById',
      this.openConversationById,
    );
    window.removeEventListener(
      'kustomerGetOpenConversations',
      this.getOpenConversations,
    );
    window.parent.removeEventListener('resize', this.resizeHandler);
    if (this.pollIntervalTimer) {
      clearInterval(this.pollIntervalTimer);
    }
  }

  fetchMessageHistory = (
    conversationId: string,
    conversationDataState: ConversationsState['data'],
  ) => {
    const conversation = conversationDataState[conversationId];
    if (!conversation) return Promise.resolve();
    const currentMessagesById = conversation.messages.reduce(
      (acc, messageId) => {
        acc[messageId] = this.state.messages.messageData[messageId];
        return acc;
      },
      {},
    );
    return fetchMessageHistoryHelper(conversation, currentMessagesById).then(
      (result) => {
        const {
          satisfaction,
          messagesById: newMessagesById,
          hasAllMessages,
          initialMessages,
          optimisticMessages,
        } = result;
        this.setState(
          produce((draftState: Draft<AppState>) => {
            // we must merge conversation messages again here, messagesById could be stale if a message is sent between when it was created and here
            // - ideally we do not merge in two places, however existing fetching logic requires it
            // get messagesById from state
            const conversation = draftState.conversations.data[conversationId];
            const prevMessagesById = conversation.messages.reduce(
              (acc, messageId) => {
                acc[messageId] = draftState.messages.messageData[messageId];
                return acc;
              },
              {},
            );

            const {
              messagesById: mergedMessagesById,
              messages: mergedMessages,
            } = mergeAndSortByMessageTimestamps(
              prevMessagesById,
              newMessagesById,
            );

            const draftConversation =
              draftState.conversations.data[conversationId];
            draftConversation.messages = mergedMessages;
            draftConversation.satisfaction =
              draftConversation.satisfaction || satisfaction;
            draftConversation.hasAllMessages = hasAllMessages;
            if (initialMessages) {
              draftConversation.initialMessages = initialMessages;
            }
            if (optimisticMessages) {
              draftConversation.optimisticMessages = optimisticMessages;
            }
            Object.assign(draftState.messages.messageData, mergedMessagesById);
          }),
        );
      },
    );
  };

  openWidget = (event?: CustomEvent | MouseEvent | KeyboardEvent) => {
    const { isChatHidden, showWidget } = this.state;
    if (isChatHidden || showWidget) {
      return;
    }

    const isMobile = isMobileViewport();

    this.setState(
      (prevState) =>
        produce(prevState, (draftState: Draft<AppState>) => {
          draftState.showWidget = true;
          draftState.isMobile = isMobile;
        }),
      () => {
        this.resizeWidget();

        setKustomerLocalStorage('wasWidgetOpen', true);

        if (event?.detail?.callback) {
          event.detail.callback();
        }

        kustomerCore.sendPresenceActivity({ presence: 'online' });

        runKustomerListenersForEvent(kustomerWidgetEventTypes.onOpen);
      },
    );
  };

  closeWidget = (event?: CustomEvent | MouseEvent) => {
    const { isChatHidden } = this.state;
    const { hideChatIcon } = this.props;

    if (isChatHidden) {
      return;
    }

    setIframeSizeChatIcon(hideChatIcon);
    this.setState(
      (prevState) =>
        produce(prevState, (draftState: Draft<AppState>) => {
          draftState.showWidget = false;
        }),
      () => {
        this.chatRootIconRef.current?.focus();

        setKustomerLocalStorage('wasWidgetOpen', false);
        if (event?.detail?.callback) {
          event.detail.callback();
        }

        kustomerCore.sendPresenceActivity({ presence: 'offline' });

        runKustomerListenersForEvent(kustomerWidgetEventTypes.onClose);
        kustomerCore.kustomerEventCount({
          name: 'app.close_widget.click',
        });
      },
    );
  };

  openConversationById = (event: CustomEvent) => {
    const { conversations } = this.state;

    const detail: OpenConversationByIdEventDetail = event?.detail;

    const conversationId = detail?.conversationId;
    const callback = detail?.callback;

    if (!conversationId || !conversations.data[conversationId]) {
      if (callback) {
        return callback(null, new Error('Invalid conversationId provided.'));
      }
    } else {
      return this.setState(
        (prevState) =>
          produce(prevState, (draftState: Draft<AppState>) => {
            draftState.navigation.currentConversationId = conversationId;
            draftState.navigation.page = MESSAGE_THREAD_PATH;
          }),
        () => {
          this.openWidget();
          if (callback) callback(null);
        },
      );
    }

    return undefined;
  };

  getOpenConversations = (event: CustomEvent) => {
    const { conversations } = this.state;

    const openConversations = Object.values(conversations.data)
      .filter((conversation) => !conversation.ended)
      .map((conversation) => ({
        conversationId: conversation.conversationId,
      }));

    if (event?.detail?.callback) {
      event.detail.callback(openConversations);
    }
  };

  updatePage = (page: Page) => {
    this.resizeWidget(page);
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.navigation.page = page;
      }),
    );
  };

  updateCurrentConversationId = (conversationId?: string) => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.navigation.currentConversationId = conversationId;
      }),
    );
  };

  updateInitAssistantPayload = (
    initAssistantPayload: InitAssistantCallbackResponse | undefined,
  ) => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.navigation.initAssistantPayload = initAssistantPayload;
      }),
    );
  };

  updateConversationListScrollLocation = (location: number) => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.navigation.conversationListScrollLocation = location;
      }),
    );
  };

  addConversation = (conversation, initialMessages) => {
    const { conversationId, createdAt, isInAssistantMode, assistantId } =
      conversation;
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.conversations.data[conversationId] = {
          conversationId,
          messages: [],
          optimisticMessages: [],
          createdAt,
          isInAssistantMode,
          initialMessages,
          assistantId,
          hasAllMessages: true,
        };
      }),
    );
  };

  updateConversation: ConversationsState['updateConversation'] = (
    conversationId,
    conversationAttributes,
  ) => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        const current = draftState.conversations.data[conversationId];

        draftState.conversations.data[conversationId] = {
          ...current,
          ...conversationAttributes,
        };
      }),
    );
  };

  updateCategories = (categories, rootCategory) => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.kb.categories = categories;
        draftState.kb.rootCategory = rootCategory;
      }),
    );
  };

  updateArticles = (articles) => {
    const articlesToAdd = articles.reduce((acc, val) => {
      acc[val.articleId] = val;

      return acc;
    }, {});

    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.kb.articles = {
          ...this.state.kb.articles,
          ...articlesToAdd,
        };
      }),
    );
  };

  updateArticleSearchResults = (articles) => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.kb.articleSearchResults = articles;
      }),
    );
  };

  updateArticleSearchInput = (term: string) => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.kb.articleSearchInput = term;
      }),
    );
  };

  updateCurrentCategoryId = (categoryId) => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.kb.currentCategoryId = categoryId;
      }),
    );
  };

  updateCurrentArticleId = (articleId) => {
    kustomerCore.getKnowledgeBaseConfig().then((config) => {
      this.setState(
        produce((draftState: Draft<AppState>) => {
          draftState.kb.currentArticleId = articleId;
          draftState.kb.config = config;
        }),
      );
    });
  };

  updateArticleSearchFetching = (fetching: boolean) => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.kb.articleSearchFetching = fetching;
      }),
    );
  };

  updateFeaturedArticles = (articles: ArticleCallbackResponse[]) => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.kb.featuredArticles = articles;
      }),
    );
    this.updateArticles(articles);
  };

  endConversation = (conversationId) => {
    kustomerCore.endConversation({
      conversationId,
    });
  };

  addOptimisticMessage = (conversationId, message) => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        draftState.conversations.data[conversationId].optimisticMessages.push(
          message,
        );
      }),
    );
  };

  toggleOptimisticMessageError = (conversationId, optimisticId, value) => {
    this.setState((prevState) => {
      const { optimisticMessages } =
        prevState.conversations.data[conversationId];
      return produce(prevState, (draftState: Draft<AppState>) => {
        draftState.conversations.data[conversationId].optimisticMessages =
          optimisticMessages.map((message) => {
            if (message.messageId === optimisticId) {
              return {
                ...message,
                showError: value,
              };
            }
            return message;
          });
      });
    });
  };

  removeOptimisticMessage = (conversationId, optimisticMessageId) => {
    this.setState(
      produce((draftState: Draft<AppState>) => {
        const conversation = draftState.conversations.data[conversationId];

        let attachmentsToRevoke = [] as MessageAttachmentCallbackResponse[];

        const withoutOptimisticMessage = conversation.optimisticMessages.filter(
          (message) => {
            const isMessage = message.messageId === optimisticMessageId;

            if (isMessage && message.attachments) {
              attachmentsToRevoke = message.attachments;
            }

            return !isMessage;
          },
        );

        attachmentsToRevoke.forEach((attachment) => {
          if (attachment.url) URL.revokeObjectURL(attachment.url);
        });

        conversation.optimisticMessages = withoutOptimisticMessage;
      }),
    );
  };

  markArticleVisited = (messageId, articleId) => {
    this.setState((prevState) => {
      return produce(prevState, (draftState: Draft<AppState>) => {
        const message = draftState.messages.messageData[messageId];

        const article = message?.meta?.articles?.find(
          (article) => article.articleId === articleId,
        );

        if (article) {
          article.visited = 1;
        }
      });
    });
  };

  renderChatRootIcon() {
    const { navigation, unreadCounts, proactiveChat, isMobile } = this.state;
    const { hideChatIcon, chatSettings, chatAvailability, intlDefinitions } =
      this.props;

    if (hideChatIcon) {
      return null;
    }

    const proactiveChatContext = {
      proactiveChat,
      onCloseProactiveChat: () => this.handleProactiveChatTrigger(),
      handoffToMessageThread: this.handoffToMessageThread,
    };
    return (
      <IntlProvider definition={intlDefinitions}>
        <NavigationContext.Provider value={navigation}>
          <ChatAvailabilityContext.Provider value={chatAvailability}>
            <UnreadCountsContext.Provider value={unreadCounts}>
              <SettingsContext.Provider value={chatSettings}>
                <ProactiveChatContext.Provider value={proactiveChatContext}>
                  <ChatRootIcon
                    ref={this.chatRootIconRef}
                    onClick={this.openWidget}
                    isMobile={isMobile}
                  />
                </ProactiveChatContext.Provider>
              </SettingsContext.Provider>
            </UnreadCountsContext.Provider>
          </ChatAvailabilityContext.Provider>
        </NavigationContext.Provider>
      </IntlProvider>
    );
  }

  renderWidget() {
    const {
      navigation,
      widgetActions,
      conversations,
      messages,
      unreadCounts,
      kb,
      connection,
      isMobile,
      loading,
      retryCount,
    } = this.state;

    const {
      chatAvailability,
      chatSettings,
      hideNewConversationButtonSettings,
      intlDefinitions,
      hideHistory,
    } = this.props;

    return (
      <IntlProvider definition={intlDefinitions}>
        <NavigationContext.Provider value={navigation}>
          <ChatAvailabilityContext.Provider value={chatAvailability}>
            <HideNewConversationButtonSettingsContext.Provider
              value={hideNewConversationButtonSettings}
            >
              <SettingsContext.Provider value={chatSettings}>
                <WidgetActionsContext.Provider value={widgetActions}>
                  <ConversationsContext.Provider value={conversations}>
                    <UnreadCountsContext.Provider value={unreadCounts}>
                      <MessagesContext.Provider value={messages}>
                        <KBContext.Provider value={kb}>
                          <ConnectionContext.Provider value={connection}>
                            <Widget
                              isMobile={isMobile}
                              hideHistory={hideHistory}
                              loading={loading}
                              restartApp={
                                retryCount < 3 ? this.handleRestart : undefined
                              }
                            />
                          </ConnectionContext.Provider>
                        </KBContext.Provider>
                      </MessagesContext.Provider>
                    </UnreadCountsContext.Provider>
                  </ConversationsContext.Provider>
                </WidgetActionsContext.Provider>
              </SettingsContext.Provider>
            </HideNewConversationButtonSettingsContext.Provider>
          </ChatAvailabilityContext.Provider>
        </NavigationContext.Provider>
      </IntlProvider>
    );
  }

  render(_, state) {
    const { isChatHidden, showWidget } = state;
    if (isChatHidden) {
      return null;
    }
    return showWidget ? this.renderWidget() : this.renderChatRootIcon();
  }
}

export default App;

import { h } from 'preact';
import { Text, useText } from 'preact-i18n';

interface TextProps {
  defaultMessage: string;
  id: string;
  fields?: Record<string, string | number>;
}

const TextWraper = ({ defaultMessage, id, fields }: TextProps) => {
  const { message } = useText({ message: id });

  if (fields) {
    let fallbackMessage = defaultMessage;

    if (!message) {
      fallbackMessage = Object.keys(fields).reduce((acc, val) => {
        const fieldValue = String(fields[val]);
        const newMessage = acc.replace(`{{${val}}}`, fieldValue);
        return newMessage;
      }, defaultMessage);
    }

    return (
      <Text fields={{ ...fields }} id={id}>
        {fallbackMessage}
      </Text>
    );
  }

  return <Text id={id}>{defaultMessage}</Text>;
};
export default TextWraper;

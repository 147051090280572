// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messageThread__flex___d5g74{display:flex}.messageThread__messageThread___ZIR-3{display:flex;flex-direction:column;justify-content:space-between;flex-grow:1;min-height:200px}.messageThread__messageThreadMobile___fj\\+bt{justify-content:flex-end}.messageThread__chatMessages___hvRsu{overflow-y:auto;padding:20px;display:flex;flex-direction:column-reverse}.messageThread__loaderWrapper___7RU6u{margin-top:10px;position:absolute;top:0;right:0;left:0;display:flex;visibility:hidden}.messageThread__loaderWrapper___7RU6u .messageThread__loader___LkSMn{margin-bottom:10px}.messageThread__loaderWrapper___7RU6u.messageThread__showLoader___rlIeE{visibility:visible;transition:visibility ease-out .25s}.messageThread__chatDividerContainer___fNwLb{display:flex;align-items:center;color:#717070;font-size:12px;margin:20px 0}.messageThread__chatDividerLabel___woSqF{margin:0 15px}.messageThread__chatDividerLine___jY4u3{height:1px;flex-grow:1;background-color:#e9eff5}.messageThread__createConversationContainer___B\\+vG3{flex-shrink:0;display:flex;background-color:#fff;padding:20px;border-top-left-radius:12px;border-top-right-radius:12px;justify-content:center;box-shadow:0 -1px 6px 0 rgba(201,201,201,.3)}.messageThread__inputContainer___HHTGL{display:flex;flex-direction:column;flex-shrink:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"flex": "messageThread__flex___d5g74",
	"messageThread": "messageThread__messageThread___ZIR-3",
	"messageThreadMobile": "messageThread__messageThreadMobile___fj+bt",
	"chatMessages": "messageThread__chatMessages___hvRsu",
	"loaderWrapper": "messageThread__loaderWrapper___7RU6u",
	"loader": "messageThread__loader___LkSMn",
	"showLoader": "messageThread__showLoader___rlIeE",
	"chatDividerContainer": "messageThread__chatDividerContainer___fNwLb",
	"chatDividerLabel": "messageThread__chatDividerLabel___woSqF",
	"chatDividerLine": "messageThread__chatDividerLine___jY4u3",
	"createConversationContainer": "messageThread__createConversationContainer___B+vG3",
	"inputContainer": "messageThread__inputContainer___HHTGL"
};
export default ___CSS_LOADER_EXPORT___;

export default {
  goBackAriaLabel: 'go back',
  endChatAriaLabel: 'end chat',
  minimizeChatAriaLabel: 'minimize chat widget',
  openInNewTabAriaLabel: 'open article in new tab',
  confirmEndChatAriaLabel: 'confirm end chat',
  cancelEndChatAriaLabelLabel: 'cancel end chat',
  endChatModalPrompt: 'Are you sure you want to end this chat?',
  endChatModalButton: 'End Chat',
  endChatModalCancelButton: 'Cancel',
};

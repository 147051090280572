import { ConversationEntity } from 'widget_main/store/types';

export const getFilteredConversations = (
  conversationsList: ConversationEntity[],
  noHistory: boolean,
  unreadConversationIds: string[] = [],
) => {
  if (noHistory) {
    return conversationsList.filter(
      (conversation) =>
        !conversation.ended ||
        unreadConversationIds.some((c) => c === conversation.conversationId),
    );
  }
  return conversationsList;
};

import { h } from 'preact';
import { useContext } from 'preact/hooks';
import classNames from 'classnames';
import { kustyIcon } from 'widget_main/globals/assets/kustyIcon';
import { SettingsContext } from 'widget_main/store';
import { Localizer } from 'preact-i18n';
import Text from 'widget_main/components/Text';
import { getChatIconSize } from 'widget_main/app/helpers';
import { ChatIconSize } from 'widget/widget_api/types';
import styles from './chatIcon.scss';

import messages from './messages';

interface ChatIconProps {
  className?: string;
  id?: string;
  isEmbedIcon?: boolean;
  size: ChatIconSize;
}

const ChatIcon = ({ className, id, isEmbedIcon, size }: ChatIconProps) => {
  const chatSettings = useContext(SettingsContext);

  const embedIconUrl = chatSettings?.embedIconUrl;
  const teamIconUrl = chatSettings?.teamIconUrl;

  const iconUrl = isEmbedIcon ? embedIconUrl : teamIconUrl;

  const getBackgroundSize = () => {
    if (isEmbedIcon && !embedIconUrl) {
      return 'contain';
    }

    if (iconUrl) {
      return 'cover';
    }

    let bgSize = size.width;

    if (!iconUrl) {
      if (size.width >= 50) {
        bgSize = size.width - 20;
      } else if (size.width >= 40) {
        bgSize = size.width - 17;
      } else if (size.width >= 30) {
        bgSize = size.width - 12;
      } else {
        bgSize = Math.max(size.width - 10, 10);
      }
    }

    return `${bgSize}px`;
  };

  const ariaLabel = Text({
    id: 'chatIconAriaLabel',
    fields: {
      teamName: chatSettings?.teamName,
    },
    defaultMessage: messages.chatIconAriaLabel,
  });
  return (
    <Localizer>
      <div
        id={id}
        role={'img'}
        aria-label={ariaLabel}
        class={classNames(styles.chatIcon, className)}
        data-kt="chat-icon"
        style={{
          width: `${size.width}px`,
          height: `${size.height}px`,
          backgroundSize: getBackgroundSize(),
          backgroundImage: `url(${
            iconUrl || kustyIcon(chatSettings?.embedIconColor)
          })`,
        }}
      />
    </Localizer>
  );
};

ChatIcon.defaultProps = {
  size: getChatIconSize(),
};

export default ChatIcon;

import {
  getCustomerSession,
  updateCurrentPage,
} from 'widget_main/globals/session';
import { getKustomerCore, getKustomer } from 'widget_main/globals/helpers';
import { CustomerDetails } from 'core_main/sdk/get_assistant/types';
import { getMappedLanguage } from 'i18n/helpers';
import { InitAssistantCallbackResponse } from 'core_main/sdk/init_assistant/types';
import { DeflectionArticle } from 'core_main/sdk/send_message/types';

export const findPageCondition = (condition) =>
  !!condition.session_currentPage ||
  !!condition.session_previousPage ||
  !!condition.currentPageSeconds;

export const findPageCriteria = (rule) => {
  const or = rule.criteria?.and?.find(findPageCondition);
  const and = rule.criteria?.or?.find(findPageCondition);

  return or || and;
};

export const startUpdatePageInterval = (settings) => {
  const { assistantRules } = settings;

  if (!assistantRules?.length) return undefined;

  const containsPageCriteria = assistantRules.find(findPageCriteria);

  if (containsPageCriteria) {
    return setInterval(() => {
      updateCurrentPage();
    }, 500);
  }

  return undefined;
};
export const getDefaultAssistant = (): Promise<
  Maybe<InitAssistantCallbackResponse>
> => {
  const settings = getKustomerCore().getChatSettings();
  if (settings.activeAssistant)
    return getKustomerCore().initAssistant({
      assistant: settings.activeAssistant,
      lang: getMappedLanguage(),
      assistantOptions: getKustomer()?.startParameters?.assistantOptions,
    });
  return Promise.resolve(undefined);
};

export const getActiveAssistant = (
  options: {
    customer?: CustomerDetails;
    proactiveOnly?: boolean;
    assistant?: string;
    dialog?: string;
  } = {},
): Promise<InitAssistantCallbackResponse | false> => {
  const activeAssistant = options.assistant
    ? {
        assistant: options.assistant,
        dialog: options.dialog,
      }
    : getKustomerCore().getAssistant({
        ...options,
        session: getCustomerSession(),
      });
  if (!activeAssistant) return Promise.resolve(false);

  const assistantId = getKustomer()?.startParameters?.assistantId;
  if (assistantId) {
    // set assistant to `assistantId` from query params in preview page
    activeAssistant.assistant = assistantId;
  }

  return getKustomerCore()
    .initAssistant({
      ...activeAssistant,
      lang: getMappedLanguage(),
      assistantOptions: getKustomer()?.startParameters?.assistantOptions,
    })
    .catch((e: Error) => {
      if (e.message.match(/4[0-9][0-9]/g)) {
        return Promise.reject(e);
      }
      return getDefaultAssistant();
    });
};

export const sendKbDeflectionClick = (
  sessionId: string,
  articles: DeflectionArticle[],
) => {
  // intentionally NOT returning the promise because if this fails, we can fail silently
  getKustomerCore()
    .sendKbDeflectionClick({ sessionId, articles })
    .catch(() => {
      console.error('Failed to send deflection click data');
    });
};

export const isAssistantPreview = () => {
  return !!getKustomer()?.startParameters?.assistantOptions?.emulation;
};

import { h } from 'preact';
import { SatisfactionCallbackResponse } from 'core_main/api/satisfaction/types';
import { useState, useContext } from 'preact/hooks';
import { getKustomerCore } from 'widget_main/globals/helpers';

import Button from 'widget_main/components/ui/Button';
import SatisfactionMessageScale from 'widget_main/screens/MessageThread/SatisfactionMessage/SatisfactionMessageScale';
import SatisfactionMessageModal from 'widget_main/screens/MessageThread/SatisfactionMessage/SatisfactionMessageModal';
import { ConversationsContext } from 'widget_main/store';
import { useTranslations } from 'i18n/hooks';
import { getMappedLanguage } from 'i18n/helpers';
import { SubmitSatisfactionFormParameters } from 'core_main/sdk/submit_satisfaction_form/types';
import styles from './satisfactionMessage.scss';
import messages from './messages';

const kustomerCore = getKustomerCore();
interface SatisfactionMessageProps {
  satisfaction: SatisfactionCallbackResponse;
}

const SatisfactionMessage = ({ satisfaction }: SatisfactionMessageProps) => {
  const conversations = useContext(ConversationsContext);

  const translations = useTranslations(messages);

  const { form, response, lockedAt } = satisfaction;

  const disableFeedback = lockedAt
    ? new Date().getTime() >= new Date(lockedAt).getTime()
    : false;

  const ratingPrompt = form?.ratingPrompt;
  const questions = form?.questions || [];
  const introduction = form?.introduction;

  const rating = response?.rating;

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const submit = (
    options: Partial<SubmitSatisfactionFormParameters>,
    callback?: (response: SatisfactionCallbackResponse) => void,
  ) => {
    kustomerCore.submitSatisfactionForm(
      {
        satisfactionId: satisfaction.satisfactionId,
        submittedAt: new Date().toISOString(),
        lang: getMappedLanguage(),
        ...options,
      },
      (response) => {
        if (satisfaction?.conversationId) {
          conversations.updateConversation(satisfaction.conversationId, {
            satisfaction: response,
          });

          if (typeof callback === 'function') callback(response);
        }
      },
    );
  };

  const submitRating = (rating) => {
    if (disableFeedback) {
      return;
    }
    submit({ rating }, (response) => {
      if (response.form?.questions?.length) {
        openModal();
      }
    });
  };

  const renderFeedbackButton = () => {
    if (!rating || !questions?.length) return null;

    let feedbackButtonText = translations.changeFeedbackButton;
    if (disableFeedback) {
      feedbackButtonText = translations.viewFeedback;
    }

    return (
      <Button
        variant="link"
        block
        className={styles.feedbackButton}
        ariaLabel={translations.changeFeedbackButtonAriaLabel}
        onClick={openModal}
      >
        <div className={styles.changeFeedback}>{feedbackButtonText}</div>
      </Button>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.satisfactionContainer}>
        <p className={styles.introduction}>{introduction}</p>
        <p className={styles.ratingPrompt}>{ratingPrompt}</p>
        <SatisfactionMessageScale
          rating={satisfaction.response?.rating}
          scale={satisfaction.form?.scale}
          onClick={submitRating}
          disabled={disableFeedback}
        />
      </div>
      {renderFeedbackButton()}
      {showModal && (
        <SatisfactionMessageModal
          show={showModal}
          onSubmit={submit}
          onHide={closeModal}
          satisfaction={satisfaction}
        />
      )}
    </div>
  );
};

SatisfactionMessage.defaultProps = {
  satisfaction: undefined,
};
export default SatisfactionMessage;

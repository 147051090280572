import { h, Fragment } from 'preact';
import { useContext, useRef, useState } from 'preact/hooks';
import classNames from 'classnames';
import {
  WidgetActionsContext,
  NavigationContext,
  ConversationsContext,
  SettingsContext,
  KBContext,
} from 'widget_main/store';
import {
  KB_ARTICLE_PATH,
  KB_DEFLECT_ARTICLE_PATH,
  MESSAGE_THREAD_PATH,
} from 'widget_main/globals/constants';
import ChevronLeftIcon from 'widget_main/globals/assets/chevronLeft.svg';
import CloseIcon from 'widget_main/globals/assets/close.svg';
import OpenInNewIcon from 'widget_main/globals/assets/open_in_new.svg';
import MinimizeIcon from 'widget_main/globals/assets/minimizeIcon.svg';
import Modal from 'widget_main/components/ui/Modal';
import Button from 'widget_main/components/ui/Button';
import TitleBarButton from 'widget_main/components/TitleBarButton';
import { useTranslations } from 'i18n/hooks';
import { getKustomerCore, getKustomer } from 'widget_main/globals/helpers';
import styles from './titleBarButtons.scss';

import messages from './messages';
import { constructExternalArticleLink } from './helpers';

const kustomerCore = getKustomerCore();

interface TitleBarButtonsProps {
  onGoBack?: () => void;
}

const TitleBarButtons = ({ onGoBack }: TitleBarButtonsProps) => {
  const widgetActions = useContext(WidgetActionsContext);
  const navigation = useContext(NavigationContext);
  const settings = useContext(SettingsContext);
  const conversations = useContext(ConversationsContext);
  const kb = useContext(KBContext);
  const modalInitialFocusRef = useRef<HTMLButtonElement>(null);
  const endChatButtonRef = useRef<HTMLButtonElement>(null);

  const [showEndChatModal, toggleEndChatModal] = useState(false);

  const translations = useTranslations(messages);

  const goBack = () => {
    if (onGoBack) {
      onGoBack();
      toggleEndChatModal(false);
    }
  };

  const handleClickEndChat = () => toggleEndChatModal(true);

  const closeEndChatModal = () => {
    toggleEndChatModal(false);
  };

  const cancelEndChat = () => {
    closeEndChatModal();
    endChatButtonRef.current?.focus();
  };

  const confirmEndChat = () => {
    if (navigation.currentConversationId) {
      conversations.endConversation(navigation.currentConversationId);
      kustomerCore.kustomerEventCount({
        name: 'title_bar.confirm_end_chat.click',
      });
    }
    closeEndChatModal();
  };

  const renderBackButton = () => {
    if (onGoBack) {
      return (
        <TitleBarButton
          ariaLabel={translations.goBackAriaLabel}
          onClick={goBack}
          className={classNames(styles.button, styles.backButton)}
        >
          <ChevronLeftIcon data-kt="backToHistoryIcon" />
        </TitleBarButton>
      );
    }

    return null;
  };

  const renderEndChatButton = () => {
    const { currentConversationId } = navigation;
    if (
      navigation.page !== MESSAGE_THREAD_PATH ||
      !settings.closableChat ||
      !currentConversationId ||
      conversations?.data?.[currentConversationId]?.ended
    ) {
      return null;
    }

    return (
      <TitleBarButton
        ariaLabel={translations.endChatAriaLabel}
        onClick={handleClickEndChat}
        className={styles.button}
        buttonRef={endChatButtonRef}
      >
        <CloseIcon width={18} height={18} data-kt="endChatIcon" />
      </TitleBarButton>
    );
  };

  const renderOpenInNewTab = () => {
    const isArticleView =
      navigation.page === KB_ARTICLE_PATH ||
      navigation.page === KB_DEFLECT_ARTICLE_PATH;
    if (
      !isArticleView ||
      !kb.currentArticleId ||
      !kb?.config.knowledgeBaseDomain ||
      getKustomer()?.startParameters?.hideArticleOpenInNewTab
    ) {
      return null;
    }
    const articleExternalUrl = constructExternalArticleLink(kb);
    return (
      <TitleBarButton
        ariaLabel={translations.openInNewTabAriaLabel}
        href={articleExternalUrl}
        className={styles.button}
      >
        <OpenInNewIcon width={18} height={18} />
      </TitleBarButton>
    );
  };

  const renderMinimizeButton = () => {
    return (
      <TitleBarButton
        ariaLabel={translations.minimizeChatAriaLabel}
        onClick={widgetActions.closeWidget}
        className={styles.button}
      >
        <MinimizeIcon width={18} height={18} data-kt="minimizeChatIcon" />
      </TitleBarButton>
    );
  };

  const renderEndChatModal = () => {
    return (
      <Modal
        initialFocusRef={modalInitialFocusRef}
        show={showEndChatModal}
        closeButton
        onHide={cancelEndChat}
        ariaLabelledby="endChatModalPrompt"
      >
        <div id="endChatModalPrompt" className={styles.endChatQuestion}>
          {translations.endChatModalPrompt}
        </div>
        <div className={styles.endChatModalButtons}>
          <Button
            buttonRef={modalInitialFocusRef}
            ariaLabel={translations.confirmEndChatAriaLabel}
            block
            className={styles.modalButton}
            variant="outlinePrimary"
            onClick={confirmEndChat}
            data-kt="endChat"
          >
            {translations.endChatModalButton}
          </Button>
          <Button
            ariaLabel={translations.cancelEndChatAriaLabelLabel}
            block
            className={classNames(styles.modalButton, styles.cancelButton)}
            variant="link"
            onClick={cancelEndChat}
            data-kt="cancel"
          >
            {translations.endChatModalCancelButton}
          </Button>
        </div>
      </Modal>
    );
  };

  return (
    <Fragment>
      {renderBackButton()}
      <div class={styles.right}>
        {renderOpenInNewTab()}
        {renderEndChatButton()}
        {renderMinimizeButton()}
      </div>
      {renderEndChatModal()}
    </Fragment>
  );
};

export default TitleBarButtons;

/**
 * This file is odd because it exists for the _rare_ case where translations
 * need to be accessible before the IntlProvider context is ready. Because of
 * how i18n currently works, all translations need to make their way into the
 * `src/i18n/messages.json` via the `npm run extract` command which consolidates
 * all `messages.json across the project into one file before translation
 * automatically occurs.
 *
 * NOTE: `pageTitleNotification` will not work with the custom <Text> component
 * in this package because it relies on translations being in this format
 *
 * {
 *  key: 'value'
 * }
 *
 * which is not the format used by preact-i18n's `translate` and other functions
 */
export default {
  browserNotificationTitle: '{{teamName}} Chat',
  pageTitleNotification: '({{number}}) chat',
};

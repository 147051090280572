import { useCallback, useEffect, useState } from 'preact/hooks';
import { RefObject } from 'preact';
import { isWidgetInFocus } from 'helpers/kustomer_api_iframe';

export const useTouchMove = (element) => {
  const handleTouchMove = useCallback(
    (event) => {
      const isScrollable =
        element?.scrollWidth > element?.clientWidth ||
        element?.scrollHeight > element?.clientHeight;

      if (!isScrollable) {
        event.preventDefault();
      }
    },
    [element],
  );

  useEffect(() => {
    element?.addEventListener('touchmove', handleTouchMove);

    return () => {
      element?.removeEventListener('touchmove', handleTouchMove);
    };
  }, [element, handleTouchMove]);
};

export const usePageVisibility = () => {
  const [pageIsVisible, setPageIsVisible] = useState(isWidgetInFocus());

  const setPageVisibility = () => setPageIsVisible(isWidgetInFocus());

  useEffect(() => {
    window.addEventListener('focus', setPageVisibility, false);
    window.addEventListener('blur', () => setPageIsVisible(false), false);

    return () => {
      window.removeEventListener('focus', setPageVisibility, false);
      window.removeEventListener('blur', () => setPageIsVisible(false), false);
    };
  }, []);
  return pageIsVisible;
};

export const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  {
    threshold = 0,
    root = null,
    rootMargin = '0px 0px 0px 0px',
  }: IntersectionObserverInit = {},
) => {
  const [state, setState] = useState<IntersectionObserverEntry | undefined>();
  useEffect(() => {
    const io = new IntersectionObserver(
      (entries) => {
        setState(entries[0]);
      },
      { root, rootMargin, threshold },
    );
    if (elementRef?.current) {
      io.observe(elementRef.current);
    }
    return () => io.disconnect();
  }, [elementRef?.current, root, rootMargin, threshold]);
  return state;
};

import { h, Fragment } from 'preact';
import { useRef, useState } from 'preact/hooks';
import { memo } from 'preact/compat';
import * as keyboardEventHelpers from 'widget_main/globals/keyboardEvents';
import { useTranslations } from 'i18n/hooks';
import MediaPreviewModal from './MediaPreviewModal';
import messages from './messages';

import styles from './mediaWithPreview.scss';
import { MediaContent } from './types';

interface MediaWithPreviewProps {
  className?: string;
  src: string;
  type: MediaContent;
}

const MediaWithPreview = ({ className, src, type }: MediaWithPreviewProps) => {
  const [showPreviewModal, togglePreviewModal] = useState(false);
  const translations = useTranslations(messages);
  const mediaContainerRef = useRef<HTMLButtonElement>(null);

  const openPreview = () => {
    togglePreviewModal(true);
  };

  const closePreview = () => {
    mediaContainerRef.current?.focus();
    togglePreviewModal(false);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (keyboardEventHelpers.isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();
      openPreview();
    }
  };

  const renderVideoPoster = () => {
    if (type !== 'video') return null;

    return (
      <video className={styles.videoPoster} src={`${src}#t=0.001`} muted />
    );
  };

  return (
    <Fragment>
      <button
        ref={mediaContainerRef}
        className={className}
        onClick={openPreview}
        onKeyPress={handleKeyPress}
        style={
          type === 'image' ? { backgroundImage: `url(${src})` } : undefined
        }
        aria-label={translations.attachmentPreviewLabel}
      >
        {renderVideoPoster()}
      </button>
      <MediaPreviewModal
        show={showPreviewModal}
        src={src}
        onHide={closePreview}
        type={type}
      />
    </Fragment>
  );
};

export default memo(MediaWithPreview);

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".conversationsHeader__conversationsHeader___-eXHO{background-color:var(--header-background-color);height:195px;flex-shrink:0;overflow:hidden;width:100%;border-bottom:.5px solid #e9eff5}.conversationsHeader__content___V8xis{padding:0 35px;display:flex;flex-direction:column;align-items:center;text-align:center}.conversationsHeader__chatIconWrapper___SRhTT{margin-top:25px;border-radius:50%}.conversationsHeader__teamName___af2jA{font-size:16px;font-weight:500;margin:10px 0 5px;color:var(--header-font-primary-color)}.conversationsHeader__greeting___UJAq3,.conversationsHeader__waitMessage___VaUQM{line-height:1.3;margin-top:5px;color:var(--header-font-secondary-color);width:100%;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;max-height:38px}.conversationsHeader__waitMessage___VaUQM{font-size:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"conversationsHeader": "conversationsHeader__conversationsHeader___-eXHO",
	"content": "conversationsHeader__content___V8xis",
	"chatIconWrapper": "conversationsHeader__chatIconWrapper___SRhTT",
	"teamName": "conversationsHeader__teamName___af2jA",
	"greeting": "conversationsHeader__greeting___UJAq3",
	"waitMessage": "conversationsHeader__waitMessage___VaUQM"
};
export default ___CSS_LOADER_EXPORT___;

import { h, Fragment } from 'preact';
import { useContext } from 'preact/hooks';
import ChatIcon from 'widget_main/components/ChatIcon';
import { SettingsContext, ChatAvailabilityContext } from 'widget_main/store';
import styles from './conversationsHeader.scss';

const ConversationsHeader = () => {
  const chatAvailability = useContext(ChatAvailabilityContext);
  const chatSettings = useContext(SettingsContext);
  const waitMessage = chatSettings?.waitMessage;
  const greeting = chatSettings?.greeting;

  const renderSubText = () => {
    if (chatAvailability === 'online') {
      return (
        <Fragment>
          <span class={styles.greeting}>{greeting}</span>
          <span class={styles.waitMessage}>{waitMessage}</span>
        </Fragment>
      );
    }

    return (
      <span className={styles.greeting}>{chatSettings?.offhoursMessage}</span>
    );
  };

  return (
    <div class={styles.conversationsHeader}>
      <div class={styles.content}>
        <ChatIcon
          className={styles.chatIconWrapper}
          size={{ width: 50, height: 50 }}
        />
        <h2 class={styles.teamName}>{chatSettings?.teamName}</h2>
        {renderSubText()}
      </div>
    </div>
  );
};

export default ConversationsHeader;

import { h } from 'preact';
import { useContext } from 'preact/hooks';
import CloseIcon from 'widget_main/globals/assets/close.svg';
import classNames from 'classnames';

import Button from 'widget_main/components/ui/Button';
import { useTranslations } from 'i18n/hooks';
import { ProactiveChatContext } from 'widget_main/store';
import styles from './closeProactiveChatButton.scss';
import messages from './messages';

const CloseProactiveChatButton = ({ className }) => {
  const translations = useTranslations(messages);
  const { onCloseProactiveChat } = useContext(ProactiveChatContext);
  return (
    <div className={styles.closeProactiveChatButtonWrapper}>
      <Button
        variant="outlinePrimary"
        className={classNames(styles.closeProactiveChatButton, className)}
        ariaLabel={translations.close}
        onClick={onCloseProactiveChat}
      >
        <CloseIcon width={26} height={26} />
      </Button>
    </div>
  );
};

export default CloseProactiveChatButton;

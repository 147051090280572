import { Fragment, h } from 'preact';
import { useContext, useEffect, useRef } from 'preact/hooks';
import classNames from 'classnames';
import {
  ConnectionContext,
  KBContext,
  NavigationContext,
} from 'widget_main/store';
import { useTranslations } from 'i18n/hooks';
import WifiStrengthOffOutline from 'widget_main/globals/assets/wifiStrengthOffOutline.svg';
import {
  CONVERSATIONS_PATH,
  MESSAGE_THREAD_PATH,
  MODAL_ROOT_ID,
  KB_SEARCH_PATH,
  KB_ARTICLE_PATH,
  KB_DEFLECT_ARTICLE_PATH,
} from 'widget_main/globals/constants';

import ErrorBoundary from 'widget_main/components/ErrorBoundary';
import { useLocale, useVendors } from 'widget_main/vendors/hooks';
import Loader from 'widget_main/components/ui/Loader';
import {
  focusOnFirstElementInWidget,
  useFocusLock,
} from 'widget_main/globals/dom';
import TitleBarButtons from '../TitleBarButtons';
import ConversationsHeader from '../../screens/Conversations/ConversationsHeader';
import MessageThreadHeader from '../../screens/MessageThread/MessageThreadHeader';
import Conversations from '../../screens/Conversations';
import MessageThread from '../../screens/MessageThread';
import KBSearchList from '../../screens/KBSearchList';
import Footer from '../Footer';
import styles from './widget.scss';
import KBHeader from '../KBHeader';
import KBArticle from '../../screens/KBArticle/KBArticle';

import messages from './messages';

interface WidgetProps {
  isMobile: boolean;
  hideHistory?: boolean;
  restartApp?: () => void;
  loading: boolean;
}

const Widget = ({
  isMobile,
  hideHistory,
  restartApp,
  loading,
}: WidgetProps) => {
  const chatWidgetRef = useRef<HTMLDivElement | null>(null);
  const navigationState = useContext(NavigationContext);
  const kb = useContext(KBContext);
  const translations = useTranslations(messages);
  const connection = useContext(ConnectionContext);
  const [, isLoadingVendors] = useVendors();
  const [isLoadingLocale] = useLocale();

  const focusLockRef = useFocusLock();

  const isLoading = isLoadingVendors || isLoadingLocale || loading;

  useEffect(() => {
    // trigger a focus on the iframe to ensure accessibility tools focus on the chat when it is
    //  opened using the Kustomer.open() call
    if (chatWidgetRef?.current) {
      focusOnFirstElementInWidget(chatWidgetRef.current);
    }
  }, [chatWidgetRef]);

  const onGoBackForMessageThreadPath = () => {
    navigationState.updatePage(CONVERSATIONS_PATH);
    navigationState.updateCurrentConversationId(undefined);
    navigationState.updateInitAssistantPayload(undefined);
  };

  const renderHeader = () => {
    switch (navigationState.page) {
      case CONVERSATIONS_PATH:
        return (
          <Fragment>
            <TitleBarButtons />
            <ConversationsHeader />
          </Fragment>
        );
      case MESSAGE_THREAD_PATH:
        return (
          <Fragment>
            <TitleBarButtons
              onGoBack={hideHistory ? undefined : onGoBackForMessageThreadPath}
            />
            <MessageThreadHeader />
          </Fragment>
        );
      case KB_SEARCH_PATH: {
        const { currentCategoryId } = kb;

        let onGoBack;

        if (currentCategoryId) {
          const category = kb.categories?.[currentCategoryId];
          const parent = category?.parentCategory;
          onGoBack = parent
            ? () => kb.updateCurrentCategoryId(parent)
            : undefined;
        }

        return (
          <Fragment>
            <TitleBarButtons onGoBack={onGoBack} />
            <KBHeader />
          </Fragment>
        );
      }
      case KB_ARTICLE_PATH: {
        return (
          <Fragment>
            <TitleBarButtons
              onGoBack={() => {
                navigationState.updatePage(KB_SEARCH_PATH);
              }}
            />
            <KBHeader />
          </Fragment>
        );
      }
      case KB_DEFLECT_ARTICLE_PATH: {
        return (
          <Fragment>
            <TitleBarButtons
              onGoBack={() => {
                navigationState.updatePage(MESSAGE_THREAD_PATH);
              }}
            />
            <KBHeader />
          </Fragment>
        );
      }
      default:
        return (
          <Fragment>
            <TitleBarButtons />
            <ConversationsHeader />
          </Fragment>
        );
    }
  };

  const routeWithErrorBoundary = (node) => {
    return (
      <ErrorBoundary page={navigationState.page} restartApp={restartApp}>
        {node}
      </ErrorBoundary>
    );
  };

  const renderRoute = () => {
    switch (navigationState.page) {
      case CONVERSATIONS_PATH:
        return routeWithErrorBoundary(<Conversations />);
      case MESSAGE_THREAD_PATH:
        return routeWithErrorBoundary(<MessageThread isMobile={isMobile} />);
      case KB_SEARCH_PATH:
        return routeWithErrorBoundary(<KBSearchList />);
      case KB_ARTICLE_PATH:
        return routeWithErrorBoundary(<KBArticle />);
      case KB_DEFLECT_ARTICLE_PATH:
        return routeWithErrorBoundary(<KBArticle />);
      default:
        return routeWithErrorBoundary(<Conversations />);
    }
  };

  const renderConnectionIndicator = () => {
    if (connection === 'offline')
      return (
        <div
          className={styles.connectionContainer}
          data-kt="noInternetConnection"
        >
          <WifiStrengthOffOutline className={styles.wifiIcon} />
          <span>{translations.noInternetConnection}</span>
        </div>
      );

    return null;
  };

  const renderWidget = () => {
    return (
      <Fragment>
        {renderHeader()}
        {renderConnectionIndicator()}
        <div id={MODAL_ROOT_ID} className={styles.modalContainer}>
          {isLoading ? <Loader /> : renderRoute()}
          <Footer />
        </div>
      </Fragment>
    );
  };

  return (
    <div
      class={classNames(styles.widgetWrapper, {
        [styles.mobile]: isMobile,
      })}
      ref={focusLockRef}
    >
      <div class={styles.widget} ref={chatWidgetRef}>
        {renderWidget()}
      </div>
    </div>
  );
};

export default Widget;

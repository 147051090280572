import { KustomerWidgetEventTypes } from 'widget/types';

// routes
export const CONVERSATIONS_PATH = 'conversations';
export const MESSAGE_THREAD_PATH = 'message_thread';
export const KB_ARTICLE_PATH = 'kb-article';
export const KB_DEFLECT_ARTICLE_PATH = 'kb-deflect-article';
export const KB_SEARCH_PATH = 'kb-search';
export const MODAL_ROOT_ID = 'kustomer-ui-modal-root';

export const WIDGET_TYPE_CHAT = 'chat';

export const WIDGET_TYPE_KB = 'kb';

export const WIDGET_TYPE_CHAT_KB = 'chat-kb';

// KustomerWidgetEventTypes
export const kustomerWidgetEventTypes: Record<
  string,
  KustomerWidgetEventTypes
> = {
  onUnread: 'onUnread',
  onConversationCreate: 'onConversationCreate',
  onOpen: 'onOpen',
  onClose: 'onClose',
  onLogin: 'onLogin',
  onLogout: 'onLogout',
};

export const DEFAULT_SESSION_PAGE_SIZE = 100;
export const OFFLINE = 'offline';

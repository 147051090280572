import { h } from 'preact';
import { useContext } from 'preact/hooks';
import classNames from 'classnames';
import { UnreadCountsContext } from 'widget_main/store';
import styles from './unread.scss';

interface UnreadProps {
  conversationId?: string;
  className?: string;
  withoutCount?: boolean;
  limitCount?: boolean;
}

const Unread = ({
  className,
  conversationId,
  withoutCount,
  limitCount,
}: UnreadProps) => {
  const unreadCounts = useContext(UnreadCountsContext);

  const unreadCount = conversationId
    ? unreadCounts.conversations[conversationId]
    : unreadCounts?.total;

  if (!unreadCount) return null;

  let count = withoutCount ? '' : `${unreadCount}`;

  if (limitCount && count.length) {
    count = unreadCount > 9 ? '9+' : count;
  }

  return <div className={classNames(styles.unread, className)}>{count}</div>;
};

export default Unread;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".listAction__listItemContainer___I8E8Q{justify-content:flex-end;align-items:flex-end;width:100%;cursor:pointer}.listAction__listItemContainer___I8E8Q .listAction__listItem___LUIo0{border-radius:3px;padding:10px;background-color:var(--agent-bubble-background-color);box-shadow:0 2px 6px 0 rgba(201,201,201,.5);color:var(--agent-bubble-primary-font-color);margin-top:10px;display:flex;align-items:center}.listAction__listItemContainer___I8E8Q .listAction__listItem___LUIo0:hover{box-shadow:0 0 5px 0 var(--primary-button-background-hover-color)}.listAction__listItemContainer___I8E8Q img{width:100px;height:70px}.listAction__infoContainer___P0O-F{display:flex;flex-direction:column;margin-left:10px}.listAction__detailsText___viir5{margin-top:10px;color:var(--agent-bubble-secondary-font-color);font-size:12px}html[dir=\"rtl\"] .listAction__infoContainer___P0O-F{margin-left:initial;margin-right:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"listItemContainer": "listAction__listItemContainer___I8E8Q",
	"listItem": "listAction__listItem___LUIo0",
	"infoContainer": "listAction__infoContainer___P0O-F",
	"detailsText": "listAction__detailsText___viir5"
};
export default ___CSS_LOADER_EXPORT___;

import { h } from 'preact';
import {
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'preact/hooks';
import classNames from 'classnames';

import {
  getMessageAgentInformation,
  getLastMessagePreview,
  setKustomerLocalStorage,
  getLastAgentMessageInConversation,
} from 'widget_main/globals/helpers';
import {
  NavigationContext,
  ConversationsContext,
  SettingsContext,
  UnreadCountsContext,
  ChatAvailabilityContext,
  HideNewConversationButtonSettingsContext,
  MessagesContext,
} from 'widget_main/store';
import {
  CONVERSATIONS_PATH,
  MESSAGE_THREAD_PATH,
  WIDGET_TYPE_CHAT_KB,
} from 'widget_main/globals/constants';
import * as keyboardEventHelpers from 'widget_main/globals/keyboardEvents';

import ConversationsEmpty from 'widget_main/globals/assets/conversationsEmpty.svg';
import Offline from 'widget_main/globals/assets/offline.svg';
import Avatar from 'widget_main/components/Avatar';
import TimeAgo from 'widget_main/components/TimeAgo';
import Unread from 'widget_main/components/Unread';
import NewConversationButton from 'widget_main/components/NewConversationButton';
import Markdown from 'widget_main/components/Markdown/Markdown';

import { ConversationEntity } from 'widget_main/store/types';

import { useTranslations } from 'i18n/hooks';
import { sanitizeHtml } from 'widget_main/vendors/hooks';
import { getFilteredConversations } from 'widget_main/screens/Conversations/helpers';
import messages from './messages';

import styles from './conversations.scss';
import Loader from '../../components/ui/Loader';

const Conversations = () => {
  const chatAvailability = useContext(ChatAvailabilityContext);
  const hideNewConversationButtonSettings = useContext(
    HideNewConversationButtonSettingsContext,
  );
  const navigation = useContext(NavigationContext);
  const conversations = useContext(ConversationsContext);
  const messagesState = useContext(MessagesContext);
  const unreadCounts = useContext(UnreadCountsContext);
  const chatSettings = useContext(SettingsContext);

  const translations = useTranslations(messages);

  const [, forceUpdate] = useState({});

  useEffect(() => {
    setKustomerLocalStorage('lastOpenPage', CONVERSATIONS_PATH);

    const interval = setInterval(() => {
      forceUpdate({});
    }, 60000);
    return () => clearInterval(interval);
  }, []);
  const [fetchingHistory, setFetchingHistory] = useState(false);
  const previousScrollTop = useRef(0);
  const CONVERSATION_LIST_ID = 'conversationList';
  const conversationCount = Object.keys(conversations.data).length;
  useLayoutEffect(() => {
    // this hook will run only on first load to scroll to the last scroll location
    if (conversationCount && navigation.conversationListScrollLocation) {
      const conversationList = document.getElementById(
        CONVERSATION_LIST_ID,
      ) as HTMLElement;
      conversationList.scrollTop = navigation.conversationListScrollLocation;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useLayoutEffect(() => {
    // this hook will make sure that the scroll position is retained when loading older conversations
    if (conversationCount) {
      const conversationList = document.getElementById(
        CONVERSATION_LIST_ID,
      ) as HTMLElement;
      if (previousScrollTop.current) {
        conversationList.scrollTop = previousScrollTop.current;
        previousScrollTop.current = 0;
      }
    }
  }, [conversationCount]);
  useEffect(() => {
    // this hook clears the loading ui when messages are received
    if (conversationCount || !conversations.pages?.next)
      setFetchingHistory(false);
  }, [conversationCount, conversations.pages?.next]);
  const handleClickConversation = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    navigation.updateCurrentConversationId(id);
    navigation.updatePage(MESSAGE_THREAD_PATH);
  };

  const handleKeyPress = (e, id) => {
    if (keyboardEventHelpers.isEnterKey(e)) {
      handleClickConversation(e, id);
    }
  };

  const getSortedConversations = () => {
    const filteredConversations = getFilteredConversations(
      Object.values(conversations.data),
      chatSettings.noHistory,
      Object.keys(unreadCounts.conversations),
    );

    return filteredConversations.sort((a, b) => {
      const timestamp1 = a.lastMessageAt
        ? new Date(a.lastMessageAt).getTime()
        : 0;

      const timestamp2 = b.lastMessageAt
        ? new Date(b.lastMessageAt).getTime()
        : 0;

      if (timestamp1 > timestamp2) {
        return -1;
      }
      return 1;
    });
  };

  const renderEmpty = () => {
    if (chatAvailability === 'online') {
      return (
        <div className={styles.empty} data-kt="emptyConversationList">
          <img
            alt={translations.conversationsEmptyAltText}
            src={ConversationsEmpty}
            className={styles.conversationsEmpty}
          />
          <h4 className={styles.emptyTitle}>{translations.emptyTitle}</h4>
          <p className={styles.emptySubtitle}>{translations.emptySubtitle}</p>
        </div>
      );
    }

    return (
      <div className={styles.empty}>
        {chatSettings?.offhoursImageUrl ? (
          <img
            className={styles.offlineCustomImage}
            alt={translations.offHoursAltText}
            src={chatSettings?.offhoursImageUrl}
          />
        ) : (
          <img alt={translations.offlineAltText} src={Offline} />
        )}
        <h4 className={styles.emptyTitle}>{translations.offHoursEmptyTitle}</h4>
        <p className={styles.emptySubtitle}>
          {translations.offHoursEmptySubtitle}
        </p>
      </div>
    );
  };

  const renderPreviewText = (previewText?) => {
    if (!previewText) {
      return null;
    }

    return (
      <Markdown
        markdown={previewText}
        formatHtml={(html) =>
          sanitizeHtml(
            html.replace(/<img.*>/g, translations.previewAttachmentReceived),
            {
              ALLOWED_TAGS: ['#text'],
            },
          )
        }
      />
    );
  };

  const renderConversationItem = (conversation: ConversationEntity) => {
    const { conversationId, lastMessageAt, ended } = conversation;

    const lastMessagePreview = getLastMessagePreview(conversation);

    const lastAgentMessage = getLastAgentMessageInConversation(
      conversation,
      messagesState,
    );

    const lastMessageAgentData = getMessageAgentInformation(
      lastAgentMessage?.sentBy ?? conversation.lastResponder,
      chatSettings,
    );

    return (
      <div
        role="button"
        tabIndex={0}
        key={conversationId}
        class={classNames(styles.conversation, {
          [styles.unreadConversation]:
            !!unreadCounts.conversations[conversationId],
          [styles.endedConversation]: ended,
        })}
        onKeyPress={(e) => handleKeyPress(e, conversationId)}
        onClick={(e) => handleClickConversation(e, conversationId)}
      >
        <Avatar
          className={styles.avatar}
          size={40}
          avatarUrl={lastMessageAgentData.avatarUrl}
          userDisplayName={lastMessageAgentData.displayName}
        />
        <div
          class={styles.conversationDetails}
          data-kt="conversationRowDetails"
        >
          <div class={styles.conversationHeader}>
            <div class={styles.agentName}>
              {lastMessageAgentData.displayName}
            </div>
            {ended && (
              <div className={styles.timestamp}>{translations.ended}</div>
            )}
            {!ended && lastMessageAt && (
              <TimeAgo className={styles.timestamp} timestamp={lastMessageAt} />
            )}
          </div>
          <div class={styles.conversationBody}>
            <div class={styles.preview}>
              {renderPreviewText(lastMessagePreview)}
            </div>
            <Unread
              className={styles.unreadProp}
              conversationId={conversationId}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderGetPreviousConversationsLoader = () => {
    return (
      <div
        className={classNames(styles.loaderWrapper, {
          [styles.showLoader]: fetchingHistory && !!conversations.pages?.next,
        })}
      >
        <Loader className={styles.loader} />
      </div>
    );
  };
  const renderConversationsList = () => {
    const conversationsList = getSortedConversations();

    return (
      <div class={styles.conversationList}>
        {conversationsList.map(renderConversationItem)}
        {conversationsList.length === 0 && renderEmpty()}
        {renderGetPreviousConversationsLoader()}
      </div>
    );
  };

  const renderNewConversationButton = () => {
    const openConversationFound = Object.values(conversations.data).find(
      (conversation) => !conversation.ended,
    );

    if (
      hideNewConversationButtonSettings.onChatHistory ||
      (chatSettings.singleSessionChat && openConversationFound)
    ) {
      return null;
    }

    return (
      <NewConversationButton
        data-kt="NewConversationButton"
        className={classNames(styles.createConversationButton, {
          [styles.createConversationButtonWithNavigation]:
            chatSettings.widgetType === WIDGET_TYPE_CHAT_KB,
        })}
      />
    );
  };
  const handleScrollConversationList = (e) => {
    const DISTANCE_TO_BOTTOM_TO_LOAD_MORE = 10;
    const element = e.target;
    navigation.updateConversationListScrollLocation(element.scrollTop);
    const isScrolledToBottom =
      element.scrollHeight -
        element.scrollTop -
        DISTANCE_TO_BOTTOM_TO_LOAD_MORE <=
      element.clientHeight;
    if (
      isScrolledToBottom &&
      conversationCount &&
      conversations.pages?.next &&
      !fetchingHistory
    ) {
      setFetchingHistory(true);
      previousScrollTop.current = element.scrollTop;
      conversations.fetchNextPageOfHistory();
    }
  };
  return (
    <div
      class={styles.container}
      onScroll={handleScrollConversationList}
      id={CONVERSATION_LIST_ID}
    >
      {renderConversationsList()}
      {renderNewConversationButton()}
    </div>
  );
};

export default Conversations;

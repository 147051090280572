import sizings from 'widget_main/styles/sizings.scss';

import {
  maybeAddDisableZoomMetaTag,
  maybeRemoveDisableZoomMetaTag,
} from 'helpers/viewport_meta_tag';

import { getChatIconPosition, getChatIconSize } from 'widget_main/app/helpers';
import { ChatIconPosition, ChatIconSize } from 'widget/widget_api/types';
import { IframeSizeOverrides } from './types';

const CHAT_ICON_PADDING = 20;
const WIDGET_OPEN_ANIMATION =
  'width 200ms ease 0s, height 200ms ease 0s, max-height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s, opacity 83ms ease-out 0s';

class _KustomerApiIFrameElement {
  readonly IFRAME_ID: string;

  readonly getParentDocument: () => Document;

  readonly getIframe: () => HTMLIFrameElement;

  constructor() {
    this.IFRAME_ID = 'kustomer-ui-sdk-iframe';
    this.getParentDocument = () => window?.parent?.document;
    this.getIframe = () =>
      this.getParentDocument().getElementById(
        this.IFRAME_ID,
      ) as HTMLIFrameElement;
  }

  setIframeSizeFullScreen(position: ChatIconPosition) {
    const selfIframe = this.getIframe();
    maybeAddDisableZoomMetaTag();
    this.addKustomerMobileActiveClass();
    selfIframe.style.transition = WIDGET_OPEN_ANIMATION;
    selfIframe.style.width = '100%';
    selfIframe.style.height = '100%';
    selfIframe.style.maxHeight = '100%';
    selfIframe.style.maxWidth = '100%';
    selfIframe.style.minWidth = '';
    selfIframe.style.minHeight = '';
    selfIframe.style.bottom = '0';
    selfIframe.style.display = 'block';
    if (position.alignment === 'right') {
      selfIframe.style.right = '0';
    } else {
      selfIframe.style.left = '0';
    }
    return selfIframe;
  }

  setIframeSize(overrides: IframeSizeOverrides, position: ChatIconPosition) {
    const selfIframe = this.getIframe();
    maybeRemoveDisableZoomMetaTag();
    this.removeKustomerMobileActiveClass();
    selfIframe.style.transition =
      overrides?.transition || WIDGET_OPEN_ANIMATION;
    selfIframe.style.width = overrides?.frameWidth;
    selfIframe.style.height = overrides?.frameHeight;
    selfIframe.style.minHeight = overrides.frameMinHeight;
    selfIframe.style.maxHeight =
      overrides.frameMaxHeight || sizings.widgetFrameMaxHeight;
    selfIframe.style.minWidth = overrides.frameMinWidth || '';
    selfIframe.style.maxWidth = sizings.widgetMaxWidth;
    selfIframe.style.bottom = `${position.verticalPadding}px`;
    selfIframe.style.right = `${position.horizontalPadding}px`;
    selfIframe.style.display = overrides.display || 'block';
    if (position.alignment === 'right') {
      selfIframe.style.right = `${position.horizontalPadding}px`;
      selfIframe.style.left = '';
    } else {
      selfIframe.style.left = `${position.horizontalPadding}px`;
      selfIframe.style.right = '';
    }
    return selfIframe;
  }

  addKustomerMobileActiveClass() {
    const parentDocument = this.getParentDocument();
    if (parentDocument) {
      // delay adding the mobile active class to ensure that the sizing animation has finished (250ms)
      setTimeout(() => {
        parentDocument.documentElement.classList?.add('kustomer-mobile-active');
      }, 300);
    }
  }

  removeKustomerMobileActiveClass() {
    const parentDocument = this.getParentDocument();
    if (parentDocument) {
      parentDocument.documentElement.classList?.remove(
        'kustomer-mobile-active',
      );
    }
  }
}

export const iFrameElement = new _KustomerApiIFrameElement();

export const getChatIconFrameSize = (): ChatIconSize => {
  const iconSize = getChatIconSize();
  return {
    width: iconSize.width + CHAT_ICON_PADDING,
    height: iconSize.height + CHAT_ICON_PADDING,
  };
};

export const setIframeSize = (overrides: IframeSizeOverrides) => {
  const position = getChatIconPosition();
  return iFrameElement.setIframeSize(overrides, position);
};

export const setIframeSizeDefaultWidget = () => {
  return setIframeSize({
    frameHeight: sizings.widgetFrameHeight,
    frameWidth: sizings.widgetFrameWidth,
    frameMinHeight: `${getChatIconFrameSize().height}px`,
  });
};

export const setIframeSizeWithExpandedUi = () => {
  return setIframeSize({
    frameWidth: `45%`,
    frameHeight: '98%',
    frameMinHeight: '400px',
    frameMaxHeight: '98%',
    frameMinWidth: '400px',
  });
};

export const setIframeSizeFullScreen = () => {
  const position = getChatIconPosition();
  return iFrameElement.setIframeSizeFullScreen(position);
};

export const setIframeSizeCustom = (overrides) => {
  return setIframeSize({
    frameHeight: overrides.frameHeight,
    frameWidth: overrides.frameWidth,
    frameMinHeight: `${getChatIconFrameSize().height}px`,
  });
};

export const setIframeSizeHidden = () => {
  return setIframeSize({
    frameHeight: '0',
    frameWidth: '0',
    frameMinHeight: '0',
    display: 'none',
  });
};

export const setIframeSizeChatIcon = (isChatIconHidden?: boolean) => {
  if (isChatIconHidden) {
    return setIframeSizeHidden();
  }
  return setIframeSize({
    frameHeight: `${getChatIconFrameSize().height}px`,
    frameMinHeight: `${getChatIconFrameSize().height}px`,
    frameWidth: `${getChatIconFrameSize().width}px`,
  });
};

export const focusOnIframe = () => {
  iFrameElement.getIframe().focus();
};

export const focusOnWindowContainingIframe = () => {
  iFrameElement.getIframe().contentWindow?.parent.focus();
};

export const isWidgetInFocus = () => {
  return Boolean(
    document.hasFocus() ||
      iFrameElement.getIframe().contentWindow?.document.hasFocus(),
  );
};

export const getParentWindowTitle = () => {
  const { contentWindow } = iFrameElement.getIframe();
  return contentWindow?.parent.document.title;
};

// only exporting for testing purposes
export const generateNotificationRegex = (text: string) => {
  const strippedNumbers = text.replaceAll(/\(\d+\) /g, '');
  return new RegExp(`\\(\\d+\\)+ ${strippedNumbers}`, 'gi');
};

export const appendChatNotificationToParentTitle = (
  notificationText: string,
) => {
  const { contentWindow } = iFrameElement.getIframe();
  if (contentWindow) {
    const currentTitle = getParentWindowTitle();
    const regex = generateNotificationRegex(notificationText);
    const titleContainsChatNotification =
      currentTitle && regex.test(currentTitle);
    if (titleContainsChatNotification) {
      // replace the current notification in the title
      contentWindow.parent.document.title = currentTitle.replaceAll(
        regex,
        notificationText,
      );
    } else {
      // prepend the notification to the title
      contentWindow.parent.document.title = `${notificationText} ${currentTitle}`;
    }
  }
};

export const clearChatNotificationFromParentTitle = (
  translatedString: string,
) => {
  const { contentWindow } = iFrameElement.getIframe();
  const currentTitle = getParentWindowTitle();
  const regex = generateNotificationRegex(translatedString);
  // clear the notification in the title
  if (contentWindow && currentTitle) {
    contentWindow.parent.document.title = currentTitle.replaceAll(regex, '');
  }
};

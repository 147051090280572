// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kbSearchListItem__itemContainer___xmnpE{display:flex;background-color:#fff;flex-grow:1;cursor:pointer;padding:0}.kbSearchListItem__itemContainer___xmnpE.kbSearchListItem__isLast___tncdA{border-bottom:.5px solid #e9eff5}.kbSearchListItem__itemContainer___xmnpE:hover{background-color:#f3f5f8}.kbSearchListItem__itemContainer___xmnpE:focus{background-color:#f3f5f8}.kbSearchListItem__container___czVaz{height:75px;width:100%;border-bottom:1px solid #e9eff5;padding:0 20px;display:flex;justify-content:space-between;align-items:center}.kbSearchListItem__container___czVaz.kbSearchListItem__isLast___tncdA{border-bottom:0}.kbSearchListItem__details___1sADg{display:flex;min-width:100px}.kbSearchListItem__itemIcon___fi-tX{margin:0 10px 0 0;color:#b8b8b8;width:18px;height:18px;flex-shrink:0}.kbSearchListItem__information___FUnrJ{display:flex;flex-direction:column;white-space:nowrap;overflow:hidden}.kbSearchListItem__title___B9qqB{text-overflow:ellipsis;overflow:hidden}.kbSearchListItem__description___wOUKK{font-size:12px;color:#717070;margin-top:10px;overflow:hidden;text-overflow:ellipsis}.kbSearchListItem__chevronIcon___HK\\+r2{color:#979797;flex-shrink:0}html[dir=\"rtl\"] .kbSearchListItem__itemContainer___xmnpE{padding:0 20px 0 0}html[dir=\"rtl\"] .kbSearchListItem__container___czVaz{padding:0 0 0 20px}html[dir=\"rtl\"] .kbSearchListItem__itemIcon___fi-tX{margin:0 0 0 10px}html[dir=\"rtl\"] .kbSearchListItem__chevronIcon___HK\\+r2{transform:rotate(180deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"itemContainer": "kbSearchListItem__itemContainer___xmnpE",
	"isLast": "kbSearchListItem__isLast___tncdA",
	"container": "kbSearchListItem__container___czVaz",
	"details": "kbSearchListItem__details___1sADg",
	"itemIcon": "kbSearchListItem__itemIcon___fi-tX",
	"information": "kbSearchListItem__information___FUnrJ",
	"title": "kbSearchListItem__title___B9qqB",
	"description": "kbSearchListItem__description___wOUKK",
	"chevronIcon": "kbSearchListItem__chevronIcon___HK+r2"
};
export default ___CSS_LOADER_EXPORT___;

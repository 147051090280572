import { h } from 'preact';
import classNames from 'classnames';

import styles from './loader.scss';
import { LoaderState } from './types';

const Loader = ({ className }: LoaderState) => (
  <div className={classNames(styles.loader, className)} />
);

export default Loader;

import { h, JSX } from 'preact';
import { memo } from 'preact/compat';
import * as keyboardEventHelpers from 'widget_main/globals/keyboardEvents';
import styles from './radio.scss';

interface RadioProps {
  name: string;
  ariaLabel?: string | JSX.Element;
  onClick: () => unknown;
  checked?: boolean;
  id?: string;
  label?: string | JSX.Element;
  className?: string;
  disabled?: boolean;
}

const Radio = ({
  id,
  name,
  ariaLabel,
  onClick,
  checked,
  label,
  className,
  disabled,
}: RadioProps) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onClick();
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (keyboardEventHelpers.isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();

      onClick();
    }
  };

  return (
    <div
      role="radio"
      tabIndex={0}
      className={styles.container}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      aria-checked={checked}
    >
      <input
        type="radio"
        id={id || name}
        name={name}
        aria-label={ariaLabel || name}
        class={className}
        checked={checked}
        disabled={disabled}
        onClick={handleClick}
      />
      <label class={styles.label}>{label}</label>
    </div>
  );
};

export default memo(Radio);

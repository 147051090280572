import { h } from 'preact';
import { useContext } from 'preact/hooks';
import classNames from 'classnames';

import MessageProcessingIcon from 'widget_main/globals/assets/messageProcessing.svg';
import BookOpenPageVariantIcon from 'widget_main/globals/assets/bookOpenPageVariant.svg';

import { NavigationContext, SettingsContext } from 'widget_main/store';
import {
  CONVERSATIONS_PATH,
  KB_ARTICLE_PATH,
  KB_SEARCH_PATH,
  MESSAGE_THREAD_PATH,
  WIDGET_TYPE_CHAT_KB,
} from 'widget_main/globals/constants';
import Unread from 'widget_main/components/Unread';
import { isEnterKey } from 'widget_main/globals/keyboardEvents';
import { useTranslations } from 'i18n/hooks';

import { getKustomerCore } from 'widget_main/globals/helpers';
import messages from './messages';

import styles from './footer.scss';
import FooterConsent from './FooterConsent';

const kustomerCore = getKustomerCore();
const Footer = () => {
  const navigation = useContext(NavigationContext);
  const settings = useContext(SettingsContext);

  const { showBrandingIdentifier } = settings;

  const translations = useTranslations(messages, ['answers']);

  const currentPage = navigation.page;

  const isKBPath =
    currentPage === KB_SEARCH_PATH || currentPage === KB_ARTICLE_PATH;

  const isChatPath =
    currentPage === CONVERSATIONS_PATH || currentPage === MESSAGE_THREAD_PATH;

  const goToKBPath = () => {
    navigation.updatePage(KB_SEARCH_PATH);
  };

  const goToChatPath = () => {
    navigation.updatePage(CONVERSATIONS_PATH);
  };

  const handleKBClick = () => {
    kustomerCore.kustomerEventCount({ name: 'footer.kb_button.click' });
    goToKBPath();
  };

  const handleKBKeyPress = (e: KeyboardEvent) => {
    if (isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();
      kustomerCore.kustomerEventCount({
        name: 'footer.kb_button.keypress',
      });
      goToKBPath();
    }
  };

  const handleChatClick = () => {
    kustomerCore.kustomerEventCount({ name: 'footer.chat_button.click' });
    goToChatPath();
  };

  const handleChatKeyPress = (e: KeyboardEvent) => {
    if (isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();
      kustomerCore.kustomerEventCount({
        name: 'footer.chat_button.keypress',
      });
      goToChatPath();
    }
  };

  const renderFooterNavigation = () => {
    if (settings.widgetType !== WIDGET_TYPE_CHAT_KB) return null;

    return (
      <div
        className={styles.footerNavigation}
        role="tablist"
        aria-labelledby={translations.answers}
      >
        <div
          onKeyPress={handleKBKeyPress}
          role="tab"
          tabIndex={0}
          aria-controls={'kbSearchList'}
          aria-label={translations.answers}
          aria-selected={isKBPath}
          onClick={isKBPath ? undefined : handleKBClick}
          className={classNames(styles.itemContainer, {
            [styles.highlighted]: isKBPath,
          })}
        >
          <BookOpenPageVariantIcon
            className={styles.icon}
            data-kt="footerNavigationKbIcon"
            aria-label={`${translations.answers} icon`}
          />
          <p>{translations.answers}</p>
        </div>
        <div
          onKeyPress={handleChatKeyPress}
          role="tab"
          aria-controls="conversationList"
          aria-label={translations.footerNavigationChat}
          aria-selected={isChatPath}
          tabIndex={0}
          onClick={isChatPath ? undefined : handleChatClick}
          className={classNames(styles.itemContainer, {
            [styles.highlighted]: isChatPath,
          })}
        >
          <MessageProcessingIcon
            className={styles.icon}
            data-kt="footerNavigationChatIcon"
            aria-label={`${translations.footerNavigationChat} icon`}
          />
          <div className={styles.chatContainer}>
            <p>{translations.footerNavigationChat}</p>
            {isKBPath && <Unread className={styles.unread} limitCount />}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.footerContainer}>
      {renderFooterNavigation()}
      <div className={styles.footer}>
        {showBrandingIdentifier ? <div>{translations.footerText}</div> : null}
        <FooterConsent />
      </div>
    </div>
  );
};

export default Footer;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".avatar__avatar___6-N\\+m{border-radius:50%;overflow:hidden}.avatar__customImg___P8f5Z{width:100%;height:100%;background-size:100%;display:initial}.avatar__acronymWrapper___GjLD4{display:flex;align-items:center;justify-content:center;background-color:var(--primary-button-background-color);color:var(--primary-button-font-color);height:100%;text-transform:uppercase}.avatar__hideImg___OtZCK{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"avatar": "avatar__avatar___6-N+m",
	"customImg": "avatar__customImg___P8f5Z",
	"acronymWrapper": "avatar__acronymWrapper___GjLD4",
	"hideImg": "avatar__hideImg___OtZCK"
};
export default ___CSS_LOADER_EXPORT___;

import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { getHTMLFromMarkdown } from 'widget_main/components/Markdown/helpers';
import MediaPreviewModal from 'widget_main/components/MediaWithPreview/MediaPreviewModal/MediaPreviewModal';
import { MediaContent } from 'widget_main/components/MediaWithPreview/types';
import styles from './markdown.scss';

interface MarkdownProps {
  markdown: string;
  options?: marked.MarkedOptions;
  formatHtml?: (html: string) => string;
}

interface MediaPreviewData {
  src: string;
  type: MediaContent;
}

const Markdown = ({
  markdown,
  options,
  formatHtml = (html) => html,
}: MarkdownProps) => {
  const markdownRef = useRef<HTMLDivElement>(null);
  const html = getHTMLFromMarkdown(markdown, options);
  const [mediaPreviewData, setMediaPreviewData] =
    useState<MediaPreviewData | null>(null);

  const closeMediaPreview = () => {
    setMediaPreviewData(null);
    const media = markdownRef.current?.querySelector('img, video') as
      | HTMLImageElement
      | HTMLVideoElement;
    if (media) {
      // force the image/video to be tabbable for focusing purposes
      media.tabIndex = -1;
      media.focus();
    }
  };

  const openMediaPreviewOnImage = (e) => {
    setMediaPreviewData({
      src: (e.currentTarget as HTMLImageElement).src,
      type: 'image',
    });
  };

  useEffect(() => {
    const markdownContainer = markdownRef.current;
    const images = markdownContainer?.querySelectorAll('img');
    images?.forEach((image) => {
      image.addEventListener('click', openMediaPreviewOnImage);
    });
    return () => {
      images?.forEach((image) => {
        image.removeEventListener('click', openMediaPreviewOnImage);
      });
    };
  }, [markdown]);

  return (
    <div ref={markdownRef}>
      <div
        className={styles.markdownBody}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: formatHtml(html) }}
      />
      {mediaPreviewData ? (
        <MediaPreviewModal
          show={true}
          onHide={closeMediaPreview}
          src={mediaPreviewData.src}
          type={mediaPreviewData.type}
        />
      ) : null}
    </div>
  );
};

export default Markdown;

import { getAppLocale } from 'i18n/helpers';

export const MINUTE = 60000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export const getTimeDifference = (date1: string, date2: string) => {
  const date1InMs = new Date(date1).getTime();
  const date2InMs = new Date(date2).getTime();

  return Math.abs(date1InMs - date2InMs);
};

export const getTimeAgo = (date: string) => {
  let rtf;

  const lang = getAppLocale();

  if (!lang) return undefined;

  try {
    const formattedLang = lang.replace('_', '-');
    rtf = new Intl.RelativeTimeFormat(formattedLang, { style: 'short' });
  } catch (error) {
    return undefined;
  }

  const messageDate = new Date(date).getTime();
  const now = new Date().getTime();

  const timeAgo = now - messageDate;

  if (timeAgo >= DAY) {
    const duration = Math.floor(timeAgo / DAY);
    return rtf.format(-duration, 'day');
  }

  if (timeAgo >= HOUR) {
    const duration = Math.floor(timeAgo / HOUR);
    return rtf.format(-duration, 'hour');
  }

  if (timeAgo <= MINUTE) {
    return 'Just now';
  }

  if (timeAgo > MINUTE) {
    const duration = Math.floor(timeAgo / MINUTE);
    return rtf.format(-duration, 'minute');
  }

  return timeAgo;
};

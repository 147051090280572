import { h } from 'preact';
import { useState } from 'preact/hooks';
import classNames from 'classnames';
import { useTranslations } from 'i18n/hooks';
import { truncate } from 'widget_main/globals/helpers';
import ImagePlaceholder from 'widget_main/globals/assets/imagePlaceholder.svg';
import * as keyboardEventHelpers from 'widget_main/globals/keyboardEvents';
import { formatListTemplateBody } from '../helpers';

import styles from './listAction.scss';

import messages from './messages';

interface ListActionProps {
  detailsText?: string;
  titleText?: string;
  imageUrl?: string;
  value: string;
  onClick: (body: string, value: string) => void;
}

const ListAction = ({
  detailsText,
  titleText,
  imageUrl,
  onClick,
  value,
}: ListActionProps) => {
  const [imageError, setImageError] = useState(false);
  const translations = useTranslations(messages);

  const handleImageError = () => {
    setImageError(true);
  };

  const handleSubmit = () => {
    const body = formatListTemplateBody(titleText, detailsText);
    onClick(body, value);
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (keyboardEventHelpers.isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();

      handleSubmit();
    }
  };

  const image = (
    <img
      src={imageError ? ImagePlaceholder : imageUrl}
      alt={translations.listImageUrlAltText}
      onError={handleImageError}
    />
  );

  const truncatedTitle = truncate(titleText, imageUrl ? 30 : 75);

  const truncatedDetails = truncate(detailsText, imageUrl ? 50 : 75);

  return (
    <div
      className={classNames(styles.listItemContainer)}
      onClick={handleSubmit}
      role="button"
      tabIndex={0}
      onKeyPress={handleKeyPress}
    >
      <div class={styles.listItem}>
        <div>{imageUrl ? image : null}</div>
        <div className={styles.infoContainer}>
          <span>{truncatedTitle}</span>
          <span className={styles.detailsText}>{truncatedDetails}</span>
        </div>
      </div>
    </div>
  );
};

export default ListAction;

import { h, Fragment } from 'preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import 'emoji-picker-element';
import EmoticonIcon from 'widget_main/globals/assets/emoticon-happy-outline.svg';
import { isEscKey } from 'widget_main/globals/keyboardEvents';
import { useTranslations } from 'i18n/hooks';
import messages from './messages';
import styles from './EmojiPicker.scss';

export default function EmojiButton({
  onEmoji,
  onClose = () => undefined,
}: {
  onEmoji: (emoji: string) => void;
  onClose?: () => void;
}) {
  const translations = useTranslations(messages);

  const [showPicker, setShowPicker] = useState(false);

  const emojiPickerRef = useRef<HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const closePicker = useCallback(() => {
    setShowPicker(false);
    onClose();
  }, [onClose]);

  const togglePicker = useCallback(() => {
    setShowPicker(!showPicker);
  }, [showPicker]);

  const onKeyPress = useCallback((e) => {
    if (isEscKey(e)) {
      closePicker();
    }
  }, []);

  const onEmojiSelect = useCallback((e) => {
    onEmoji(e.detail.unicode);
    setShowPicker(false);
  }, []);

  useEffect(() => {
    if (!showPicker) {
      return () => undefined;
    }

    emojiPickerRef?.current?.shadowRoot?.getElementById('search')?.focus();

    // if the user clicks out of the picker, close the picker
    // exception is if clicks on button, cuz the button itself has a handler to reopen, so we go in circles
    function handleClickOutside(event) {
      if (
        contentRef.current &&
        !contentRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        closePicker();
      }
    }
    document.addEventListener('keydown', onKeyPress);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('keydown', onKeyPress);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPicker]);

  useEffect(() => {
    const currentRef = emojiPickerRef.current;

    // custom styles injected into the shadow dom of the picker
    const style = document.createElement('style');
    style.textContent = `.picker { border: none; }`;
    currentRef?.shadowRoot?.appendChild(style);

    // listen for emoji click event. remove this listener
    currentRef?.addEventListener('emoji-click', onEmojiSelect);

    return () => {
      currentRef?.shadowRoot?.removeChild(style);
      currentRef?.removeEventListener('emoji-click', onEmojiSelect);
    };
  }, [showPicker]);

  return (
    <Fragment>
      <button
        className={styles.emojiIconButton}
        ref={buttonRef}
        onClick={togglePicker}
        aria-label={translations.emojiPickerButtonAriaLabel}
      >
        <EmoticonIcon />
      </button>
      {showPicker ? (
        <div ref={contentRef} className={styles.tooltip}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <emoji-picker ref={emojiPickerRef} />
        </div>
      ) : null}
    </Fragment>
  );
}

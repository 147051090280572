// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes loader__animation-spinner___KLQeW{0%{transform:rotate(-360deg)}50%{transform:rotate(-180deg)}100%{transform:rotate(0deg)}}.loader__loader___kQxuj{width:35px;height:35px;border-radius:100%;border-width:1px;border-style:solid;border-image:initial;border-color:#717070 #717070 rgba(0,0,0,0);display:inline-block;animation:loader__animation-spinner___KLQeW 1s linear infinite;background:rgba(0,0,0,0) !important;margin-top:auto;margin-left:auto;margin-right:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"loader": "loader__loader___kQxuj",
	"animation-spinner": "loader__animation-spinner___KLQeW"
};
export default ___CSS_LOADER_EXPORT___;

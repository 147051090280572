// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messageThreadHeader__messageThreadHeader___HkJKF{background-color:var(--header-background-color);min-height:76px;width:100%;padding:10px 65px 10px 45px;display:flex;align-items:center;border-bottom:.5px solid #e9eff5}.messageThreadHeader__avatar___9dQ-x{flex-shrink:0}.messageThreadHeader__info___sWSsw{margin:0 0 0 10px;display:flex;flex-direction:column}.messageThreadHeader__teamName___00zcO{font-weight:500;color:var(--header-font-primary-color)}.messageThreadHeader__waitMessage___ia8b7{font-size:12px;margin-top:5px;color:var(--header-font-secondary-color);line-height:normal;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:2;max-height:30px}html[dir=\"rtl\"] .messageThreadHeader__messageThreadHeader___HkJKF{padding:10px 45px 10px 65px}html[dir=\"rtl\"] .messageThreadHeader__info___sWSsw{margin:0 10px 0 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"messageThreadHeader": "messageThreadHeader__messageThreadHeader___HkJKF",
	"avatar": "messageThreadHeader__avatar___9dQ-x",
	"info": "messageThreadHeader__info___sWSsw",
	"teamName": "messageThreadHeader__teamName___00zcO",
	"waitMessage": "messageThreadHeader__waitMessage___ia8b7"
};
export default ___CSS_LOADER_EXPORT___;

import type { GetMessagesCallbackResponse } from 'core_main/sdk/get_messages/types';
import { getKustomerCore } from 'widget_main/globals/helpers';
import { GetHistoricalMessagesParameters } from 'core_main/sdk/get_historical_messages/types';
import { SatisfactionCallbackResponse } from 'core_main/api/satisfaction/types';
import type { FetchMessageHistoryHelperOptions } from '../types';

const kustomerCore = getKustomerCore();

// paginate and get all historical messages from Kustomer's API
// - then mark the conversation as "hasAllMessages"
export const fetchAllMessagesFromKApi = (
  conversationId: string,
  options: FetchMessageHistoryHelperOptions,
): Promise<GetMessagesCallbackResponse> => {
  let done = false;
  const output: GetMessagesCallbackResponse = {
    messages: [],
    satisfaction: {} as SatisfactionCallbackResponse,
    conversationId,
  };
  // create recursive fetchFn
  const fetchMsgs = (
    conversationId: string,
    options: FetchMessageHistoryHelperOptions,
  ) =>
    new Promise<GetMessagesCallbackResponse>((resolve, reject) => {
      const params: GetHistoricalMessagesParameters = {
        conversationId,
        count: options.count,
      };
      if (options.before) {
        params.before = options.before;
      }
      kustomerCore.getHistoricalMessages(
        params,
        (response: GetMessagesCallbackResponse, error) => {
          if (error) {
            reject(error);
            return;
          }
          resolve(response);
        },
      );
    }).then((res) => {
      done = options.count
        ? res.messages.length < options.count
        : res.messages.length === 0;

      output.conversationId = res.conversationId;
      output.satisfaction = res.satisfaction;
      output.messages = [...output.messages, ...res.messages];
      if (!done && res.links?.next) {
        const p = new URLSearchParams(res.links.next.split('?')[1]);
        const before = p.get('before');
        if (before) {
          return fetchMsgs(conversationId, {
            ...options,
            before: decodeURIComponent(before),
          });
        }
      }
      return output;
    });
  return fetchMsgs(conversationId, options);
};

// wrap core API PubNub call in a promise
export const fetchAllMessagesFromPubNub = (
  conversationId: string,
): Promise<GetMessagesCallbackResponse> => {
  return new Promise((resolve, reject) => {
    kustomerCore.getMessages(
      { conversationId },
      (response: GetMessagesCallbackResponse, error) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(response);
      },
    );
  });
};

import {
  CategoryCallbackResponse,
  GetCategoriesCallbackResponse,
} from 'core_main/sdk/get_categories/types';
import { CategoryState, KBState } from 'widget_main/store/types';

export const createCategoryTree = (
  categories: GetCategoriesCallbackResponse['categories'],
) => {
  return Object.entries(categories).reduce(
    (acc, val) => {
      const key = val[0];
      const value = val[1] as CategoryCallbackResponse;

      const sorted = value.childCategories.reduce((children, val) => {
        if (categories[val]) children.push(acc[val]);
        return children;
      }, [] as CategoryState[]);

      acc[key].sortedChildCategories = sorted;

      return acc;
    },
    { ...categories } as KBState['categories'],
  );
};

import { Fragment, h } from 'preact';
import { useContext } from 'preact/hooks';
import { forwardRef, Ref } from 'preact/compat';
import Unread from 'widget_main/components/Unread';
import ChatIcon from 'widget_main/components/ChatIcon';
import * as keyboardEventHelpers from 'widget_main/globals/keyboardEvents';
import classNames from 'classnames';
import { NavigationContext, ProactiveChatContext } from 'widget_main/store';
import { MESSAGE_THREAD_PATH } from 'widget_main/globals/constants';
import { getChatIconFrameSize } from 'helpers/kustomer_api_iframe';
import styles from './chatRootIcon.scss';
import ProactivePopup from '../ProactivePopup/ProactivePopup';

interface ChatRootIconProps {
  onClick?: () => void;
  isMobile: boolean;
}

const ChatRootIcon = forwardRef(
  ({ onClick, isMobile }: ChatRootIconProps, ref: Ref<HTMLDivElement>) => {
    const { proactiveChat, onCloseProactiveChat } =
      useContext(ProactiveChatContext);
    const navigation = useContext(NavigationContext);

    const handleChatIconClick = () => {
      if (proactiveChat) {
        navigation.updateInitAssistantPayload(proactiveChat.initResponse);
        navigation.updateCurrentConversationId(undefined);
        navigation.updatePage(MESSAGE_THREAD_PATH);
        onCloseProactiveChat();
      }
      if (onClick) onClick();
    };

    const handleKeyPress = (e: KeyboardEvent) => {
      if (keyboardEventHelpers.isEnterKey(e)) {
        e.preventDefault();
        e.stopPropagation();

        handleChatIconClick();
      }
    };

    const renderProactiveChat = () => {
      return proactiveChat ? <ProactivePopup isMobile={isMobile} /> : null;
    };
    const chatIconFrameSize = getChatIconFrameSize();
    return (
      <Fragment>
        {renderProactiveChat()}
        <div
          className={classNames(
            {
              [styles.proactivePopupActive]: proactiveChat,
            },
            styles.chatIconWrapper,
          )}
          style={{
            width: chatIconFrameSize.width,
            height: chatIconFrameSize.height,
          }}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={handleChatIconClick}
            onKeyPress={handleKeyPress}
            className={classNames({
              [styles.pointer]: !!onClick,
            })}
            ref={ref}
            aria-labelledby="rootChatIcon"
          >
            <ChatIcon id="rootChatIcon" isEmbedIcon />
          </div>
          <Unread className={styles.unreadProp} />
        </div>
      </Fragment>
    );
  },
);
export default ChatRootIcon;

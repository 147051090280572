import { h, JSX } from 'preact';
import { memo } from 'preact/compat';
import { Ref } from 'preact/hooks';
import classNames from 'classnames';
import * as keyboardEventHelpers from 'widget_main/globals/keyboardEvents';
import styles from './button.scss';

interface ButtonProps {
  variant: 'primary' | 'secondary' | 'danger' | 'link' | 'outlinePrimary';
  type: 'button' | 'submit' | 'reset';
  ariaLabel?: string | JSX.Element;
  onClick: (e: Event) => unknown;
  className?: string;
  block?: boolean;
  children?: JSX.Element | JSX.Element[] | string;
  disabled?: boolean;
  buttonRef?: Ref<HTMLButtonElement>;
}

const Button = ({
  variant,
  type,
  ariaLabel,
  onClick,
  className,
  children,
  block,
  disabled,
  buttonRef,
  ...props
}: ButtonProps) => {
  const handleKeyPress = (e: KeyboardEvent) => {
    if (keyboardEventHelpers.isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();

      onClick(e);
    }
  };

  return (
    <button
      ref={buttonRef}
      type={type}
      aria-label={ariaLabel}
      class={classNames(
        styles.button,
        { [styles.block]: block },
        `${styles[variant]}`,
        className,
      )}
      onClick={disabled ? undefined : onClick}
      onKeyPress={disabled ? undefined : handleKeyPress}
      aria-disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  variant: 'primary',
  type: 'button',
};

export default memo(Button);

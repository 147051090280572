import { useText } from 'preact-i18n';
import globalMessages from 'widget_main/globals/messages';

type GlobalMessages = typeof globalMessages;
export const useTranslations = <
  M extends Record<string, string>,
  G extends keyof GlobalMessages
>(
  messages: M,
  globalIds?: G[],
): M & Pick<GlobalMessages, G> => {
  const contextInput = Object.entries(messages).reduce((acc, val) => {
    const key = val[0];
    acc[key] = key;
    return acc;
  }, {});

  const globals = globalIds?.reduce((acc, id) => {
    const globalMessage = globalMessages[id];

    if (globalMessage) {
      acc[id] = id;
    }

    return acc;
  }, {} as GlobalMessages);

  const withGlobals = { ...contextInput, ...globals };

  const definitions = useText(withGlobals);

  const withDefaults = Object.entries(definitions).reduce((acc, val) => {
    const key = val[0];
    const value = val[1];

    if (!value) {
      acc[key] = messages[key] || globalMessages[key];
    }

    return acc;
  }, definitions);

  return { ...withDefaults };
};

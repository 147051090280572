import { InitialMessage } from 'core_main/api/assistant/types';
import { MLLChild } from 'core_main/api/messages/types';
import { MessageCallbackResponse } from 'core_main/sdk/send_message/types';
import { OptimisticMessage } from 'widget_main/store/types';
import { ACTIONABLE_TEMPLATE_TYPES } from './constants';

type Message = MessageCallbackResponse | InitialMessage;

export const flattenMLLTree = (tree) => {
  let results = [] as MLLChild[];

  tree.children.forEach((mllChild: MLLChild) => {
    if (mllChild.children) {
      results = results.concat(flattenMLLTree(mllChild));
    } else {
      results.push(mllChild);
    }
  });

  return results;
};

export const getLastMessageWithTemplateAction = (messages?: Message[]) => {
  if (messages && messages.length) {
    const lastMessage = messages?.[messages.length - 1];

    const templateType = lastMessage?.meta?.template?.templateType;

    if (templateType && ACTIONABLE_TEMPLATE_TYPES.includes(templateType)) {
      return lastMessage;
    }
  }
  return undefined;
};

export const formatListTemplateBody = (titleText, detailsText) => {
  return `${titleText} - ${detailsText}`;
};

export const isMatchingReply = (customerMessage, agentMessage) => {
  const messageActions = agentMessage?.meta?.template?.actions;
  const { body } = customerMessage;

  if (messageActions && 'tree' in messageActions) {
    const flattened = flattenMLLTree(messageActions.tree);

    const matchingAction = flattened.find((mllChild) => {
      return mllChild.displayName === body;
    });

    return !!matchingAction;
  }

  const matchingAction = messageActions?.find((action) => {
    const { detailsText, titleText } = action;

    return (
      action.displayText === body ||
      formatListTemplateBody(titleText, detailsText) === body
    );
  });

  return !!matchingAction;
};

export const getLastTemplateActionReply = (
  templateActionMessage: Message,
  conversationMessages?: MessageCallbackResponse[] | null,
) => {
  const lastTemplateReplyMessage = conversationMessages
    ?.slice()
    .reverse()
    .filter((message) => message?.sentBy?.type === 'customer')
    .filter((message) => {
      const rawDate =
        'createdAt' in templateActionMessage
          ? templateActionMessage.createdAt
          : templateActionMessage.importedAt;

      const messageTemplateDate = new Date(rawDate);
      const replyDate = new Date(message.createdAt);

      return replyDate > messageTemplateDate;
    })
    .find((message) => {
      return isMatchingReply(message, templateActionMessage);
    });

  return lastTemplateReplyMessage;
};

export const getLastTemplateActionOptimisticReply = (
  templateActionMessage: Message,
  optimisticMessages?: OptimisticMessage[] | null,
) => {
  const lastTemplateReplyMessage = optimisticMessages?.find(
    (optimisticMessage) => {
      return isMatchingReply(optimisticMessage, templateActionMessage);
    },
  );

  return lastTemplateReplyMessage;
};

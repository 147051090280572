import { createTheme } from 'widget_main/globals/themes';
import { isBrowserIE } from 'polyfills/helpers';
import { InitCallbackResponse } from 'core_main/sdk/init/types';
import { getKustomer } from 'widget_main/globals/helpers';
import { ChatIconPosition, ChatIconSize } from 'widget/widget_api/types';
import { GetConversationsCallbackResponse } from 'core_main/sdk/get_conversations/types';
import { ConversationsState, Page } from 'widget_main/store/types';
import {
  CONVERSATIONS_PATH,
  KB_ARTICLE_PATH,
  KB_DEFLECT_ARTICLE_PATH,
  KB_SEARCH_PATH,
  MESSAGE_THREAD_PATH,
  WIDGET_TYPE_CHAT,
  WIDGET_TYPE_CHAT_KB,
  WIDGET_TYPE_KB,
} from 'widget_main/globals/constants';
import { DEFAULT_CHAT_ICON_SIZE } from './constants';

export const setThemeVariables = (
  settings: InitCallbackResponse['settings'],
) => {
  const theme = createTheme(settings);

  if (isBrowserIE()) {
    return import(
      /* webpackChunkName: "css-vars-ponyfill" */ 'css-vars-ponyfill'
    ).then((module) => {
      module.default({
        variables: theme as unknown as { [key: string]: string },
      });
    });
  }
  Object.entries(theme).forEach((value) => {
    const variable = value[0];
    const style = value[1];
    document.documentElement.style.setProperty(variable, style);
  });
  return Promise.resolve();
};

export const getChatIconSize = (): ChatIconSize => {
  const startParameters = getKustomer()?.startParameters;
  return (
    startParameters?.chatIconSize || {
      width: DEFAULT_CHAT_ICON_SIZE,
      height: DEFAULT_CHAT_ICON_SIZE,
    }
  );
};

export const getChatIconPosition = (): ChatIconPosition => {
  const { startParameters } = getKustomer();
  return startParameters.chatIconPosition;
};

export const mapConversationState = (
  conversations: GetConversationsCallbackResponse['conversations'],
  conversationDataState: ConversationsState['data'],
): ConversationsState['data'] => {
  return conversations
    .map((conversation) => {
      const currentConversation =
        conversationDataState[conversation.conversationId];

      const optimisticMessages = currentConversation?.optimisticMessages || [];
      const messages = currentConversation?.messages || [];
      const satisfaction = currentConversation?.satisfaction;
      const assistantId = currentConversation?.assistantId;
      const hasAllMessages = currentConversation?.hasAllMessages;
      const createdAt =
        conversation.createdAt || currentConversation?.createdAt;

      return {
        ...conversation,
        messages,
        optimisticMessages,
        satisfaction,
        assistantId,
        hasAllMessages,
        createdAt,
      };
    })
    .reduce((acc, val) => {
      acc[val.conversationId] = val;
      return acc;
    }, {} as ConversationsState['data']);
};

/**
 * This is only needed to support testing live chat preview and the _rare_ case of an org
 *  changing their widget type to one that does not support a previous last page opened
 * @param lastOpenedPage
 * @param widgetType
 */
export const lastOpenedPageIsSupportedByWidgetType = (
  lastOpenedPage: Page,
  widgetType: string,
): boolean => {
  switch (lastOpenedPage) {
    case KB_ARTICLE_PATH:
    case KB_SEARCH_PATH:
    case KB_DEFLECT_ARTICLE_PATH:
      return [WIDGET_TYPE_KB, WIDGET_TYPE_CHAT_KB].includes(widgetType);
    case CONVERSATIONS_PATH:
    case MESSAGE_THREAD_PATH:
      return [WIDGET_TYPE_CHAT, WIDGET_TYPE_CHAT_KB].includes(widgetType);
    default:
      return false;
  }
};

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".templateActions__templateActions___-e4EK{padding:10px 15px;display:flex;justify-content:center;flex-wrap:wrap}.templateActions__blockTemplateAnswer___LWcl9{background:#fff}.templateActions__inThread___QDOOi{padding:10px 0}.templateActions__quickReplyButton___tWpPV,.templateActions__mllButton___1G7lr{min-width:80px;padding:8px 15px;margin:5px;display:flex;align-items:center}.templateActions__quickReplyButton___tWpPV{justify-content:center;margin-top:10px}.templateActions__mllButton___1G7lr{justify-content:flex-end}.templateActions__mllButton___1G7lr.templateActions__mllButtonWithChevron___IltVO{padding-right:5px}.templateActions__mllText___zK-RG{flex-grow:1}.templateActions__mllChevron___cjR0X{height:100%}.templateActions__answerFeedbackContainer___7GbQj{padding-left:35px;display:flex;gap:10px}.templateActions__emojiReplyButton___CGaXN{padding:5px 20px;font-size:16px}html[dir=\"rtl\"] .templateActions__mllButton___1G7lr.templateActions__mllButtonWithChevron___IltVO{padding-left:5px;padding-right:8px}html[dir=\"rtl\"] .templateActions__mllButton___1G7lr.templateActions__mllButtonWithChevron___IltVO>svg{transform:rotate(180deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"templateActions": "templateActions__templateActions___-e4EK",
	"blockTemplateAnswer": "templateActions__blockTemplateAnswer___LWcl9",
	"inThread": "templateActions__inThread___QDOOi",
	"quickReplyButton": "templateActions__quickReplyButton___tWpPV",
	"mllButton": "templateActions__mllButton___1G7lr",
	"mllButtonWithChevron": "templateActions__mllButtonWithChevron___IltVO",
	"mllText": "templateActions__mllText___zK-RG",
	"mllChevron": "templateActions__mllChevron___cjR0X",
	"answerFeedbackContainer": "templateActions__answerFeedbackContainer___7GbQj",
	"emojiReplyButton": "templateActions__emojiReplyButton___CGaXN"
};
export default ___CSS_LOADER_EXPORT___;

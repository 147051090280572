import { useEffect, useState, useCallback } from 'preact/hooks';
import debounce from 'lodash.debounce';
import {
  getChatIconFrameSize,
  setIframeSizeChatIcon,
  setIframeSizeCustom,
} from 'helpers/kustomer_api_iframe';
import styles from './proactivePopup.scss';

const debounceTime = 5;
export function useResizeObserver(targetEl, cb) {
  const [observer, setObserver] = useState<ResizeObserver | null>(null);

  useEffect(() => {
    const obs = new ResizeObserver(debounce(cb, debounceTime));
    setObserver(obs);
  }, [cb, setObserver]);

  useEffect(() => {
    if (!observer || !targetEl) return;
    observer.observe(targetEl);
    // eslint-disable-next-line consistent-return
    return () => observer && observer.disconnect();
  }, [observer, targetEl]);
}

export function useResizeIframe(targetEl) {
  const [wrapperHeight, setWrapperHeight] = useState(0);

  const resizeIframeCallback = useCallback((entries) => {
    setWrapperHeight(entries[0].target.clientHeight);
  }, []);
  useResizeObserver(targetEl?.current, resizeIframeCallback);
  useEffect(() => {
    setIframeSizeCustom({
      frameHeight: `${wrapperHeight + getChatIconFrameSize().height}px`,
      frameWidth: styles.proactivePopupIframeWidth,
    });
    return () => {
      setIframeSizeChatIcon();
    };
  }, [wrapperHeight, targetEl]);
}

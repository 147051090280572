import { h, Component } from 'preact';
import produce, { Draft } from 'immer';
import { handleError } from 'globals/errors';
import { ErrorBoundaryComponent } from './ErrorBoundaryComponent';
import { ErrorBoundaryProps, ErrorBoundaryState } from './types';

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props);

    this.state = { errored: false };
  }

  componentDidUpdate(prevProps, prevState) {
    const { errored } = prevState;

    if (errored && prevProps.page !== this.props.page) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        produce((draftState: Draft<ErrorBoundaryState>) => {
          draftState.errored = false;
        }),
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.errored !== nextState.errored) {
      return true;
    }

    if (nextProps.page !== this.props.page) {
      return true;
    }

    return false;
  }

  componentDidCatch(error) {
    this.setState(
      produce((draftState: Draft<ErrorBoundaryState>) => {
        draftState.errored = true;
      }),
      () => {
        handleError(error);
      },
    );
  }

  render(props, state) {
    if (state.errored) {
      return <ErrorBoundaryComponent restartApp={this.props.restartApp} />;
    }

    return props.children;
  }
}

export default ErrorBoundary;

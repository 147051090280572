import { Fragment, h } from 'preact';
import { useContext, useState } from 'preact/hooks';
import CloseIcon from 'widget_main/globals/assets/close.svg';
import { SettingsContext } from 'widget_main/store';
import { useTranslations } from 'i18n/hooks';
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { isMobileViewport } from 'widget_main/globals/dom';
import messages from './messages';
import styles from './footerConsent.scss';

export const FooterConsent = () => {
  const [showToolTip, setShowTooltip] = useState<boolean>(false);
  const [showCloseButtonInToolTip, setShowCloseButtonInToolTip] =
    useState<boolean>(false);
  const consentButtonRef = useRef<HTMLButtonElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const settings = useContext(SettingsContext);
  const translations = useTranslations(messages);

  // for a11y purposes, make sure the closebutton is available before we focus on it when opening the tooltip
  useEffect(() => {
    if (showCloseButtonInToolTip && closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, [showCloseButtonInToolTip, consentButtonRef]);

  if (!settings.botConsent?.enabled) {
    return null;
  }

  let consentMessage = translations.footerConsent;
  if (settings.botConsent.value !== 'default') {
    consentMessage = settings.botConsent.value;
  }

  const handleMouseOver = () => {
    if (isMobileViewport()) {
      return;
    }
    setShowCloseButtonInToolTip(false);
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    if (isMobileViewport()) {
      return;
    }
    setShowCloseButtonInToolTip(false);
    setShowTooltip(false);
  };

  const handleClick = () => {
    if (showToolTip) {
      setShowTooltip(false);
      consentButtonRef.current?.focus();
    } else {
      setShowCloseButtonInToolTip(true);
      setShowTooltip(true);
    }
  };

  const renderToolTip = () => {
    if (!showToolTip) {
      return null;
    }
    return (
      <div className={styles.footerTooltipContainer}>
        <button
          className={classNames(styles.footerTooltipContent, {
            [styles.footerTooltipContentClicked]: showCloseButtonInToolTip,
          })}
          type="button"
          onClick={handleClick}
          ref={closeButtonRef}
        >
          <div className={styles.footerTooltip}>{consentMessage}</div>
          {showCloseButtonInToolTip && (
            <div className={styles.closeButton}>
              <CloseIcon width={18} height={18} />
            </div>
          )}
        </button>
      </div>
    );
  };

  return (
    <Fragment>
      <span className={styles.separator}>•</span>
      <button
        ref={consentButtonRef}
        className={styles.consent}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onFocus={() => undefined}
        onClick={handleClick}
      >
        {consentMessage}
      </button>
      {renderToolTip()}
    </Fragment>
  );
};

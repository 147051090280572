import { h, RefObject } from 'preact';
import { forwardRef } from 'preact/compat';

import classNames from 'classnames';
import * as keyboardEventHelpers from 'widget_main/globals/keyboardEvents';
import { useTouchMove } from 'widget_main/globals/hooks';
import styles from './textArea.scss';

interface TextAreaProps {
  type?: 'text';
  ariaLabel: string;
  onChange: (e: Event) => unknown;
  onClick?: (e: Event) => unknown;
  autoFocus?: boolean;
  maxLength?: number;
  id?: string;
  rows?: number;
  value?: string;
  disabled?: boolean;
  onEnter?: (e: KeyboardEvent) => unknown;
  className?: string;
  placeholder?: string;
}

const TextArea = forwardRef(
  (
    {
      type = 'text',
      ariaLabel,
      onChange,
      onClick,
      autoFocus = true,
      onEnter,
      id,
      className,
      placeholder,
      value,
      disabled,
      maxLength = 500,
      rows,
    }: TextAreaProps,
    ref?: RefObject<HTMLTextAreaElement>,
  ) => {
    useTouchMove(ref?.current);

    const handleKeyPress = (e: KeyboardEvent) => {
      if (keyboardEventHelpers.isEnterKey(e)) {
        e.preventDefault();
        e.stopPropagation();

        if (onEnter) {
          onEnter(e);
        }
      }
    };

    return (
      <textarea
        id={id}
        ref={ref}
        type={type}
        tabIndex={0}
        aria-label={ariaLabel}
        label={ariaLabel}
        class={classNames(styles.textArea, className)}
        placeholder={placeholder}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        rows={rows}
        onChange={onChange}
        onClick={onClick}
        onKeyPress={handleKeyPress}
        value={value}
        disabled={disabled}
        maxLength={maxLength}
      />
    );
  },
);

TextArea.displayName = 'TextArea';

export default TextArea;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer__footer___MMHBz{border-top:.5px solid #e9eff5;display:flex;justify-content:center;align-items:center;height:25px;flex-shrink:0;font-size:10px;font-weight:500;color:#717070}.footer__footerNavigation___dgKul{display:flex;height:40px;justify-content:space-around;align-items:center;cursor:pointer}.footer__icon___bbLQ5{width:18px;height:18px}.footer__itemContainer___i7b3T{display:flex;width:50%;align-items:center;justify-content:center;color:#717070;border-top:.5px solid #e9eff5;height:100%;gap:10px}.footer__itemContainer___i7b3T:hover{color:var(--primary-button-background-color)}.footer__highlighted___cJheK{border-top:1px solid var(--primary-button-background-color);color:var(--primary-button-background-color);background-color:#fff}.footer__chatContainer___j-p2I{position:relative;display:flex;gap:5px}.footer__unread___jaE1P{height:16px;width:16px;min-width:10px;background-color:red;text-align:center}.footer__footerContainer___\\+Vavx{margin-top:auto}html[dir=\"rtl\"] .footer__icon___bbLQ5{margin:0 0 0 10px;transform:scaleX(-1)}html[dir=\"rtl\"] .footer__chatContainer___j-p2I,html[dir=\"rtl\"] .footer__itemContainer___i7b3T{flex-direction:row-reverse}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"footer": "footer__footer___MMHBz",
	"footerNavigation": "footer__footerNavigation___dgKul",
	"icon": "footer__icon___bbLQ5",
	"itemContainer": "footer__itemContainer___i7b3T",
	"highlighted": "footer__highlighted___cJheK",
	"chatContainer": "footer__chatContainer___j-p2I",
	"unread": "footer__unread___jaE1P",
	"footerContainer": "footer__footerContainer___+Vavx"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".proactiveChatMessage__proactiveChatMessage___md3FK{opacity:0;display:flex;flex-direction:column;align-items:flex-end;margin-right:2px;cursor:pointer}.proactiveChatMessage__proactiveChatMessage___md3FK .proactiveChatMessage__chatBubble___PfYe4{box-shadow:0 1px 4px rgba(0,0,0,.15),0 2px 4px rgba(0,0,0,.08);max-width:262px;margin-left:0;border-bottom-left-radius:12px;border-bottom-right-radius:0;margin-bottom:5px}.proactiveChatMessage__proactiveChatMessage___md3FK.proactiveChatMessage__fadeIn___8VB1H{opacity:1;transition:opacity 500ms}@media all and (-ms-high-contrast: none){.proactiveChatMessage__proactiveChatMessage___md3FK .proactiveChatMessage__chatBubble___PfYe4{width:100%}}body.align-chat-icon-left .proactiveChatMessage__proactiveChatMessage___md3FK{align-items:flex-start}body.align-chat-icon-left .proactiveChatMessage__chatBubble___PfYe4{border-bottom-left-radius:0;border-bottom-right-radius:12px}html[dir=\"rtl\"] body.align-chat-icon-left .proactiveChatMessage__proactiveChatMessage___md3FK{align-items:flex-end}html[dir=\"rtl\"] body.align-chat-icon-right .proactiveChatMessage__proactiveChatMessage___md3FK{align-items:flex-start}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"proactiveChatMessage": "proactiveChatMessage__proactiveChatMessage___md3FK",
	"chatBubble": "proactiveChatMessage__chatBubble___PfYe4",
	"fadeIn": "proactiveChatMessage__fadeIn___8VB1H"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kbSearchList__list___w9Sz6{min-height:100px;flex-grow:1;overflow-y:auto}.kbSearchList__listLoading___Sxq9M{display:flex;align-items:center;justify-content:center;background-color:#fff}.kbSearchList__empty___26Swx{display:flex;flex-direction:column;align-items:center;text-align:center;padding:45px 20px 10px}.kbSearchList__articlesEmpty___ZzKSo{min-height:129px}.kbSearchList__emptyMessage___6F1p9{font-size:12px;margin-top:10px}.kbSearchList__maxResultsLabel___A5c5N{text-align:center;font-size:12px;padding:20px}.kbSearchList__headerContainer___2UbiF{background-color:#fff;padding:20px;border-bottom:1px solid #e9eff5}.kbSearchList__headerTitle___0hhbt{font-size:15px;font-weight:500}.kbSearchList__headerDescription___nwu51{color:#717070;margin-top:10px}.kbSearchList__loader___iANJO{margin:initial}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"list": "kbSearchList__list___w9Sz6",
	"listLoading": "kbSearchList__listLoading___Sxq9M",
	"empty": "kbSearchList__empty___26Swx",
	"articlesEmpty": "kbSearchList__articlesEmpty___ZzKSo",
	"emptyMessage": "kbSearchList__emptyMessage___6F1p9",
	"maxResultsLabel": "kbSearchList__maxResultsLabel___A5c5N",
	"headerContainer": "kbSearchList__headerContainer___2UbiF",
	"headerTitle": "kbSearchList__headerTitle___0hhbt",
	"headerDescription": "kbSearchList__headerDescription___nwu51",
	"loader": "kbSearchList__loader___iANJO"
};
export default ___CSS_LOADER_EXPORT___;

import { Fragment, h } from 'preact';
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'preact/hooks';
import classNames from 'classnames';
import {
  getKustomerCore,
  setKustomerLocalStorage,
} from 'widget_main/globals/helpers';
import { handleError } from 'globals/errors';

import {
  KBContext,
  NavigationContext,
  SettingsContext,
} from 'widget_main/store';
import { CategoryCallbackResponse } from 'core_main/sdk/get_categories/types';
import Loader from 'widget_main/components/ui/Loader';
import { KB_ARTICLE_PATH, KB_SEARCH_PATH } from 'widget_main/globals/constants';
import ArticlesEmpty from 'widget_main/globals/assets/articlesEmpty.svg';
import { ArticleCallbackResponse } from 'core_main/sdk/get_articles/types';

import { useTranslations } from 'i18n/hooks';
import { getMappedLanguage } from 'i18n/helpers';
import Text from 'widget_main/components/Text';
import KBSearchListItem from './KBSearchListItem';

import { createCategoryTree } from './helpers';
import styles from './kbSearchList.scss';
import { ARTICLE_TYPE, MAX_ARTICLE_SEARCH_SIZE } from './constants';
import { KBSearchListItemType } from './types';

import messages from './messages';

const kustomerCore = getKustomerCore();

const KBSearchList = () => {
  const kb = useContext(KBContext);
  const navigation = useContext(NavigationContext);
  const chatSettings = useContext(SettingsContext);
  const [loading, updateLoading] = useState(false);
  const [langSupported, updateLangSupported] = useState(true);
  const translations = useTranslations(messages);
  const listContainer = useRef<HTMLDivElement>(null);

  const lang = getMappedLanguage();

  const { categories, rootCategory, currentCategoryId, articleSearchResults } =
    kb;

  const { knowledgeBaseId } = chatSettings;

  useEffect(() => {
    setKustomerLocalStorage('lastOpenPage', KB_SEARCH_PATH);
  }, []);

  useEffect(() => {
    if (!Object.keys(categories).length) {
      updateLoading(true);
      if (knowledgeBaseId) {
        kustomerCore.getFeaturedArticles(
          { knowledgeBaseId, lang },
          (res, err) => {
            if (err) {
              handleError(err, { message: 'Error fetching featured articles' });
              return;
            }
            if (res && res.articles.length > 0) {
              kb.updateFeaturedArticles(res.articles);
            }
          },
        );
      }
      kustomerCore.getCategories({ lang, knowledgeBaseId }, (res, err) => {
        const langNotFound =
          err?.title === 'Not Found' && err?.source === 'lang';

        if (langNotFound) {
          updateLangSupported(false);
          updateLoading(false);
          return;
        }

        if (res) {
          const tree = createCategoryTree(res.categories);
          kb.updateCategories(tree, res.rootCategory);
          kb.updateCurrentCategoryId(res.rootCategory.categoryId);
          updateLoading(false);
        }
      });
    }
  }, [categories, kb, lang, knowledgeBaseId]);

  useLayoutEffect(() => {
    const currentList = listContainer?.current;
    if (currentList && currentList?.scrollTop) {
      currentList.scrollTop = 0;
    }
  }, [currentCategoryId]);

  const renderEmptyState = () => {
    return (
      <div className={styles.empty}>
        <img
          alt={translations.articlesEmptyAltText}
          className={styles.articlesEmpty}
          src={ArticlesEmpty}
        />
        <p className={styles.emptyMessage}>
          {translations.kbSearchListEmptyState}
        </p>
      </div>
    );
  };

  if (!langSupported) {
    return <div className={classNames(styles.list)}>{renderEmptyState()}</div>;
  }

  if (!currentCategoryId || loading || kb.articleSearchFetching) {
    return (
      <div className={classNames(styles.list, styles.listLoading)}>
        <Loader className={styles.loader} />
      </div>
    );
  }

  const category = categories?.[currentCategoryId];
  const sortedChildCategories = category?.sortedChildCategories;
  const articles = category?.articles;

  let tree: (ArticleCallbackResponse | CategoryCallbackResponse)[] =
    articleSearchResults || [
      ...(articles || []),
      ...(sortedChildCategories || []),
    ];

  const overMaxResults =
    articleSearchResults &&
    articleSearchResults.length > MAX_ARTICLE_SEARCH_SIZE;
  // long term, it probably makes sense that the API should be sending back a flag
  //  to us that more than MAX_ARTICLE_SEARCH_SIZE number of articles were found,
  //  but for now we are doing the parsing
  // Also, we (currently) only want this to affect search results, so only check on the
  //  `articleSearchResults` object before we trim & mark as over max
  if (overMaxResults) tree = tree.slice(0, MAX_ARTICLE_SEARCH_SIZE);

  const handleListItemClick = (type: KBSearchListItemType, id: string) => {
    if (type === ARTICLE_TYPE) {
      kb.updateCurrentArticleId(id);
      navigation.updatePage(KB_ARTICLE_PATH);
      return;
    }

    const categoryArticles = categories[id].articles;

    if (categories && !categoryArticles) {
      kustomerCore.getArticles(
        { categoryId: id, lang, knowledgeBaseId },
        (res, err) => {
          const updatedArticles = err ? [] : res.articles;

          kb.updateArticles(updatedArticles);

          kb.updateCategories(
            {
              ...categories,
              [id]: { ...categories[id], articles: updatedArticles },
            },
            rootCategory,
          );

          kb.updateCurrentCategoryId(id);
        },
      );

      return;
    }

    kb.updateCurrentCategoryId(id);
  };

  const renderHeaderContent = (title?: string, description?: string) => {
    return (
      <div className={styles.headerContainer}>
        <p className={styles.headerTitle}>{title}</p>
        {description && (
          <p className={styles.headerDescription}>{description}</p>
        )}
      </div>
    );
  };

  const renderHeader = () => {
    if (currentCategoryId !== rootCategory?.categoryId) {
      if (category) {
        return renderHeaderContent(category.title, category.description);
      }
    } else if (kb.featuredArticles?.length && !articleSearchResults) {
      return renderHeaderContent(translations.kbCategories);
    }
    return null;
  };

  const renderOverMaxLabel = () => {
    return (
      <div className={styles.maxResultsLabel}>
        <Text
          fields={{ number: MAX_ARTICLE_SEARCH_SIZE }}
          id="kbSearchListMaxArticles"
          defaultMessage={translations.kbSearchListMaxArticles}
        />
      </div>
    );
  };

  const renderFeaturedArticles = () => {
    if (
      currentCategoryId === rootCategory?.categoryId &&
      kb.featuredArticles?.length &&
      !articleSearchResults
    ) {
      return (
        <Fragment>
          {renderHeaderContent(translations.kbFeaturedArticles)}
          {kb.featuredArticles?.map((article) => {
            return (
              <KBSearchListItem
                listItem={article}
                key={article.title}
                onClick={handleListItemClick}
              />
            );
          })}
        </Fragment>
      );
    }

    return null;
  };

  return (
    <div
      ref={listContainer}
      id="kbSearchList"
      className={classNames(styles.list)}
    >
      {renderFeaturedArticles()}
      {renderHeader()}
      {tree?.map((item, index) => {
        const isLast = !tree[index + 1];
        return (
          <KBSearchListItem
            listItem={item}
            key={item.title}
            onClick={handleListItemClick}
            isLast={isLast}
          />
        );
      })}
      {tree?.length === 0 && renderEmptyState()}
      {overMaxResults && renderOverMaxLabel()}
    </div>
  );
};

export default KBSearchList;

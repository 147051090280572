// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmojiPicker__tooltip___z2dby{position:absolute;left:50%;bottom:55px;transform:translate(-50%);height:270px;box-shadow:0 2px 10px 0 #bdbdbd;border-radius:4px;background:#fff;padding:4px 8px;z-index:1}.EmojiPicker__emojiIconButton___eJCry{background-color:#fff;cursor:pointer;opacity:.8;padding:0 0 0 2px}emoji-picker{--background: $backgroundPrimaryColor;--emoji-size: 1.5rem;--num-columns: 6;width:100%;height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"tooltip": "EmojiPicker__tooltip___z2dby",
	"emojiIconButton": "EmojiPicker__emojiIconButton___eJCry"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".scrollToCurrent__scrollToCurrentContainer___AmN9i{position:sticky;bottom:0;display:flex;justify-content:center;margin-top:-34px}.scrollToCurrent__scrollToCurrentContainer___AmN9i.scrollToCurrent__scrollToContainerFadeIn___UYuUW{opacity:1;transition:opacity 100ms ease-out}.scrollToCurrent__scrollToCurrentContainer___AmN9i.scrollToCurrent__scrollToContainerFadeOut___bEAeV{opacity:0;transition:opacity 100ms ease-out;pointer-events:none}.scrollToCurrent__scrollToCurrent___qCF\\+W{background-color:#fff;color:#697182;border-radius:18px;align-items:center;min-width:34px;min-height:34px;justify-content:center;display:flex;box-shadow:0 2px 6px 0 rgba(201,201,201,.5);padding:0}.scrollToCurrent__scrollToCurrent___qCF\\+W.scrollToCurrent__hasNewMessages___\\+QaTc{padding:0 10px 0 6px}.scrollToCurrent__chevronDown___SYB4Z{transform:rotate(90deg)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"scrollToCurrentContainer": "scrollToCurrent__scrollToCurrentContainer___AmN9i",
	"scrollToContainerFadeIn": "scrollToCurrent__scrollToContainerFadeIn___UYuUW",
	"scrollToContainerFadeOut": "scrollToCurrent__scrollToContainerFadeOut___bEAeV",
	"scrollToCurrent": "scrollToCurrent__scrollToCurrent___qCF+W",
	"hasNewMessages": "scrollToCurrent__hasNewMessages___+QaTc",
	"chevronDown": "scrollToCurrent__chevronDown___SYB4Z"
};
export default ___CSS_LOADER_EXPORT___;

import {
  SatisfactionCallbackResponse,
  SatisfactionFormAnswer,
} from 'core_main/api/satisfaction/types';

export const getInitialAnswersFromSatisfaction = (
  satisfaction: SatisfactionCallbackResponse,
) => {
  if (satisfaction.response?.answers?.length) {
    return satisfaction.response?.answers.map((answer) => ({
      ...answer,
      selections: answer.selections?.slice(),
      selectionsRaw: answer.selectionsRaw?.slice(),
    }));
  }

  const questions = satisfaction.form?.questions || [];

  return questions.map((question) => {
    return {
      id: question.id,
      prompt: question.prompt,
      required: question.isRequired,
      displayType: question.displayType,
      answer: '',
      answerRaw: '',
      selections: [] as string[],
      selectionsRaw: [] as string[],
    };
  }) as SatisfactionFormAnswer[];
};

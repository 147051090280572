import { Page } from 'widget_main/store/types';
import { isMobileViewport } from 'widget_main/globals/dom';
import { EXPAND_NAV_ITEMS } from 'widget_main/app/constants';
import {
  setIframeSizeChatIcon,
  setIframeSizeDefaultWidget,
  setIframeSizeFullScreen,
  setIframeSizeHidden,
  setIframeSizeWithExpandedUi,
} from './index';

interface ResizeWidgetHelperProps {
  page?: Page;
  isChatHidden: boolean;
  showWidget: boolean;
  hideChatIcon: boolean;
}

export const resizeWidgetHelper = ({
  page,
  isChatHidden,
  showWidget,
  hideChatIcon,
}: ResizeWidgetHelperProps) => {
  if (isChatHidden) {
    setIframeSizeHidden();
    return;
  }
  if (showWidget) {
    if (isMobileViewport()) {
      setIframeSizeFullScreen();
    } else if (page && EXPAND_NAV_ITEMS.includes(page)) {
      setIframeSizeWithExpandedUi();
    } else {
      setIframeSizeDefaultWidget();
    }
  } else {
    setIframeSizeChatIcon(hideChatIcon);
  }
};

export default {
  emptyTitle: 'No conversations yet',
  emptySubtitle: 'Have a question? Start a new conversation to get help.',
  offHoursAltText: 'outside business hours',
  offHoursEmptyTitle: 'Please leave a message',
  offHoursEmptySubtitle: 'We will get back to you once we are online!',
  offlineAltText: 'Offline',
  conversationsEmptyAltText: 'Conversations empty',
  ended: 'Ended',
  previewAttachmentReceived: 'You received an attachment',
  previewAttachmentSent: 'You sent an attachment',
};

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unread__unread___k7MRf{border-radius:50%;font-size:10px;display:flex;align-items:center;justify-content:center;color:var(--primary-button-font-color);padding:3px;min-width:18px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"unread": "unread__unread___k7MRf"
};
export default ___CSS_LOADER_EXPORT___;

import { h } from 'preact';
import classNames from 'classnames';
import { useContext } from 'preact/hooks';
import { MESSAGE_THREAD_PATH, OFFLINE } from 'widget_main/globals/constants';
import {
  NavigationContext,
  ChatAvailabilityContext,
  ConnectionContext,
} from 'widget_main/store';
import Button from 'widget_main/components/ui/Button';
import { useTranslations } from 'i18n/hooks';
import { getActiveAssistant } from 'widget_main/app/helpers/assistant';
import { getKustomerCore } from 'widget_main/globals/helpers';
import styles from './newConversationButton.scss';

import messages from './messages';

const kustomerCore = getKustomerCore();

interface NewConversationButtonProps {
  className?: string;
  navigateToConversationId?: string;
}

const NewConversationButton = ({
  className,
  navigateToConversationId,
}: NewConversationButtonProps) => {
  const chatAvailability = useContext(ChatAvailabilityContext);
  const navigation = useContext(NavigationContext);
  const connection = useContext(ConnectionContext);

  const translations = useTranslations(messages);

  const handleClickCreate = () => {
    kustomerCore.kustomerEventCount({
      name: 'new_conversation_button.new_conversation.click',
    });
    getActiveAssistant()
      .then((response) => {
        if (response) {
          navigation.updateInitAssistantPayload(response);
        }
        navigation.updateCurrentConversationId(undefined);
        navigation.updatePage(MESSAGE_THREAD_PATH);
      })
      .catch(() => {
        navigation.updateCurrentConversationId(undefined);
        navigation.updatePage(MESSAGE_THREAD_PATH);
      });
  };

  const handleNavigateToConversation = () => {
    kustomerCore.kustomerEventCount({
      name: 'new_conversation_button.navigate_to_conversation.click',
    });
    navigation.updateCurrentConversationId(navigateToConversationId);
  };

  const isOnline = chatAvailability === 'online';
  let message = isOnline
    ? translations.newConversation
    : translations.leaveAMessage;

  if (navigateToConversationId) {
    message = translations.viewNewConversation;
  }

  return (
    <Button
      className={classNames(styles.newConversationButton, className)}
      ariaLabel={message}
      onClick={
        navigateToConversationId
          ? handleNavigateToConversation
          : handleClickCreate
      }
      disabled={connection === OFFLINE}
      data-kt="NewConversationButton"
    >
      {message}
    </Button>
  );
};

export default NewConversationButton;

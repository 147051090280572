import { MLLChild } from 'core_main/api/messages/types';

export const buildPath = (
  branch: MLLChild,
  currentBranch: MLLChild | null,
  previousBranches: MLLChild[],
) => {
  const prevIds = previousBranches.map((prevBranch) => {
    return prevBranch.id;
  });

  let fullPath;

  if (currentBranch?.id) {
    fullPath = [...prevIds, currentBranch.id, branch.id];
  } else {
    fullPath = [...prevIds, branch.id];
  }

  const mllPath = fullPath.join('.');

  return mllPath;
};

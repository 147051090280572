// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal__container___YKj-W{position:absolute;top:0;width:100%;height:100%;background-color:rgba(0,0,0,.1);display:flex;flex-direction:column;justify-content:flex-end}.modal__modal___tYBpK{background-color:#fff;border-top-left-radius:12px;border-top-right-radius:12px;position:relative}.modal__body___XQSiE{padding:20px;width:100%}.modal__closeButton___ZLht4{position:absolute;top:10px;right:10px;z-index:100;color:#717070}html[dir=\"rtl\"] .modal__closeButton___ZLht4{right:initial;left:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"container": "modal__container___YKj-W",
	"modal": "modal__modal___tYBpK",
	"body": "modal__body___XQSiE",
	"closeButton": "modal__closeButton___ZLht4"
};
export default ___CSS_LOADER_EXPORT___;

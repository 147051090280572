// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px"
};
export default ___CSS_LOADER_EXPORT___;

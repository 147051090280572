import { h } from 'preact';
import { useContext } from 'preact/hooks';
import classNames from 'classnames';
import { getKustomerCore } from 'widget_main/globals/helpers';
import ChatIcon from 'widget_main/components/ChatIcon';
import Text from 'widget_main/components/Text';
import MagnifyIcon from 'widget_main/globals/assets/magnify.svg';
import CloseIcon from 'widget_main/globals/assets/close.svg';
import {
  KBContext,
  NavigationContext,
  SettingsContext,
} from 'widget_main/store';
import { isEnterKey } from 'widget_main/globals/keyboardEvents';
import {
  KB_ARTICLE_PATH,
  KB_DEFLECT_ARTICLE_PATH,
} from 'widget_main/globals/constants';
import { useTranslations } from 'i18n/hooks';
import { getMappedLanguage } from 'i18n/helpers';
import styles from './kbHeader.scss';

import messages from './messages';

const kustomerCore = getKustomerCore();

const KBHeader = () => {
  const kb = useContext(KBContext);
  const navigation = useContext(NavigationContext);
  const chatSettings = useContext(SettingsContext);
  const translations = useTranslations(messages, ['answers']);

  const lang = getMappedLanguage();

  const { currentCategoryId, articleSearchResults } = kb;

  const isArticleView =
    navigation.page === KB_ARTICLE_PATH ||
    navigation.page === KB_DEFLECT_ARTICLE_PATH;

  const resultsCount = articleSearchResults?.length;

  const handleUpdateSearchInput = (e) => {
    kb.updateArticleSearchInput(e.target.value);
  };

  const handleClearSearchInput = () => {
    kb.updateArticleSearchInput('');
    kb.updateArticleSearchResults(undefined);
  };

  const handleClearKeyPress = (e: KeyboardEvent) => {
    if (isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();

      handleClearSearchInput();
    }
  };

  const runSearch = () => {
    if (!kb.articleSearchInput) {
      return handleClearSearchInput();
    }

    kb.updateArticleSearchFetching(true);

    kustomerCore.kustomerEventCount({
      name: 'kb_header.search_bar_submit.submit',
    });

    return kustomerCore.searchArticles(
      { term: kb.articleSearchInput, lang },
      (res, error) => {
        if (!error) {
          kb.updateArticleSearchResults(res.articles);
          kb.updateArticles(res.articles);
        }
        kb.updateArticleSearchFetching(false);
      },
    );
  };

  const handleSearchKeyPress = (e: KeyboardEvent) => {
    if (isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();

      runSearch();
    }
  };

  const handleInputKeyPress = (e: KeyboardEvent) => {
    if (isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();
      runSearch();
    }
  };

  const renderClearSearchButton = () => {
    if (!kb.articleSearchInput) {
      return null;
    }
    return (
      <div
        role="button"
        tabIndex={0}
        onClick={handleClearSearchInput}
        onKeyPress={handleClearKeyPress}
        className={styles.clearIcon}
        aria-label={translations.articleSearchClearButton}
      >
        <CloseIcon />
      </div>
    );
  };

  if (currentCategoryId === kb.rootCategory?.categoryId && !isArticleView) {
    return (
      <div class={styles.kbHeader}>
        <div class={styles.content}>
          <ChatIcon
            className={styles.chatIconWrapper}
            size={{ width: 50, height: 50 }}
          />
          <h4 className={styles.header}>{chatSettings?.teamName}</h4>
          <div className={styles.inputContainer}>
            <input
              type="text"
              name={translations.articleSearchInputName}
              value={kb.articleSearchInput}
              className={styles.articleSearchInput}
              placeholder={translations.articleSearchInputPlaceholder}
              label={translations.articleSearchInputPlaceholder}
              onChange={handleUpdateSearchInput}
              onKeyPress={handleInputKeyPress}
              autoComplete="off"
              data-kt="kbSearchInput"
              aria-label={translations.articleSearchInputPlaceholder}
            />
            {renderClearSearchButton()}
            <div
              role="button"
              tabIndex={0}
              className={classNames(styles.searchIcon, {
                [styles.highlighted]: kb.articleSearchInput?.length,
              })}
              onKeyPress={handleSearchKeyPress}
              onClick={kb.articleSearchInput?.length ? runSearch : undefined}
              aria-label={translations.articleSearchButton}
            >
              <MagnifyIcon />
            </div>
          </div>
          <p className={styles.resultsCount}>
            {resultsCount && resultsCount >= 0 ? (
              <Text
                fields={{ resultsCount }}
                id="kbHeaderResults"
                defaultMessage={translations.kbHeaderResults}
              />
            ) : null}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div class={styles.currentView}>
      <div class={styles.content}>
        <p className={styles.header}>{translations.answers}</p>
      </div>
    </div>
  );
};

export default KBHeader;

import { h } from 'preact';
import classNames from 'classnames';

import FolderIcon from 'widget_main/globals/assets/folder.svg';
import FileDocumentOutlineIcon from 'widget_main/globals/assets/fileDocumentOutline.svg';
import ChevronRightIcon from 'widget_main/globals/assets/chevronRight.svg';
import { isEnterKey } from 'widget_main/globals/keyboardEvents';

import { CategoryCallbackResponse } from 'core_main/sdk/get_categories/types';
import { ArticleCallbackResponse } from 'core_main/sdk/get_articles/types';
import { sanitizeHtml } from 'widget_main/vendors/hooks';
import styles from './kbSearchListItem.scss';
import { KBSearchListItemType } from '../types';

interface KBSearchListItemProps {
  listItem: CategoryCallbackResponse | ArticleCallbackResponse;
  onClick: (type: KBSearchListItemType, itemId: string) => void;
  isLast?: boolean;
}

export const isCategory = (
  item?: CategoryCallbackResponse | ArticleCallbackResponse,
): item is CategoryCallbackResponse => {
  return 'categoryId' in (item as CategoryCallbackResponse);
};

const KBSearchListItem = ({
  listItem,
  onClick,
  isLast,
}: KBSearchListItemProps) => {
  const isListItemCategory = isCategory(listItem);
  const type = isListItemCategory ? 'category' : 'article';

  let id;

  if (isCategory(listItem)) {
    id = listItem.categoryId;
  } else {
    id = listItem.articleId;
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    if (isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();

      onClick(type, id);
    }
  };

  const handleClick = () => {
    onClick(type, id);
  };

  const renderIcon = () => {
    if (isListItemCategory) return <FolderIcon className={styles.itemIcon} />;

    return <FileDocumentOutlineIcon className={styles.itemIcon} />;
  };

  const showDescription = Boolean(
    listItem.description && listItem.description.length > 0,
  );

  return (
    <div
      className={classNames(styles.itemContainer, {
        [styles.isLast]: isLast,
      })}
    >
      <div
        className={classNames(styles.container, {
          [styles.isLast]: isLast,
        })}
        onKeyPress={handleKeyPress}
        tabIndex={0}
        role="button"
        aria-label={listItem.title}
        onClick={handleClick}
      >
        <div className={styles.details}>
          {renderIcon()}
          <div className={styles.information}>
            <div className={styles.title} data-kt="kbSearchItemTitle">
              {listItem.title}
            </div>
            {showDescription && (
              <div
                className={styles.description}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(listItem.description, {
                    ALLOWED_TAGS: ['#text'],
                  }),
                }}
              />
            )}
          </div>
        </div>
        <ChevronRightIcon className={styles.chevronIcon} />
      </div>
    </div>
  );
};

export default KBSearchListItem;

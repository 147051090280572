import { h, Fragment } from 'preact';
import { useContext } from 'preact/hooks';
import classNames from 'classnames';
import { useTranslations } from 'i18n/hooks';
import FileDocument from 'widget_main/globals/assets/fileDocument.svg';
import ChevronRight from 'widget_main/globals/assets/chevronRight.svg';
import * as keyboardEventHelpers from 'widget_main/globals/keyboardEvents';
import { KB_DEFLECT_ARTICLE_PATH } from 'widget_main/globals/constants';
import {
  NavigationContext,
  KBContext,
  MessagesContext,
  SettingsContext,
} from 'widget_main/store';
import { ArticleCallbackResponse } from 'core_main/sdk/get_articles/types';
import {
  getKustomerCore,
  getMessageAgentInformation,
  truncate,
} from 'widget_main/globals/helpers';
import Markdown from 'widget_main/components/Markdown/Markdown';
import { DeflectionArticle } from 'core_main/sdk/send_message/types';
import { sendKbDeflectionClick } from 'widget_main/app/helpers/assistant';
import { Message } from '../types';
import ChatBubble from '../ChatBubble';

import defaultMessages from './messages';
import styles from './templateMessage.scss';

const kustomerCore = getKustomerCore();

interface TemplateMessageProps {
  showAvatar?: boolean;
  showBotIdentifier?: boolean;
  showAIIdentifier?: boolean;
  showTimestamp?: boolean;
  message?: Message;
  chatBubbleInnerClassName?: string;
  truncateLength?: number;
}

const TemplateMessage = ({
  message,
  showAvatar,
  showBotIdentifier,
  showAIIdentifier,
  showTimestamp,
  chatBubbleInnerClassName,
  truncateLength,
}: TemplateMessageProps) => {
  const navigation = useContext(NavigationContext);
  const messages = useContext(MessagesContext);
  const settings = useContext(SettingsContext);
  const kb = useContext(KBContext);

  const translations = useTranslations(defaultMessages);

  const navigateToArticleView = (articleId: string) => {
    messages.markArticleVisited(message?.messageId, articleId);
    kb.updateCurrentArticleId(articleId);
    navigation.updatePage(KB_DEFLECT_ARTICLE_PATH);
  };

  const handleClickArticle = (article: ArticleCallbackResponse) => {
    const { articleId, knowledgeBaseId } = article;

    const deflectionArticles =
      message?.meta?.articles?.map((a) => {
        const deflectionObject: DeflectionArticle = {
          id: a.articleId,
          version: a.version,
          visited: a.articleId === articleId ? 1 : 0,
          lang: a.lang,
          title: a.title,
          url: a.kbUrl || '',
        };
        return deflectionObject;
      }) || [];

    if (deflectionArticles.length === 0) return;

    sendKbDeflectionClick(message?.conversationId || '', deflectionArticles);

    if (kb.articles?.[articleId]) {
      navigateToArticleView(articleId);
      return;
    }

    kustomerCore.getArticleById(
      {
        articleId,
        // if we're fetching an article for the first time on click, fetch the article with the ARTICLE'S
        //  language, not the user's language, which is important if the user is using chat in another
        //  language but the org's KB doesn't support that language
        lang: article.lang,
        knowledgeBaseId,
      },
      (response) => {
        const articleToAppend = response;

        if (articleToAppend) {
          // when a deflection article does not live on the default knowledgeBase domain (due to CA setup searching a different brand),
          //    we need to make sure the kbUrl is set after the article is fetched (this value is not returned from the API when looking
          //    up an article)
          //    Jira: https://kustomer.atlassian.net/browse/KDEV-54978
          if (
            article.kbUrl &&
            article.kbUrl !== kb.config?.knowledgeBaseDomain
          ) {
            articleToAppend.kbUrl = article.kbUrl;
          }

          kb.updateArticles([articleToAppend]);
          navigateToArticleView(articleId);
        }
      },
    );
  };

  const handleKeyPress = (
    e: KeyboardEvent,
    article: ArticleCallbackResponse,
  ) => {
    if (keyboardEventHelpers.isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();

      handleClickArticle(article);
    }
  };

  const renderMarkdown = (markdown?: string) => {
    if (!markdown) {
      return null;
    }

    const truncated = truncateLength
      ? truncate(markdown, truncateLength)
      : markdown;

    return <Markdown markdown={truncated} options={{ breaks: true }} />;
  };

  const renderInlineDeflectionArticles = () => {
    const articles = message?.meta?.articles;
    if (!articles?.length) {
      return null;
    }

    return (
      <div class={styles.kbInlineArticleHolder}>
        <div class={styles.related}>
          {articles.length > 1
            ? translations.kbInlineRelatedArticles
            : translations.kbInlineRelatedArticle}
        </div>
        {articles.map((article) => (
          <div
            key={article.articleId}
            class={styles.button}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => handleKeyPress(e, article)}
            onClick={() => handleClickArticle(article)}
          >
            <div class={styles.icon}>
              <FileDocument width={14} height={14} />
            </div>
            <div class={styles.details}>{article.title}</div>
          </div>
        ))}
      </div>
    );
  };

  const renderStandardMessage = () => {
    const agentData = getMessageAgentInformation(
      message?.sentBy,
      settings,
      navigation.initAssistantPayload,
    );
    return (
      <ChatBubble
        avatarUrl={agentData.avatarUrl}
        className={classNames({
          [styles.isLastInStreak]: showAvatar,
        })}
        innerClassName={classNames(chatBubbleInnerClassName)}
        showAvatar={showAvatar}
        showBotIdentifier={showBotIdentifier}
        showAIIdentifier={showAIIdentifier}
        showTimestamp={showTimestamp}
        timestamp={message?.createdAt}
        userDisplayName={agentData.displayName}
      >
        {renderMarkdown(message?.meta?.template?.body)}
        {renderInlineDeflectionArticles()}
      </ChatBubble>
    );
  };

  const renderDeflectionMessage = () => {
    const agentData = getMessageAgentInformation(
      message?.sentBy,
      settings,
      navigation.initAssistantPayload,
    );
    return (
      <Fragment>
        <ChatBubble innerClassName={classNames(chatBubbleInnerClassName)}>
          {renderMarkdown(message?.body)}
        </ChatBubble>
        <ChatBubble
          innerClassName={classNames(
            styles.kbArticlesChatBubble,
            chatBubbleInnerClassName,
          )}
        >
          {message?.meta?.articles?.map((article, index) => {
            return (
              <div
                key={article.articleId}
                className={styles.kbArticle}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => handleKeyPress(e, article)}
                onClick={() => handleClickArticle(article)}
                data-kt={`deflectionArticle${index}`}
              >
                <div className={styles.articleIcon}>
                  <FileDocument width={14} height={14} />
                </div>
                <div className={styles.articleDetails}>
                  <div className={styles.articleTitle} data-kt="kbArticleTitle">
                    {article.title}
                  </div>
                </div>
                <ChevronRight className={styles.chevronRight} />
              </div>
            );
          })}
        </ChatBubble>
        <ChatBubble
          className={classNames({
            [styles.isLastInStreak]: showAvatar,
          })}
          innerClassName={classNames(chatBubbleInnerClassName)}
          showAvatar={showAvatar}
          showBotIdentifier={showBotIdentifier}
          avatarUrl={agentData.avatarUrl}
          showTimestamp={showTimestamp}
          userDisplayName={agentData.displayName}
          timestamp={message?.createdAt}
        >
          {renderMarkdown(message?.meta?.template?.followupText)}
        </ChatBubble>
      </Fragment>
    );
  };

  const templateType = message?.meta?.template?.templateType;

  switch (templateType) {
    case 'deflection':
      return renderDeflectionMessage();
    case 'text':
    case 'quick_replies':
    case 'mll':
    case 'answer_button_feedback':
    default:
      return renderStandardMessage();
  }
};
export default TemplateMessage;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".widget__widgetWrapper___gAToq{width:100%;height:100%;padding:12.5px}.widget__widgetWrapper___gAToq.widget__mobile___1R4aB{padding:0}.widget__widget___uExal{width:100%;height:100%;border-radius:8px;box-shadow:0 2px 10px 0 #bdbdbd;background-color:#fafafa;display:flex;flex-direction:column;overflow:hidden;position:relative}.widget__modalContainer___fJ8sh{position:relative;flex-grow:1;min-height:30px;display:flex;flex-direction:column}.widget__connectionContainer___zXQUC{min-height:40px;display:flex;align-items:center;justify-content:center;background-color:#ffccc9}.widget__wifiIcon___J0x5C{margin-right:10px;color:#717070}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"widgetWrapper": "widget__widgetWrapper___gAToq",
	"mobile": "widget__mobile___1R4aB",
	"widget": "widget__widget___uExal",
	"modalContainer": "widget__modalContainer___fJ8sh",
	"connectionContainer": "widget__connectionContainer___zXQUC",
	"wifiIcon": "widget__wifiIcon___J0x5C"
};
export default ___CSS_LOADER_EXPORT___;

import { SessionDetails } from 'core_main/sdk/get_assistant/types';
import { getMappedLanguage } from 'i18n/helpers';

let startTime;
let currentPage;
let previousPage;

export const updateCurrentPage = () => {
  const parentHref = window.parent.location.href;

  if (parentHref !== currentPage) {
    previousPage = currentPage;
    currentPage = parentHref;
    startTime = Date.now();
  }
};

export const getCustomerSession = (): SessionDetails => {
  startTime = startTime || Date.now();
  previousPage = previousPage || window.parent.document.referrer;
  currentPage = currentPage || window.parent.location.href;
  const currentPageSeconds = Math.round((Date.now() - startTime) / 1000);
  return {
    currentPageSeconds,
    currentPage,
    previousPage,
    language: getMappedLanguage(),
    userAgent: window.parent.navigator.userAgent,
  };
};

import { InitCallbackResponse } from 'core_main/sdk/init/types';
import colors from 'widget_main/styles/colors.scss';
import { ThemeVariables } from './types';

export const createTheme = (
  settings: InitCallbackResponse['settings'],
): ThemeVariables => {
  return {
    '--header-background-color':
      settings.colors?.headerBackground || colors.white,
    '--header-font-primary-color':
      settings.colors?.headerPrimaryText || colors.primary,
    '--header-font-secondary-color':
      settings.colors?.headerSecondaryText || colors.secondary,
    '--agent-bubble-background-color':
      settings.colors?.agentBubbleBackground || colors.white,
    '--agent-bubble-primary-font-color':
      settings.colors?.agentBubblePrimaryText || colors.primary,
    '--agent-bubble-secondary-font-color':
      settings.colors?.agentBubbleSecondaryText || colors.secondary,
    '--customer-bubble-background-color':
      settings.colors?.customerBubbleBackground || colors.primaryBlue,
    '--customer-bubble-primary-font-color':
      settings.colors?.customerBubblePrimaryText || colors.white,
    '--primary-button-background-color':
      settings.colors?.primaryButtonBackground || colors.primaryBlue,
    '--primary-button-background-hover-color':
      settings.colors?.primaryButtonBackgroundHover || colors.primaryDarkerBlue,
    '--primary-button-font-color':
      settings.colors?.primaryButtonText || colors.white,
  };
};

import { ComponentChild, h } from 'preact';
import classNames from 'classnames';
import Avatar from 'widget_main/components/Avatar';
import TimeAgo from 'widget_main/components/TimeAgo';
import BotSparkle from 'widget_main/globals/assets/botSparkle.svg';
import { useTranslations } from 'i18n/hooks';
import styles from './chatBubble.scss';
import messages from './messages';

interface ChatBubbleProps {
  avatarUrl?: string;
  children?: ComponentChild;
  className?: string;
  innerClassName?: string;
  isOutbound?: boolean;
  showAvatar?: boolean;
  showBotIdentifier?: boolean;
  showAIIdentifier?: boolean;
  showAIProcessingAnswer?: boolean;
  showOptimisticError?: boolean;
  showTimestamp?: boolean;
  timestamp?: string;
  userDisplayName?: string;
}

const ChatBubble = ({
  avatarUrl,
  children,
  className,
  innerClassName,
  isOutbound,
  showAvatar,
  showBotIdentifier,
  showAIIdentifier,
  showOptimisticError,
  showTimestamp,
  timestamp,
  userDisplayName,
  showAIProcessingAnswer,
}: ChatBubbleProps) => {
  const translations = useTranslations(messages);

  const renderTimestamp = () => {
    return <TimeAgo className={styles.timestamp} timestamp={timestamp} />;
  };

  const renderAITypingMessage = () => {
    if (!showAIProcessingAnswer) return null;
    return <div>{translations.processingAnswer}</div>;
  };

  const renderOptimisticError = () => {
    return (
      <div className={styles.optimisticError}>
        {translations.messageSendFailure}
      </div>
    );
  };

  const renderSeparator = () => {
    const useGenericSeparator = showTimestamp && showBotIdentifier;
    const useAISeparator = showAIIdentifier || showAIProcessingAnswer;

    if (!useGenericSeparator && !useAISeparator) return null;

    return (
      <div className={styles.separator}>
        {useAISeparator ? <BotSparkle /> : '•'}
      </div>
    );
  };

  const renderIdentifier = () => {
    if (!showBotIdentifier && !showAIIdentifier) return null;
    return (
      <div className={styles.identifier}>
        {showAIIdentifier ? translations.aiAnswer : translations.messageBot}
      </div>
    );
  };

  const renderMessageMeta = () => {
    if (
      !showTimestamp &&
      !showBotIdentifier &&
      !showAIIdentifier &&
      !showAIProcessingAnswer
    ) {
      return null;
    }
    return (
      <div className={styles.messageMeta}>
        {showTimestamp && renderTimestamp()}
        {renderSeparator()}
        {renderIdentifier()}
        {renderAITypingMessage()}
      </div>
    );
  };

  const renderInboundBubble = () => {
    return (
      <div className={classNames(styles.inbound, className)}>
        <div className={styles.avatarAndBubble}>
          {showAvatar && (
            <Avatar
              className={styles.avatarWrapper}
              size={30}
              avatarUrl={avatarUrl}
              userDisplayName={userDisplayName}
            />
          )}
          <div
            className={classNames(
              styles.chatBubble,
              {
                [styles.showAvatar]: showAvatar,
              },
              innerClassName,
            )}
            data-kt="inboundMessageBubbleText"
          >
            {children}
          </div>
        </div>
        {renderMessageMeta()}
      </div>
    );
  };

  const renderOutboundBubble = () => {
    return (
      <div className={classNames(styles.outbound, className)}>
        <div
          className={classNames(styles.chatBubble, innerClassName)}
          data-kt="outboundMessageBubbleText"
        >
          {children}
        </div>
        {renderMessageMeta()}
        {showOptimisticError && renderOptimisticError()}
      </div>
    );
  };

  return isOutbound ? renderOutboundBubble() : renderInboundBubble();
};
export default ChatBubble;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".satisfactionMessageScale__ratingOptionContainer___cV5eR{cursor:pointer;width:50px;height:50px;background-size:contain;background-repeat:no-repeat;display:inline-block}.satisfactionMessageScale__optionContainer___pbmLv{display:flex;width:100%;justify-content:space-around}.satisfactionMessageScale__labels___RDK7j{width:100%;display:flex;justify-content:space-between;margin:15px 0}.satisfactionMessageScale__container___fCjWW{width:115px;height:2.4em;overflow:hidden}.satisfactionMessageScale__label___xVz4-{color:var(--agent-bubble-secondary-font-color);display:block;display:-webkit-box;-webkit-box-orient:vertical;position:relative;overflow:hidden;text-overflow:ellipsis;-webkit-line-clamp:2;height:2.4em}.satisfactionMessageScale__label___xVz4-::after{content:\"...\";text-align:right;bottom:0;right:0;width:10%;display:block;position:absolute;height:1.2em;background:var(--agent-bubble-background-color);color:var(--agent-bubble-secondary-font-color)}@supports(-webkit-line-clamp: 1){.satisfactionMessageScale__label___xVz4-::after{display:none}}html[dir=\"rtl\"] .satisfactionMessageScale__optionContainer___pbmLv{direction:rtl}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"ratingOptionContainer": "satisfactionMessageScale__ratingOptionContainer___cV5eR",
	"optionContainer": "satisfactionMessageScale__optionContainer___pbmLv",
	"labels": "satisfactionMessageScale__labels___RDK7j",
	"container": "satisfactionMessageScale__container___fCjWW",
	"label": "satisfactionMessageScale__label___xVz4-"
};
export default ___CSS_LOADER_EXPORT___;

import { initLocale } from 'i18n/helpers';

import { useContext, useEffect, useState } from 'preact/hooks';
import { ConnectionContext } from 'widget_main/store';
import { UseVendorsState } from './types';

let vendors;

export const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export const useLocale = () => {
  const [isLoadingLocale, setIsLoadingLocale] = useState(true);
  const connection = useContext(ConnectionContext);

  useEffect(() => {
    if (connection !== 'online') {
      setIsLoadingLocale(false);
      return;
    }

    retry(() => initLocale())
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoadingLocale(false);
      });
  }, [connection]);

  return [isLoadingLocale];
};

export const addDomPurifyHooks = (dompurifyModule) => {
  dompurifyModule?.default?.addHook('afterSanitizeAttributes', (node) => {
    // set all elements owning target to target=_blank
    if (node && 'target' in node) {
      (node as Element).setAttribute('target', '_blank');
      (node as Element).setAttribute('rel', 'noopener');
    }
  });
};
export const useVendors = (): [
  UseVendorsState | undefined,
  boolean,
  Error?,
] => {
  const connection = useContext(ConnectionContext);

  const [isLoadingVendors, setIsLoadingVendors] = useState(true);
  const [loadingVendorsError, setIsLoadingVendorsError] = useState(undefined);

  useEffect(() => {
    if (connection !== 'online' || vendors) {
      setIsLoadingVendors(false);
      return;
    }

    Promise.all([
      retry(() => import(/* webpackChunkName: "marked" */ 'marked')),
      retry(() => import(/* webpackChunkName: "dompurify" */ 'dompurify')),
    ])
      .then((mod) => {
        const markedModule = mod[0] as { marked: UseVendorsState['marked'] };

        const dompurifyModule = mod[1] as {
          default: UseVendorsState['dompurify'];
        };
        addDomPurifyHooks(dompurifyModule);
        vendors = {
          marked: markedModule.marked,
          dompurify: dompurifyModule.default,
        };
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingVendorsError(error);
      })
      .finally(() => {
        setIsLoadingVendors(false);
      });
  }, [connection]);

  return [vendors, isLoadingVendors, loadingVendorsError];
};

export const getMarked = () => {
  return vendors?.marked;
};

export const sanitizeHtml = (dirtyHtml, config) => {
  return (vendors?.dompurify?.sanitize(dirtyHtml, config) || '') as string;
};

import { h, render, Fragment, Component, options } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { isBrowserIE, installPolyfills } from 'polyfills/helpers';
import { enableES5 } from 'immer';
import {
  getKustomer,
  getKustomerCore,
  handleFirstTab,
  handleMouseClick,
} from 'widget_main/globals/helpers';

import * as helpers from 'widget_main/app/helpers';
import { AppProps } from 'widget_main/app/types';
import App from './app';
import './styles/base.scss';
import { initSentry } from '../../globals/sentry';
import { IFRAME_MESSAGES } from '../../globals/iframeMessages';
import { getMessagesForLanguage } from '../../i18n/helpers';
import { NormalizedStartParameters } from '../widget_api/types';
import { isInAppBrowser } from '../../helpers/browser';

if (isBrowserIE()) {
  enableES5();
}
const kustomerCore = getKustomerCore();

const Root = ({
  startParameters,
}: {
  startParameters: NormalizedStartParameters;
}) => {
  const [chatSettings, setChatSettings] = useState<AppProps['chatSettings']>();
  const [intlDefinitions, setIntlDefinitions] =
    useState<AppProps['intlDefinitions']>();
  const [chatAvailability, setChatAvailability] =
    useState<AppProps['chatAvailability']>();

  useEffect(() => {
    window.addEventListener('keydown', handleFirstTab);

    return () => {
      window.removeEventListener('keydown', handleFirstTab);
      window.removeEventListener('mousedown', handleMouseClick);
    };
  }, []);

  const loadSettings = () => {
    // Even though kustomerCore.init returns a Promise, it is possible for it to resolve before pubnub finishes initiating.
    // That is why we wrap it in another Promise that will only resolve once the callback is called.
    return new Promise<void>((resolve) => {
      kustomerCore.init(
        {
          scheduleId: startParameters.scheduleId,
          brandId: startParameters.brandId,
          lang: startParameters.lang,
          assistantId: startParameters.assistantId,
        },
        (response, error) => {
          if (error) return;
          const { settings } = response;

          if (settings.sentry?.web && !isInAppBrowser()) {
            // can we get org info here? maybe we should decode the api key? or is there more info on settings?
            initSentry(settings.sentry.web);
          }

          setChatSettings(settings);

          // `chatAvailability` is based on business and holiday schedules, so we must wait until after settings are
          // loaded to check this state from core. We are also able to override the availability from startParameters.
          // We do this on the live preview page.
          let chatAvailability = kustomerCore.isChatAvailable().availability;
          const businessHoursOverride =
            startParameters?.livePreviewOverrides?.businessHours;

          if (businessHoursOverride === IFRAME_MESSAGES.INSIDE_BUSINESS_HOURS) {
            chatAvailability = 'online';
          } else if (
            businessHoursOverride === IFRAME_MESSAGES.OUTSIDE_BUSINESS_HOURS
          ) {
            chatAvailability = 'offline';
            if (settings.offhoursDisplay === 'none') {
              chatAvailability = 'hidden';
            }
          }

          setChatAvailability(chatAvailability);

          helpers.setThemeVariables(settings);

          resolve();
        },
      );
    });
  };

  useEffect(() => {
    // init on first render
    loadSettings();
    getMessagesForLanguage().then((langModule) => {
      setIntlDefinitions(langModule?.default);
    });
  }, []);

  if (!chatSettings) {
    return null;
  }

  return (
    <App
      intlDefinitions={intlDefinitions}
      chatSettings={chatSettings}
      chatAvailability={chatAvailability}
      hideHistory={startParameters?.hideHistory}
      hideChatIcon={!!startParameters?.hideChatIcon}
      hideNewConversationButtonSettings={{
        onChatHistory: !!startParameters?.hideNewConversationButtonOnHistory,
        onConversationEnd: !!startParameters?.hideNewConversationButtonOnEnd,
      }}
      reloadSettings={loadSettings}
    />
  );
};

installPolyfills().then(() => {
  // preact-devtools claims to work in iframes, but that doesn't seem to work?
  // Force install it to the top frame instead.
  try {
    // eslint-disable-next-line no-underscore-dangle
    const devtools = (window.top as any)?.__PREACT_DEVTOOLS__;
    if (devtools) {
      devtools.attachPreact('10.6.4', options, {
        Fragment,
        Component,
      });
    }
  } catch {}

  const startParameters = getKustomer()?.startParameters;

  render(
    <Root startParameters={startParameters} />,
    document.getElementById('root') as HTMLElement,
  );
});

import { useTranslations } from 'i18n/hooks';
import { h } from 'preact';
import * as helpers from './helpers';
import messages from './messages';

interface TimeAgoProps {
  className?: string;
  timestamp?: string;
}

const TimeAgo = ({ className, timestamp }: TimeAgoProps) => {
  const translations = useTranslations(messages);

  if (!timestamp) return null;

  let timeAgo = helpers.getTimeAgo(timestamp);

  if (timeAgo === 'Just now') {
    timeAgo = translations.timeAgoJustNow;
  }

  return (
    <div className={className} aria-hidden={true}>
      {timeAgo}
    </div>
  );
};
export default TimeAgo;

import classNames from 'classnames';
import { h } from 'preact';

import ChevronRight from 'widget_main/globals/assets/chevronRight.svg';
import { useTranslations } from 'i18n/hooks';
import globalMessages from 'widget_main/globals/messages';
import Text from 'widget_main/components/Text';
import { CHAT_MESSAGE_THREAD_ID } from '../constants';
import styles from './scrollToCurrent.scss';
import messages from './messages';

interface ScrollToCurrentProps {
  showNewMessagesText: boolean;
  showScrollToCurrent: boolean;
}

export const ScrollToCurrent = ({
  showNewMessagesText,
  showScrollToCurrent,
}: ScrollToCurrentProps) => {
  const translations = useTranslations(messages);
  const scrollViewToBottom = () => {
    const chatMessageThread = document.getElementById(CHAT_MESSAGE_THREAD_ID);
    if (chatMessageThread) {
      chatMessageThread.scrollTo({
        top: chatMessageThread.scrollHeight - 1,
        behavior: 'smooth',
      });
    }
  };
  return (
    <div
      className={classNames(styles.scrollToCurrentContainer, {
        [styles.scrollToContainerFadeIn]: showScrollToCurrent,
        [styles.scrollToContainerFadeOut]: !showScrollToCurrent,
      })}
    >
      <button
        type="button"
        className={classNames(styles.scrollToCurrent, {
          [styles.hasNewMessages]: showNewMessagesText,
        })}
        onClick={scrollViewToBottom}
        aria-label={translations.scrollToBottom}
      >
        <ChevronRight className={styles.chevronDown} />
        {showNewMessagesText && (
          <Text id="newMessages" defaultMessage={globalMessages.newMessages} />
        )}
      </button>
    </div>
  );
};

import { getKustomer } from 'widget_main/globals/helpers';

class _DisableZoomMetaElement {
  readonly META_VIEWPORT_ID: string;

  readonly getParentDocument: () => Document;

  readonly getParentHead: () => HTMLHeadElement | null;

  readonly getMetaViewport: () => HTMLElement | null;

  constructor() {
    this.META_VIEWPORT_ID = 'kustomer-viewport';

    this.getParentDocument = () => window?.parent?.document;
    this.getParentHead = () =>
      this.getParentDocument()?.getElementsByTagName('head')?.[0];
    this.getMetaViewport = () =>
      this.getParentDocument()?.getElementById(this.META_VIEWPORT_ID);
  }

  buildMetaTag(): HTMLElement {
    const metaViewPortEl = this.getParentDocument().createElement('meta');
    metaViewPortEl.id = this.META_VIEWPORT_ID;
    metaViewPortEl.name = 'viewport';
    metaViewPortEl.content =
      'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';
    return metaViewPortEl;
  }

  add(): void {
    const hasMetaViewport = this.getMetaViewport();
    if (hasMetaViewport) return;

    const metaViewPortEl = this.buildMetaTag();
    const head = this.getParentHead();
    head?.appendChild(metaViewPortEl);
  }

  remove(): void {
    const metaViewportEl = this.getMetaViewport();
    if (!metaViewportEl) return;

    const head = this.getParentHead();
    head?.removeChild(metaViewportEl);
  }
}

export const DisableZoomMetaElement = new _DisableZoomMetaElement();

// Usage of allowZoom param \\
const getIsZoomAllowed = () => getKustomer()?.startParameters?.allowZoom;

export const maybeAddDisableZoomMetaTag = () => {
  const isZoomAllowed = getIsZoomAllowed();
  if (isZoomAllowed) return;

  DisableZoomMetaElement.add();
};

export const maybeRemoveDisableZoomMetaTag = () => {
  const isZoomAllowed = getIsZoomAllowed();
  if (isZoomAllowed) return;

  DisableZoomMetaElement.remove();
};

export const ALLOWED_MIME_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/zip',
  'text/plain',
  'text/html',
  'video/mpeg',
  'video/mp4',
  'image/png',
  'image/jpeg',
  'image/jpeg',
  // the options below may get removed depending on PM feedback
  'audio/mpeg',
  'video/mpeg',
  'image/gif',
  'video/webm',
  'image/webp',
];

export const MAX_FILE_SIZE = 5000000; // 5mb
export const MAX_TOTAL_SIZE = 9000000;

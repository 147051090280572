import { OptimisticMessage } from 'widget_main/store/types';
import {
  Attachment,
  MessageCallbackResponse,
  SendMessageParameters,
} from 'core_main/sdk/send_message/types';
import {
  DISTANCE_TO_BOTTOM_FOR_LOCK,
  DISTANCE_TO_TOP_TO_LOAD_MORE,
} from './constants';

export const createOptimisticMessage = (
  sendMessagePayload: SendMessageParameters,
): OptimisticMessage => {
  const {
    body,
    payload,
    attachments,
    lastDeflection,
    isInAssistantMode,
    initAssistantPayload,
    lang,
  } = sendMessagePayload;
  return {
    messageId: String(Date.now()),
    body,
    payload,
    attachments: attachments?.map((attachment: Attachment) => ({
      attachmentId: String(Date.now()),
      file: attachment.file,
      url: attachment.url,
      name: attachment.file.name,
      contentLength: attachment.file.size,
      contentType: attachment.file.type,
    })),
    lastDeflection,
    isInAssistantMode,
    initAssistantPayload,
    lang,
    sentBy: {
      type: 'customer',
    },
  };
};
export const isLastMessageFromCustomer = (
  messages: MessageCallbackResponse[] | null,
) => {
  const lastMessageConvo = messages?.[messages.length - 1];

  return lastMessageConvo?.sentBy?.type === 'customer';
};

export const getMessageIdsToMarkAsRead = (
  conversationId: string,
  messages: Record<string, MessageCallbackResponse>,
): string[] => {
  if (!conversationId || !messages) return [];
  const messageIds = Object.keys(messages);

  return messageIds.filter((messageId) => {
    const message = messages[messageId];
    const isConversationMessage = message.conversationId === conversationId;
    const isSentByAgent = message.direction === 'out';
    return isConversationMessage && isSentByAgent;
  });
};

export const isScrolledToBottom = (element: {
  scrollTop: number;
  scrollHeight: number;
  clientHeight: number;
}) => {
  return (
    element.scrollHeight - element.scrollTop - DISTANCE_TO_BOTTOM_FOR_LOCK <=
    element.clientHeight
  );
};

export const isScrolledToTop = (element: { scrollTop: number }) => {
  return element.scrollTop <= DISTANCE_TO_TOP_TO_LOAD_MORE;
};

import { h, Fragment } from 'preact';
import { SatisfactionFormScale } from 'core_main/api/satisfaction/types';
import { isEnterKey } from 'widget_main/globals/keyboardEvents';
import Text from 'widget_main/components/Text';
import { Localizer } from 'preact-i18n';
import styles from './satisfactionMessageScale.scss';
import { SCALE_MAP } from './constants';
import messages from './messages';

interface SatisfactionMessageScaleProps {
  scale?: SatisfactionFormScale;
  rating?: number;
  onClick: (number) => void;
  disabled?: boolean;
}

const SatisfactionMessageScale = ({
  scale,
  rating,
  onClick,
  disabled,
}: SatisfactionMessageScaleProps) => {
  const options = scale?.options;
  const labelLow = scale?.labelLow;
  const labelHigh = scale?.labelHigh;
  const type = scale?.type;

  const disabledString = disabled ? 'Disabled' : '';
  const scaleOptionsAriaLabels = {
    satisfactionRatingOption: `satisfactionRatingOption${disabledString}`,
    highestSatisfactionRatingOption: `highestSatisfactionRatingOption${disabledString}`,
    lowestSatisfactionRatingOption: `lowestSatisfactionRatingOption${disabledString}`,
  };

  const handleSetRatingKeyPress = (e, rating) => {
    if (isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();
      onClick(rating);
    }
  };

  return (
    <Fragment>
      <div className={styles.optionContainer}>
        {options &&
          SCALE_MAP[options].map((option, optionIndex) => {
            const style = rating === option ? 'color' : 'grey';
            const imgUrl = `https://cdn.kustomerapp.com/satisfaction/${type}_${option}_${style}.svg`;
            const isLow = optionIndex === 0;
            const isHigh = optionIndex === SCALE_MAP[options].length - 1;
            const isSet = option === rating;

            let ariaLabel = Text({
              id: scaleOptionsAriaLabels.satisfactionRatingOption,
              fields: {
                option,
              },
              defaultMessage:
                messages[scaleOptionsAriaLabels.satisfactionRatingOption],
            });

            if (isHigh) {
              ariaLabel = Text({
                id: scaleOptionsAriaLabels.highestSatisfactionRatingOption,
                fields: {
                  option,
                },
                defaultMessage:
                  messages[
                    scaleOptionsAriaLabels.highestSatisfactionRatingOption
                  ],
              });
            } else if (isLow) {
              ariaLabel = Text({
                id: scaleOptionsAriaLabels.lowestSatisfactionRatingOption,
                fields: {
                  option,
                },
                defaultMessage:
                  messages[
                    scaleOptionsAriaLabels.lowestSatisfactionRatingOption
                  ],
              });
            }

            return (
              <Localizer key={option}>
                <div
                  className={styles.ratingOptionContainer}
                  tabIndex={0}
                  role="button"
                  onKeyPress={
                    !isSet
                      ? (e) => handleSetRatingKeyPress(e, option)
                      : undefined
                  }
                  onClick={!isSet ? () => onClick(option) : undefined}
                  style={{ backgroundImage: `url(${imgUrl})` }}
                  aria-label={ariaLabel}
                />
              </Localizer>
            );
          })}
      </div>
      <div className={styles.labels}>
        <div className={styles.container}>
          <p className={styles.label}>{labelLow}</p>
        </div>
        <div className={styles.container}>
          <p className={styles.label}>{labelHigh}</p>
        </div>
      </div>
    </Fragment>
  );
};

export default SatisfactionMessageScale;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".errorBoundary__container___EVs\\+k{height:100%;width:100%;display:flex;justify-content:center;align-items:center;flex-direction:column;text-align:center;padding:10px}.errorBoundary__header___wTTIv{color:#222;font-weight:500;margin:50px 20px 10px;font-size:16px}.errorBoundary__subHeader___SXEdk{color:#717070;font-size:12px}.errorBoundary__refreshButton___NriPn{margin-top:25px;width:200px;height:36px;font-size:16px;box-shadow:0 2px 6px 0 rgba(201,201,201,.5)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"container": "errorBoundary__container___EVs+k",
	"header": "errorBoundary__header___wTTIv",
	"subHeader": "errorBoundary__subHeader___SXEdk",
	"refreshButton": "errorBoundary__refreshButton___NriPn"
};
export default ___CSS_LOADER_EXPORT___;

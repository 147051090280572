import { h, Fragment } from 'preact';
import classNames from 'classnames';
import { MessageAttachmentCallbackResponse } from 'core_main/sdk/send_message/types';
import PdfIcon from 'widget_main/globals/assets/pdfFile.svg';
import FilePresentIcon from 'widget_main/globals/assets/filePresent.svg';
import * as keyboardEventHelpers from 'widget_main/globals/keyboardEvents';
import globalMessages from 'widget_main/globals/messages';

import ChatBubble from 'widget_main/components/ChatMessage/ChatBubble';
import MediaWithPreview from 'widget_main/components/MediaWithPreview';
import { OptimisticMessage } from 'widget_main/store/types';
import { MediaContent } from 'widget_main/components/MediaWithPreview/types';
import Markdown from 'widget_main/components/Markdown/Markdown';
import { useContext } from 'preact/hooks';
import { NavigationContext, SettingsContext } from 'widget_main/store';
import { getMessageAgentInformation } from 'widget_main/globals/helpers';
import Text from 'widget_main/components/Text';
import { ChatMessageProps } from './types';

import SatisfactionMessage from '../../screens/MessageThread/SatisfactionMessage';
import styles from './chatMessage.scss';
import TemplateMessage from './TemplateMessage';

import messages from './messages';

const ChatMessage = ({
  chatBubbleInnerClassName,
  handleRetrySend,
  message,
  satisfaction,
  showAvatar,
  showBotIdentifier,
  showAIIdentifier,
  showNewMessageDivider,
  showTimestamp,
  userTyping,
}: ChatMessageProps) => {
  const chatSettings = useContext(SettingsContext);
  const navigation = useContext(NavigationContext);
  const handleClick = () => {
    if (handleRetrySend) {
      handleRetrySend(message as OptimisticMessage);
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (keyboardEventHelpers.isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();

      handleClick();
    }
  };

  const renderMediaPreview = (
    attachment: MessageAttachmentCallbackResponse,
    type: MediaContent,
  ) => {
    if (attachment.url) {
      return (
        <MediaWithPreview
          className={styles.mediaPreview}
          src={attachment.url}
          type={type}
        />
      );
    }
    return null;
  };

  const renderFilePreview = (attachment: MessageAttachmentCallbackResponse) => {
    const { contentType } = attachment;
    const isPDF = contentType?.includes('pdf');
    const icon = isPDF ? (
      <PdfIcon class={styles.pdfIcon} />
    ) : (
      <FilePresentIcon className={styles.pdfIcon} />
    );

    return (
      <div class={styles.filePreview}>
        {icon}
        <a href={attachment.url} target="_blank" rel="noreferrer noopener">
          {attachment.name}
        </a>
      </div>
    );
  };

  const renderMarkdownBody = () => {
    if (!message?.body) {
      return null;
    }

    return <Markdown markdown={message.body} options={{ breaks: true }} />;
  };

  const renderPlainBody = () => {
    return <div>{message?.body}</div>;
  };

  const renderAttachment = (attachment: MessageAttachmentCallbackResponse) => {
    const contentType = attachment?.contentType;
    const isImage = contentType.includes('image');
    const isVideo = contentType.includes('video');

    if (isImage) {
      return renderMediaPreview(attachment, 'image');
    }

    if (isVideo) {
      return renderMediaPreview(attachment, 'video');
    }

    return renderFilePreview(attachment);
  };

  const renderAttachments = () => {
    const attachments = message?.attachments;

    if (!attachments?.length) {
      return null;
    }

    return (
      <div>
        {attachments.map((attachment) => {
          return (
            <div
              key={attachment.attachmentId}
              className={classNames(styles.attachment, {
                [styles.attachmentWithoutMessage]: !message?.body,
              })}
            >
              {renderAttachment(attachment)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderInbound = () => {
    const agentData = getMessageAgentInformation(
      message?.sentBy,
      chatSettings,
      navigation.initAssistantPayload,
    );
    return (
      <ChatBubble
        className={classNames({
          [styles.isLastInStreak]: showAvatar,
        })}
        innerClassName={classNames(chatBubbleInnerClassName)}
        showAvatar={showAvatar}
        avatarUrl={agentData.avatarUrl}
        userDisplayName={agentData.displayName}
        showTimestamp={showTimestamp}
        timestamp={message?.createdAt}
      >
        <Fragment>
          <span className={styles.visuallyHidden}>
            <Text
              id="inboundAriaDefinitionText"
              defaultMessage={messages.inboundAriaDefinitionText}
              fields={{
                name: agentData.displayName || '',
              }}
            />
          </span>
          {renderMarkdownBody()}
          {renderAttachments()}
        </Fragment>
      </ChatBubble>
    );
  };

  const renderInboundTypingIndicator = () => {
    const agentData = getMessageAgentInformation(
      message?.sentBy,
      chatSettings,
      navigation.initAssistantPayload,
    );
    return (
      <ChatBubble
        innerClassName={classNames(chatBubbleInnerClassName)}
        showAvatar={showAvatar}
        avatarUrl={userTyping?.avatarUrl}
        showAIProcessingAnswer={userTyping?.isAIGeneratingAnswer}
      >
        <div
          className={classNames(styles.typingIndicator, {
            [styles.aiAssistantTypingIndicator]:
              userTyping?.isAIGeneratingAnswer,
          })}
        >
          <span className={styles.visuallyHidden}>
            <Text
              id="inboundChatIndicator"
              defaultMessage={messages.inboundChatIndicator}
              fields={{
                name: agentData.displayName || '',
              }}
            />
          </span>
          <div />
          <div />
          <div />
        </div>
      </ChatBubble>
    );
  };

  const renderSatisfactionMessage = () => {
    return (
      <ChatBubble
        avatarUrl={chatSettings.teamIconUrl}
        userDisplayName={chatSettings.teamName}
        showAvatar
        innerClassName={classNames(
          styles.satisfactionBubble,
          chatBubbleInnerClassName,
        )}
      >
        <SatisfactionMessage satisfaction={satisfaction} />
      </ChatBubble>
    );
  };

  const renderOutbound = () => {
    const chatBubble = (
      <ChatBubble
        isOutbound
        className={classNames({
          [styles.isLastInStreak]: showTimestamp,
        })}
        innerClassName={classNames(chatBubbleInnerClassName)}
        showTimestamp={showTimestamp}
        timestamp={message?.createdAt}
        showOptimisticError={message?.showError}
      >
        <Fragment>
          <span className={styles.visuallyHidden}>
            <Text
              id="outboundAriaDefinitionText"
              defaultMessage={messages.outboundAriaDefinitionText}
            />
          </span>
          {renderPlainBody()}
          {renderAttachments()}
        </Fragment>
      </ChatBubble>
    );

    if (handleRetrySend && message?.showError) {
      return (
        <div
          role="button"
          tabIndex={0}
          onClick={handleClick}
          onKeyPress={handleKeyPress}
          className={styles.retryMessage}
        >
          {chatBubble}
        </div>
      );
    }

    return chatBubble;
  };

  const renderMessage = () => {
    if (userTyping) {
      return renderInboundTypingIndicator();
    }

    if (satisfaction) {
      return renderSatisfactionMessage();
    }

    if (message?.meta?.template) {
      return (
        <TemplateMessage
          showAvatar={showAvatar}
          showAIIdentifier={showAIIdentifier}
          showBotIdentifier={showBotIdentifier}
          showTimestamp={showTimestamp}
          message={message}
          chatBubbleInnerClassName={chatBubbleInnerClassName}
        />
      );
    }

    return message?.sentBy?.type === 'user'
      ? renderInbound()
      : renderOutbound();
  };

  const renderNewMessageDivider = () => {
    if (showNewMessageDivider) {
      return (
        <div className={styles.newMessageDivider}>
          <div className={styles.newMessageDividerLine} />
          <div className={styles.newMessageDividerText}>
            <Text
              id="newMessages"
              defaultMessage={globalMessages.newMessages}
            />
          </div>
          <div className={styles.newMessageDividerLine} />
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {renderNewMessageDivider()}
      {renderMessage()}
    </div>
  );
};
export default ChatMessage;

ChatMessage.defaultProps = {
  showAvatar: false,
  showTimestamp: false,
};

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kbHeader__kbHeader___kf-zd{background-color:var(--header-background-color);width:100%;border-bottom:.5px solid #e9eff5;padding-bottom:15px}.kbHeader__content___54Vks{padding:0 20px;display:flex;flex-direction:column;align-items:center;text-align:center}.kbHeader__chatIconWrapper___NIrj2{margin-top:25px;border-radius:50%}.kbHeader__currentView___8ADKV{min-height:76px;display:flex;align-items:center;justify-content:center;background-color:var(--header-background-color);border-bottom:.5px solid #e9eff5}.kbHeader__header___4Ukry{line-height:normal;margin:10px 0 5px;color:var(--header-font-primary-color);font-size:16px;font-weight:500}.kbHeader__inputContainer___Xcg0j{width:100%;position:relative;margin-top:15px;margin-bottom:10px}.kbHeader__articleSearchInput___1CnmM{width:100%;height:40px;padding:10px 70px 10px 15px;border-radius:8px;border:.5px solid #e9eff5;background-color:#fafafa}.kbHeader__articleSearchInput___1CnmM:focus{background-color:#fff;box-shadow:0 2px 6px 0 rgba(201,201,201,.5)}.kbHeader__articleSearchInput___1CnmM::-ms-clear{display:none;height:0;width:0}.kbHeader__clearIcon___09Kyj,.kbHeader__searchIcon___3k78o{position:absolute;top:8px;color:#717070;cursor:pointer}.kbHeader__clearIcon___09Kyj{width:20px;right:50px}.kbHeader__searchIcon___3k78o{width:23px;right:15px}.kbHeader__resultsCount___CPzZI{color:var(--header-font-secondary-color);font-size:12px;align-self:flex-start}.kbHeader__highlighted___w6o9c{color:#336dec}html[dir=\"rtl\"] .kbHeader__articleSearchInput___1CnmM{padding:10px 15px 10px 70px}html[dir=\"rtl\"] .kbHeader__clearIcon___09Kyj{right:initial;left:50px}html[dir=\"rtl\"] .kbHeader__searchIcon___3k78o{right:initial;left:15px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"kbHeader": "kbHeader__kbHeader___kf-zd",
	"content": "kbHeader__content___54Vks",
	"chatIconWrapper": "kbHeader__chatIconWrapper___NIrj2",
	"currentView": "kbHeader__currentView___8ADKV",
	"header": "kbHeader__header___4Ukry",
	"inputContainer": "kbHeader__inputContainer___Xcg0j",
	"articleSearchInput": "kbHeader__articleSearchInput___1CnmM",
	"clearIcon": "kbHeader__clearIcon___09Kyj",
	"searchIcon": "kbHeader__searchIcon___3k78o",
	"resultsCount": "kbHeader__resultsCount___CPzZI",
	"highlighted": "kbHeader__highlighted___w6o9c"
};
export default ___CSS_LOADER_EXPORT___;

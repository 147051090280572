import { h } from 'preact';
import classNames from 'classnames';
import ChatIcon from 'widget_main/components/ChatIcon';
import { useTranslations } from 'i18n/hooks';
import { useState } from 'preact/hooks';
import styles from './avatar.scss';

import messages from './messages';

interface AvatarProps {
  size?: number;
  className?: string;
  avatarUrl?: string;

  userDisplayName?: string;
}

const Avatar = ({
  size,
  className,
  avatarUrl,
  userDisplayName,
}: AvatarProps) => {
  const translations = useTranslations(messages);
  const [imageFetchState, setImageFetchState] = useState('fetching');

  const renderAvatarUrl = () => {
    const formattedAvatarUrl = avatarUrl?.replace('d=blank', 'd=404'); // Gravatar will return an HTTP 404 (File Not Found) response

    if (!avatarUrl && !userDisplayName) {
      return (
        <ChatIcon size={size ? { width: size, height: size } : undefined} />
      );
    }

    if (imageFetchState === 'error' || !avatarUrl) {
      return (
        <div className={styles.acronymWrapper}>
          <span>{userDisplayName?.charAt(0) || null}</span>
        </div>
      );
    }

    const imageAlt = userDisplayName
      ? `${userDisplayName} ${translations.agentAvatar}`
      : translations.agentAvatar;

    return (
      <img
        class={classNames(styles.customImg, {
          [styles.hideImg]: imageFetchState === 'fetching',
        })}
        src={formattedAvatarUrl}
        alt={imageAlt}
        onError={() => {
          setImageFetchState('error');
        }}
        onLoad={() => {
          setImageFetchState('success');
        }}
      />
    );
  };

  return (
    <div
      style={{ width: `${size}px`, height: `${size}px` }}
      class={classNames(styles.avatar, className)}
      aria-hidden={true}
    >
      {renderAvatarUrl()}
    </div>
  );
};

Avatar.defaultProps = {
  size: 40,
};
export default Avatar;

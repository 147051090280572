import { ChatIconPosition } from './types';

export const DEFAULT_Z_INDEX = '2147483000';
export const DEFAULT_CHAT_ICON_POSITION: ChatIconPosition = {
  alignment: 'right',
  horizontalPadding: 20,
  verticalPadding: 20,
};

export const publicConstants = {
  KNOWLEDGE_BASE_VIEW: 'kb',
  CHAT_HISTORY_VIEW: 'chat',
};

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kbArticle__articleIframe___\\+6p2T{height:100%;width:100%;background-color:#fff;overflow:hidden}.kbArticle__articleContainer___OHVJ8{height:100%;width:100%;background-color:#fff;overflow:hidden}.kbArticle__loaderWrapper___w7ALp{height:100%;width:100%;display:flex;justify-content:center;align-items:center;background-color:#fff}.kbArticle__loader___\\+vnwK{margin-top:unset}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"articleIframe": "kbArticle__articleIframe___+6p2T",
	"articleContainer": "kbArticle__articleContainer___OHVJ8",
	"loaderWrapper": "kbArticle__loaderWrapper___w7ALp",
	"loader": "kbArticle__loader___+vnwK"
};
export default ___CSS_LOADER_EXPORT___;

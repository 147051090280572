// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chatIcon__chatIcon___7jaa5{width:100%;height:100%;background-repeat:no-repeat;background-position:center center}.chatIcon__chatIcon___7jaa5.chatIcon__pointer___nVps2{cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"chatIcon": "chatIcon__chatIcon___7jaa5",
	"pointer": "chatIcon__pointer___nVps2"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footerConsent__separator___V42r4{margin:0 10px}.footerConsent__consent___4QZMb{font-weight:normal;overflow:hidden;width:120px;text-overflow:ellipsis;white-space:nowrap;background:none;border:0 none;padding:0;cursor:default}.footerConsent__footerTooltipContainer___vCYa0{position:absolute;bottom:30px;left:0;width:100%}.footerConsent__footerTooltipContent___5mmPn{position:relative;display:block;width:237px;padding:5px;margin:0 auto;background:#1f242d;border:0 none;text-align:left;border-radius:3px}.footerConsent__footerTooltipContentClicked___U\\+Csf{width:222px;padding:5px 20px 5px 5px}.footerConsent__footerTooltip___QZlNr{color:#fff;font-weight:normal;font-size:12px}.footerConsent__footerTooltip___QZlNr::after{top:100%;left:50%;content:\"\";height:0;width:0;position:absolute;pointer-events:none;border-style:solid;border-color:rgba(31,36,45,0);border-top-color:#1f242d;border-width:4px;margin-left:-4px}.footerConsent__closeButton___DqUX2{position:absolute;top:0;right:0}.footerConsent__closeButton___DqUX2 svg{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"separator": "footerConsent__separator___V42r4",
	"consent": "footerConsent__consent___4QZMb",
	"footerTooltipContainer": "footerConsent__footerTooltipContainer___vCYa0",
	"footerTooltipContent": "footerConsent__footerTooltipContent___5mmPn",
	"footerTooltipContentClicked": "footerConsent__footerTooltipContentClicked___U+Csf",
	"footerTooltip": "footerConsent__footerTooltip___QZlNr",
	"closeButton": "footerConsent__closeButton___DqUX2"
};
export default ___CSS_LOADER_EXPORT___;

import { h } from 'preact';
import { useState, useContext, useEffect, useRef } from 'preact/hooks';
import classNames from 'classnames';

import { ProactiveChatContext } from 'widget_main/store';

import { useLocale, useVendors } from 'widget_main/vendors/hooks';
import styles from './proactivePopup.scss';
import proactiveChatMessageStyles from './ProactiveChatMessage/proactiveChatMessage.scss';

import CloseProactiveChatButton from './CloseProactiveChatButton/CloseProactiveChatButton';

import ProactiveChatMessage from './ProactiveChatMessage/ProactiveChatMessage';
import { useResizeIframe } from './helpers';
import ProactiveChatInput from './ProactiveChatInput/ProactiveChatInput';

const ProactivePopup = ({ isMobile }) => {
  const { proactiveChat } = useContext(ProactiveChatContext);

  const [, isLoadingVendors, errorLoadingVendors] = useVendors();
  const [isLoadingLocale] = useLocale();

  const wrapperEl = useRef<HTMLDivElement>(null);

  useResizeIframe(wrapperEl);
  const proactiveChatLoadingOrError =
    !proactiveChat ||
    !proactiveChat.initResponse ||
    isLoadingVendors ||
    isLoadingLocale ||
    errorLoadingVendors;
  const [startAnimation, setStartAnimation] = useState(false);
  useEffect(() => {
    if (!proactiveChatLoadingOrError) {
      setTimeout(() => setStartAnimation(true), 250);
    }
  }, [proactiveChatLoadingOrError]);
  if (proactiveChatLoadingOrError) return null;

  return (
    <div
      ref={wrapperEl}
      class={classNames(styles.proactivePopupWrapper, {
        [styles.mobile]: isMobile,
      })}
    >
      <CloseProactiveChatButton className={styles.showCloseButton} />
      <ProactiveChatMessage
        initialMessages={proactiveChat?.initResponse?.initialMessages}
        className={classNames({
          [proactiveChatMessageStyles.fadeIn]: startAnimation,
        })}
      />
      <ProactiveChatInput isMobile={isMobile} show={startAnimation} />
    </div>
  );
};

export default ProactivePopup;

import { getMarked } from 'widget_main/vendors/hooks';

export const replaceLinks = (html) => {
  return html.replace(/^<a /, '<a target="_blank" rel="noreferrer noopener" ');
};

export const getHTMLFromMarkdown = (
  markdown: string,
  markedOptions?: marked.MarkedOptions,
) => {
  const marked = getMarked();

  if (!marked) return '';

  const options = markedOptions || {};

  try {
    const renderer = new marked.Renderer();
    const linkRenderer = renderer.link;
    renderer.link = (href, title, text) => {
      const html = linkRenderer.call(renderer, href, title, text);
      return replaceLinks(html);
    };

    const formattedMarkdown = markdown
      .replace(/\\_/g, '_')
      .replace(/\\-/g, '-');

    const outputHTML = marked(formattedMarkdown, {
      renderer,
      ...options,
    });

    return outputHTML;
  } catch (error) {
    return markdown;
  }
};

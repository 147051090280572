// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".titleBarButton__titleBarButton___2zJQU{cursor:pointer;border-radius:8px;padding:2px;display:flex;justify-content:center;align-items:center;color:var(--header-font-secondary-color);background-color:rgba(0,0,0,0)}.titleBarButton__titleBarButton___2zJQU:hover{background-color:rgba(118,118,118,.1)}.titleBarButton__titleBarButton___2zJQU:focus{background-color:rgba(118,118,118,.1)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"titleBarButton": "titleBarButton__titleBarButton___2zJQU"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".closeProactiveChatButton__closeProactiveChatButton___SfQp\\+{padding:2px;border-radius:30px;border:none;display:flex;align-items:center;justify-content:center;transform:translateY(40px);margin-bottom:-40px;opacity:0;transition:transform 700ms,opacity 700ms,margin 1ms 700ms}.closeProactiveChatButton__closeProactiveChatButtonWrapper___iD2uB{overflow:hidden;align-self:flex-end}@media all and (-ms-high-contrast: none){.closeProactiveChatButton__closeProactiveChatButton___SfQp\\+{margin-bottom:0;transform:none;transition:opacity 700ms}}html[dir=\"rtl\"] .closeProactiveChatButton__closeProactiveChatButtonWrapper___iD2uB{align-self:flex-start}body.align-chat-icon-left .closeProactiveChatButton__closeProactiveChatButtonWrapper___iD2uB{align-self:flex-start}html[dir=\"rtl\"] body.align-chat-icon-left .closeProactiveChatButton__closeProactiveChatButtonWrapper___iD2uB{align-self:flex-end}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"closeProactiveChatButton": "closeProactiveChatButton__closeProactiveChatButton___SfQp+",
	"closeProactiveChatButtonWrapper": "closeProactiveChatButton__closeProactiveChatButtonWrapper___iD2uB"
};
export default ___CSS_LOADER_EXPORT___;

import { KustomerCoreWindow, KustomerCore } from 'core/types';
import { ConversationEntity, MessagesState } from 'widget_main/store/types';
import * as keyboardEventHelpers from 'widget_main/globals/keyboardEvents';
import {
  KustomerWindow,
  Kustomer,
  KustomerWidgetEventTypes,
} from 'widget/types';
import { SettingsAttributes } from 'core_main/api/settings/types';
import { MessageCallbackResponse } from 'core_main/sdk/send_message/types';
import { InitAssistantCallbackResponse } from 'core_main/sdk/init_assistant/types';
import { Message } from 'widget_main/components/ChatMessage/types';
import { KustomerLocalStorage } from './types';

export const getItemFromLocalStorage = (key: string) => {
  return window.localStorage?.getItem(key);
};

export const setItemInLocalStorage = (key: string, value: string) => {
  window.localStorage?.setItem(key, value);
};

export const getKustomerCore = () => {
  const kustomerCoreWindow = window as KustomerCoreWindow;
  return kustomerCoreWindow.KustomerCore as KustomerCore;
};

export const getKustomer = () => {
  const kustomerWindow = window.parent as KustomerWindow;
  return kustomerWindow.Kustomer as Kustomer;
};

export const handleMouseClick = () => {
  document.getElementById('root')?.classList.remove('userIsTabbing');
  window.removeEventListener('mousedown', handleMouseClick);

  // eslint-disable-next-line no-use-before-define
  window.addEventListener('keydown', handleFirstTab);
};

export const handleFirstTab = (e: KeyboardEvent) => {
  if (keyboardEventHelpers.isTabKey(e)) {
    document.getElementById('root')?.classList.add('userIsTabbing');
    window.removeEventListener('keydown', handleFirstTab);

    window.addEventListener('mousedown', handleMouseClick);
  }
};

export const getKustomerLocalStorage = <K extends keyof KustomerLocalStorage>(
  key: K,
) => {
  const kustomerState = JSON.parse(
    getItemFromLocalStorage('kustomerState') || '{}',
  ) as KustomerLocalStorage;
  return kustomerState[key];
};

export const setKustomerLocalStorage = (
  key: keyof KustomerLocalStorage,
  value,
) => {
  const kustomerState = JSON.parse(
    getItemFromLocalStorage('kustomerState') || '{}',
  ) as KustomerLocalStorage;

  setItemInLocalStorage(
    'kustomerState',
    JSON.stringify({
      ...kustomerState,
      [key]: value,
    }),
  );
};

export const runKustomerListenersForEvent = (
  eventType: KustomerWidgetEventTypes,
  response?,
  error?: Error,
) => {
  getKustomer()?.listeners?.[eventType]?.forEach((callback) => {
    callback(response, error);
  });
};

export const getLastMessageInConversation = (
  conversation: ConversationEntity,
  messagesState: MessagesState,
) => {
  const { messages } = conversation;

  if (!messages?.length) {
    return undefined;
  }

  const lastMessageId = messages[messages.length - 1];

  return messagesState.messageData[lastMessageId];
};

export const getLastAgentMessageInConversation = (
  conversation?: ConversationEntity,
  messagesState?: MessagesState,
) => {
  if (!conversation?.messages || !messagesState) return undefined;

  const { messages } = conversation;

  const agentMessageId = messages
    .slice()
    .reverse()
    .find(
      (messageId) =>
        messagesState.messageData?.[messageId]?.sentBy?.type === 'user',
    );

  if (agentMessageId) {
    return messagesState.messageData[agentMessageId];
  }

  return undefined;
};

export const getMessageAgentInformation = (
  messageSentBy?:
    | MessageCallbackResponse['sentBy']
    | Message['sentBy']
    | ConversationEntity['lastResponder'],
  settings?: SettingsAttributes,
  initAssistantPayload?: InitAssistantCallbackResponse,
) => {
  return {
    avatarUrl:
      messageSentBy?.avatarUrl ||
      initAssistantPayload?.attributes?.avatarUrl ||
      settings?.teamIconUrl,
    displayName:
      messageSentBy?.displayName ||
      initAssistantPayload?.attributes?.publicName ||
      initAssistantPayload?.attributes?.name ||
      settings?.teamName,
  };
};

export const getLastMessagePreview = (conversation: ConversationEntity) => {
  const { satisfaction, lastMessageAt, preview } = conversation;

  const satisfactionTimetoken = satisfaction?.timetoken;
  const satisfactionIntroduction = satisfaction?.form?.introduction;

  const isSatisfactionLater =
    satisfactionTimetoken &&
    lastMessageAt &&
    lastMessageAt < satisfactionTimetoken;

  return isSatisfactionLater ? satisfactionIntroduction : preview;
};

export const truncate = (input, length) =>
  input.length > length ? `${input?.substring(0, length)}...` : input;

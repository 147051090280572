import { h, JSX, RefObject } from 'preact';
import { createPortal } from 'preact/compat';
import { useEffect, useRef } from 'preact/hooks';
import classNames from 'classnames';
import CloseIcon from 'widget_main/globals/assets/close.svg';
import { FocusableElement, useFocusLock } from 'widget_main/globals/dom';
import { MODAL_ROOT_ID } from 'widget_main/globals/constants';
import { isEscKey } from 'widget_main/globals/keyboardEvents';
import { useTranslations } from 'i18n/hooks';
import TitleBarButton from '../../TitleBarButton';
import styles from './modal.scss';
import messages from './messages';

interface ModalProps {
  show: boolean;
  closeButton?: boolean;
  onHide?: () => void;
  children?: JSX.Element | JSX.Element[] | string;
  className?: string;
  bodyClassName?: string;
  initialFocusRef?: RefObject<FocusableElement>;
  ariaLabelledby?: string;
  ariaLabel?: string;
}

const Modal = ({
  show,
  closeButton,
  onHide,
  className,
  bodyClassName,
  initialFocusRef,
  ariaLabelledby,
  ariaLabel,
  children,
}: ModalProps) => {
  const translations = useTranslations(messages);
  const modalRoot = document.getElementById(MODAL_ROOT_ID);
  const dialogRef = useRef<HTMLDivElement>(null);

  const focusLockRef = useFocusLock();

  const hideOnEscape = (e: KeyboardEvent) => {
    if (isEscKey(e)) {
      if (typeof onHide === 'function') onHide();
    }
  };

  useEffect(() => {
    if (show) {
      if (initialFocusRef) {
        initialFocusRef.current?.focus();
      } else {
        dialogRef.current?.focus();
      }
    }
  }, [show]);

  const renderCloseButton = () => {
    if (!closeButton) {
      return null;
    }

    return (
      <TitleBarButton
        ariaLabel={translations.closeModalAriaLabel}
        onClick={onHide}
        className={styles.closeButton}
      >
        <CloseIcon width={20} height={20} />
      </TitleBarButton>
    );
  };

  if (!show || !modalRoot) {
    return null;
  }
  return createPortal(
    // should be ok to ignore these warning because the container is only designed to capture any outside
    // events, not to serve as an interactable element itself.
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={focusLockRef}
      onClick={onHide}
      onKeyDown={hideOnEscape}
      className={styles.container}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
      <div
        role="dialog"
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledby}
        onClick={(e) => {
          // hack to prevent modal from closing due to click handler on parent
          e.stopPropagation();
        }}
        className={classNames(styles.modal, className)}
      >
        {renderCloseButton()}
        <div
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={-1}
          ref={dialogRef}
          className={classNames(styles.body, bodyClassName)}
        >
          {children}
        </div>
      </div>
    </div>,
    modalRoot,
  );
};

export default Modal;

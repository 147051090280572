// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".titleBarButtons__button___HyLvl{margin:0 0 0 5px}.titleBarButtons__backButton___K\\+RyP{position:absolute;margin:25px 15px}.titleBarButtons__right___nRidl{position:absolute;top:0;right:0;padding:15px;display:flex}.titleBarButtons__endChatQuestion___R2pAd{padding:10px;text-align:center}.titleBarButtons__modalButton___O-\\+kk{font-weight:500;height:40px;margin-top:15px}.titleBarButtons__cancelButton___Yvcuz{color:#717070}html[dir=\"rtl\"] .titleBarButtons__button___HyLvl{margin:0 5px 0 0}html[dir=\"rtl\"] .titleBarButtons__backButton___K\\+RyP{transform:rotate(180deg);margin:25px 15px}html[dir=\"rtl\"] .titleBarButtons__right___nRidl{left:0;right:initial}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"button": "titleBarButtons__button___HyLvl",
	"backButton": "titleBarButtons__backButton___K+RyP",
	"right": "titleBarButtons__right___nRidl",
	"endChatQuestion": "titleBarButtons__endChatQuestion___R2pAd",
	"modalButton": "titleBarButtons__modalButton___O-+kk",
	"cancelButton": "titleBarButtons__cancelButton___Yvcuz"
};
export default ___CSS_LOADER_EXPORT___;

import { h } from 'preact';
import { useState } from 'preact/hooks';
import classNames from 'classnames';
import { useTranslations } from 'i18n/hooks';
import ChevronRight from 'widget_main/globals/assets/chevronRight.svg';
import ChevronLeft from 'widget_main/globals/assets/chevronLeft.svg';
import Modal from 'widget_main/components/ui/Modal';
import * as keyboardEventHelpers from 'widget_main/globals/keyboardEvents';
import TitleBarButton from 'widget_main/components/TitleBarButton';
import { MLLChild } from 'core_main/api/messages/types';
import styles from './mllModal.scss';
import { buildPath } from './helpers';

interface MLLModalProps {
  show: boolean;
  onHide: () => void;
  selectedMLLChild: MLLChild;
  onSubmitMLLReply: (mllChild: MLLChild, mllPath: string) => void;

  disabled?: boolean;
}

const MLLModal = ({
  show,
  onHide,
  selectedMLLChild,
  onSubmitMLLReply,
  disabled,
}: MLLModalProps) => {
  const translations = useTranslations({}, ['goBack']);

  const [currentMLL, setCurrentMLL] = useState<MLLChild | null>(
    selectedMLLChild,
  );
  const [prevMLLs, updatePrevMLLs] = useState<MLLChild[]>([] as MLLChild[]);

  const handleClickOption = (mllChild) => {
    const mllPath = buildPath(mllChild, currentMLL, prevMLLs);

    if (disabled) return undefined;

    if (!mllChild.children) {
      return onSubmitMLLReply(mllChild, mllPath);
    }

    updatePrevMLLs(prevMLLs.concat(currentMLL as MLLChild));

    return setCurrentMLL(mllChild);
  };

  const handleKeyPress = (e: KeyboardEvent, mllChild) => {
    if (keyboardEventHelpers.isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();

      handleClickOption(mllChild);
    }
  };

  const handleGoBack = () => {
    const prevMLL = prevMLLs[prevMLLs.length - 1];

    setCurrentMLL(prevMLL);

    const newPrevMLLs = prevMLLs.slice();
    newPrevMLLs.pop();

    updatePrevMLLs(newPrevMLLs);
  };

  const renderBackButton = () => {
    if (!prevMLLs.length) {
      return null;
    }
    return (
      <TitleBarButton
        className={styles.headerBackButton}
        ariaLabel={translations.goBack}
        onClick={handleGoBack}
      >
        <ChevronLeft />
      </TitleBarButton>
    );
  };

  return (
    <Modal
      show={show}
      closeButton
      onHide={onHide}
      className={styles.modal}
      bodyClassName={styles.modalBody}
    >
      <div className={styles.header}>
        {renderBackButton()}
        {currentMLL?.displayName} ({currentMLL?.children?.length})
      </div>
      <div className={styles.mllOptions}>
        {currentMLL?.children?.map((mllChild) => {
          return (
            <div
              disabled={disabled}
              key={mllChild.displayName}
              tabIndex={0}
              role="button"
              onKeyPress={(e) => handleKeyPress(e, mllChild)}
              onClick={() => handleClickOption(mllChild)}
              className={classNames(styles.mllOption, {
                [styles.disabled]: disabled,
              })}
            >
              <div className={styles.label}>{mllChild.displayName}</div>
              {mllChild.children && (
                <ChevronRight className={styles.mllChevron} />
              )}
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default MLLModal;

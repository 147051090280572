import { h } from 'preact';
import { useContext } from 'preact/hooks';
import classNames from 'classnames';
import { InitialMessage } from 'core_main/api/assistant/types';
import * as keyboardEventHelpers from 'widget_main/globals/keyboardEvents';

import TemplateMessage from 'widget_main/components/ChatMessage/TemplateMessage/TemplateMessage';
import { ProactiveChatContext } from 'widget_main/store';
import styles from './proactiveChatMessage.scss';

interface ProactiveChatMessageProps {
  initialMessages?: InitialMessage[];
  className?: string;
}

const ProactiveChatMessage = ({
  initialMessages,
  className,
}: ProactiveChatMessageProps) => {
  const { proactiveChat, handoffToMessageThread, onCloseProactiveChat } =
    useContext(ProactiveChatContext);
  if (!initialMessages) return null;

  const handleClick = () => {
    if (proactiveChat?.initResponse) {
      handoffToMessageThread(
        proactiveChat.initResponse?.assistant,
        undefined,
        undefined,
        proactiveChat.initResponse,
        null,
        (/* result, error */) => {
          onCloseProactiveChat();
        },
      );
    }
  };
  const handleKeyPress = (e: KeyboardEvent) => {
    if (keyboardEventHelpers.isEnterKey(e)) {
      e.preventDefault();
      e.stopPropagation();

      if (handleClick) {
        handleClick();
      }
    }
  };
  const renderMessages = (messages) => {
    return messages.map((initialMessage) => {
      const message = {
        ...initialMessage,
        createdAt: initialMessage.importedAt,
        sentBy: {
          type: 'user',
        },
      };
      return (
        <TemplateMessage
          showAvatar={false}
          showTimestamp={false}
          message={message}
          key={message?.id}
          truncateLength={200}
          chatBubbleInnerClassName={classNames(styles.chatBubble)}
        />
      );
    });
  };

  return (
    <div
      className={classNames(styles.proactiveChatMessage, className)}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      role="button"
      tabIndex={0}
    >
      {renderMessages(initialMessages)}
    </div>
  );
};

export default ProactiveChatMessage;

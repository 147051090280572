import { captureException } from './sentry';

export interface ErrorProps {
  message?: string;
  tags?: Record<string, any>;
}

export const handleError = (error, { message, tags }: ErrorProps = {}) => {
  console.error(message, error);

  return captureException(error, {
    tags: {
      errorBoundary: false,
      ...tags,
    },
  });
};

import { h, Fragment } from 'preact';
import classNames from 'classnames';
import {
  useContext,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'preact/hooks';
import {
  NavigationContext,
  ConversationsContext,
  MessagesContext,
  HideNewConversationButtonSettingsContext,
  SettingsContext,
  ConnectionContext,
  ChatAvailabilityContext,
} from 'widget_main/store';

import {
  getKustomerCore,
  setKustomerLocalStorage,
  runKustomerListenersForEvent,
  getKustomer,
} from 'widget_main/globals/helpers';
import NewConversationButton from 'widget_main/components/NewConversationButton';

import * as helpers from 'widget_main/components/TimeAgo/helpers';
import { useTranslations } from 'i18n/hooks';
import {
  UserTypingResponse,
  UserAttributesWithTypingOptions,
} from 'core_main/pubnub/types';
import { getMappedLanguage } from 'i18n/helpers';
import {
  MESSAGE_THREAD_PATH,
  OFFLINE,
  kustomerWidgetEventTypes,
} from 'widget_main/globals/constants';
import { OptimisticMessage } from 'widget_main/store/types';
import { PublicCallback } from 'globals/types';
import {
  useIntersectionObserver,
  usePageVisibility,
  useTouchMove,
} from 'widget_main/globals/hooks';
import { TemplateType } from 'core_main/api/messages/types';
import { isIntersectionObserverUnmounted } from 'widget_main/globals/dom';
import ScrollToCurrent from './ScrollToCurrent';
import ChatMessage from '../../components/ChatMessage';
import MessageThreadInput from '../../components/MessageThreadInput';
import TemplateActions from './TemplateActions';
import { CHAT_MESSAGE_THREAD_ID } from './constants';
import {
  createOptimisticMessage,
  isLastMessageFromCustomer,
  getMessageIdsToMarkAsRead,
} from './helpers';
import * as templateActionHelpers from './TemplateActions/helpers';
import styles from './messageThread.scss';
import { MessageOptions } from '../../components/MessageThreadInput/types';

import defaultMessages from './messages';
import Loader from '../../components/ui/Loader';

const kustomerCore = getKustomerCore();

interface MessageThreadProps {
  isMobile: boolean;
}

const MessageThread = ({ isMobile }: MessageThreadProps) => {
  const chatSettings = useContext(SettingsContext);
  const navigation = useContext(NavigationContext);
  const threadRef = useRef<HTMLDivElement>(null);

  useTouchMove(threadRef?.current);

  const hideNewConversationButtonSettings = useContext(
    HideNewConversationButtonSettingsContext,
  );

  const chatAvailability = useContext(ChatAvailabilityContext);
  const conversations = useContext(ConversationsContext);
  const messages = useContext(MessagesContext);

  const { handoff, draft } = messages;
  const handoffCallback = handoff?.callback;

  const connection = useContext(ConnectionContext);

  const translations = useTranslations(defaultMessages);

  const { currentConversationId, initAssistantPayload } = navigation;

  const getCurrentConversation = useCallback(() => {
    if (currentConversationId) {
      return conversations?.data?.[currentConversationId];
    }

    return undefined;
  }, [conversations, currentConversationId]);

  let satisfaction;

  if (getCurrentConversation()) {
    satisfaction = getCurrentConversation()?.satisfaction;
  }

  const conversation = getCurrentConversation();

  const getConversationMessages = useCallback(() => {
    if (!currentConversationId || !conversation) return null;

    return conversation.messages.map((id) => messages.messageData[id]);
  }, [currentConversationId, conversation, messages.messageData]);

  const [userTyping, setUserTyping] = useState<
    UserAttributesWithTypingOptions | undefined
  >(undefined);

  const [isCreatingConversation, setIsCreatingConversation] = useState(false);

  const [forceUpdateState, forceUpdate] = useState<Maybe<unknown>>();
  const [fetchingHistory, setFetchingHistory] = useState(false);
  const [isAtBottomOfThread, setIsAtBottomOfThread] = useState(true);
  const [showScrollToCurrent, setShowScrollToCurrent] = useState(false);
  const [showNewMessagesText, setShowNewMessagesText] = useState(false);
  const [messageCount, setMessageCount] = useState({
    messageTotal: 0,
    newMessages: 0, // not currently used, but future proofing in case it is needed
  });
  const [showNewMessageDividerIndex, setShowNewMessageDividerIndex] =
    useState(-1);
  const [isThreadRendered, setIsThreadRendered] = useState<boolean>(false);

  const topOfChatRef = useRef<HTMLDivElement>(null);
  const bottomOfChatRef = useRef<HTMLDivElement>(null);

  const handleOnline = useCallback(() => {
    if (currentConversationId) {
      conversations
        .fetchMessageHistory(currentConversationId, conversations.data)
        .catch(forceUpdate);
    }
  }, [currentConversationId]);

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  const bottomRefIntersection = useIntersectionObserver(bottomOfChatRef, {
    rootMargin: '36px',
    root: threadRef?.current,
  });
  useEffect(() => {
    // when sending messages, the rerender of the message thread causes useIntersectionObserver to return an unmounted
    //   intersection observer which creates a false negative when checking if we are at the bottom of the thread
    if (
      bottomRefIntersection &&
      !isIntersectionObserverUnmounted(bottomRefIntersection)
    ) {
      const isIntersecting = Boolean(bottomRefIntersection?.isIntersecting);
      setIsAtBottomOfThread(isIntersecting);
    }
  }, [bottomRefIntersection]);

  const fetchingInitialMessages =
    !conversation?.messages.length && fetchingHistory;

  const getInitialMessages = useCallback(() => {
    const currentConversation = getCurrentConversation();

    const conversationInitialMessages = currentConversation?.initialMessages;

    const assistantInitialMessages = initAssistantPayload?.initialMessages;

    if (!currentConversationId && assistantInitialMessages) {
      return assistantInitialMessages;
    }

    if (currentConversationId && conversationInitialMessages) {
      return conversationInitialMessages;
    }

    return undefined;
  }, [initAssistantPayload, currentConversationId, getCurrentConversation]);

  const sendMessage = useCallback(
    (
      sendMessageParams,
      currentOptimisticMessage?: OptimisticMessage,
      callback?: PublicCallback,
    ) => {
      const messageParamsConversationId = sendMessageParams.conversationId;
      let optimisticMessage = currentOptimisticMessage;

      if (optimisticMessage) {
        conversations.toggleOptimisticMessageError(
          messageParamsConversationId,
          optimisticMessage.messageId,
          false,
        );
      }

      if (!currentOptimisticMessage) {
        optimisticMessage = createOptimisticMessage(sendMessageParams);

        conversations.addOptimisticMessage(
          messageParamsConversationId,
          optimisticMessage,
        );
      }

      return kustomerCore.sendMessage(sendMessageParams, (response, error) => {
        if (optimisticMessage) {
          if (error) {
            conversations.toggleOptimisticMessageError(
              messageParamsConversationId,
              optimisticMessage.messageId,
              true,
            );
          } else {
            conversations.removeOptimisticMessage(
              messageParamsConversationId,
              optimisticMessage.messageId,
            );
          }
        }

        if (typeof callback === 'function') callback(response, error);

        return messages.receiveMessage(response, error);
      });
    },
    [conversations, messages],
  );

  const runHandoffCallback = useCallback(
    (response?, error?) => {
      const isFunction = typeof handoffCallback === 'function';

      if (!handoffCallback || !isFunction) return;

      handoffCallback(response, error);
    },
    [handoffCallback],
  );

  const handleSubmit = useCallback(
    (messageOptions: MessageOptions) => {
      const initialMessages = getInitialMessages();
      const assistantOptions = getKustomer()?.startParameters?.assistantOptions;

      const messagePayload = {
        ...messageOptions,
        lang: getMappedLanguage(),
      };

      if (!navigation.currentConversationId) {
        setIsCreatingConversation(true);

        const title = messageOptions.body?.substring(0, 256);

        const assistantId =
          messages?.handoff?.assistantId || initAssistantPayload?.assistant;

        return kustomerCore.createConversation(
          {
            title,
            assistant: assistantId,
            brand: chatSettings.brandId,
            lang: getMappedLanguage(),
            custom: messageOptions?.custom,
          },
          (response, error) => {
            kustomerCore.sendPresenceActivity({ presence: 'online' });

            if (error) {
              runHandoffCallback(response, error);
              setIsCreatingConversation(false);
              conversations.refreshConversations();
              // put an error in state to trigger the ErrorBoundary (async errors aren't caught)
              forceUpdate(new Error(error));
              return;
            }

            const { conversationId, isInAssistantMode } = response;

            conversations.addConversation(response, initialMessages);

            navigation.updateCurrentConversationId(conversationId);

            const sendMessageParams = {
              ...messagePayload,
              conversationId,
              isInAssistantMode,
              initAssistantPayload,
              assistantOptions,
            };

            sendMessage(sendMessageParams, undefined, (_, error) => {
              if (error) {
                conversations.refreshConversations();
                // put an error in state to trigger the ErrorBoundary (async errors aren't caught)
                forceUpdate(new Error(error));
              }
              setIsCreatingConversation(false);
              runKustomerListenersForEvent(
                kustomerWidgetEventTypes.onConversationCreate,
                response,
                error,
              );
              runHandoffCallback(response, error);
            });
          },
        );
      }

      const { currentConversationId } = navigation;

      const sendMessageParams = {
        ...messagePayload,
        conversationId: currentConversationId,
        isInAssistantMode: getCurrentConversation()?.isInAssistantMode,
        assistantOptions,
      };

      return sendMessage(sendMessageParams);
    },
    [
      getInitialMessages,
      navigation,
      getCurrentConversation,
      sendMessage,
      messages?.handoff?.assistantId,
      initAssistantPayload,
      chatSettings.brandId,
      conversations,
      runHandoffCallback,
    ],
  );

  useEffect(() => {
    setKustomerLocalStorage('lastOpenPage', MESSAGE_THREAD_PATH);
    const interval = setInterval(() => {
      forceUpdate({});
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const previousConversationMessageCount = useRef(0);

  useLayoutEffect(() => {
    const chatMessageThread = document.querySelector(CHAT_MESSAGE_THREAD_ID);
    const conversationMessages = getConversationMessages();
    // this hook runs on message changes and scrolls to the bottom of the latest message if the end-customer has not scrolled up
    if (conversation?.messages.length && !fetchingHistory) {
      if (chatMessageThread && isAtBottomOfThread) {
        chatMessageThread.scrollTop = chatMessageThread.scrollHeight;
      }
    }

    // if messages are loaded and the end user has scrolled up
    setShowScrollToCurrent(
      Boolean(chatMessageThread?.scrollTop !== 0 && !isAtBottomOfThread),
    );

    // if we receive a new message, set the new message text that appears if the customer is scrolled up in the thread to true
    if (
      !fetchingHistory &&
      !isAtBottomOfThread &&
      conversationMessages?.length &&
      conversationMessages?.length > messageCount.messageTotal
    ) {
      if (
        messageCount.messageTotal > 0 &&
        conversationMessages[conversationMessages.length - 1].direction ===
          'out'
      ) {
        setMessageCount({
          messageTotal: conversationMessages.length,
          newMessages: messageCount.newMessages + 1,
        });
        setShowNewMessagesText(true);
        setShowNewMessageDividerIndex(
          conversationMessages.length - (messageCount.newMessages + 1),
        );
      }
    } else {
      // if user has reached the bottom, we can assume they've seen all new messages
      //  so reset the message counter & new message text (but not the new message divider)
      // the timeout is to ensure the New Messages text does not disappear before
      //  the isAtBottomOfThread css animation finishes
      setTimeout(() => {
        setMessageCount({
          messageTotal: conversationMessages?.length || 0,
          newMessages: 0,
        });
        setShowNewMessagesText(false);
      }, 250);
    }

    if (
      !isThreadRendered &&
      conversationMessages?.some((m) => m.direction === 'out')
    ) {
      setIsThreadRendered(true);
    }

    // when showing the new message divider, we only want to clear the divider when a new message is sent or received
    //  and the end user is at the bottom of the thread
    if (
      isAtBottomOfThread &&
      conversationMessages &&
      conversationMessages.length > 0 &&
      conversationMessages.length === previousConversationMessageCount.current
    ) {
      setShowNewMessageDividerIndex(-1);
    }
    previousConversationMessageCount.current =
      (conversationMessages?.length || 0) + 1;
  }, [conversation?.messages, isAtBottomOfThread, connection, fetchingHistory]);

  useEffect(() => {
    setIsThreadRendered(false);
  }, [conversation?.conversationId]);

  useEffect(() => {
    // this hook loads messages on initial load
    if (currentConversationId) {
      setFetchingHistory(true);
      conversations
        .fetchMessageHistory(currentConversationId, conversations.data)
        .catch(forceUpdate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // this hook clears the loading ui when messages are received
    if (conversation?.messages?.length || conversation?.hasAllMessages)
      setFetchingHistory(false);
  }, [conversation?.hasAllMessages, conversation?.messages]);

  useEffect(() => {
    if (
      messages.handoff?.message &&
      !currentConversationId &&
      !isCreatingConversation
    ) {
      handleSubmit({
        body: messages.handoff?.message,
        payload: messages.handoff?.payload,
        custom: messages.handoff?.custom,
      });
      return;
    }

    if (!messages.handoff?.message) {
      runHandoffCallback();
    }
  }, [
    runHandoffCallback,
    messages.handoff,
    handleSubmit,
    currentConversationId,
    isCreatingConversation,
  ]);
  const pageIsVisible = usePageVisibility();
  useEffect(() => {
    if (navigation.currentConversationId && pageIsVisible) {
      setKustomerLocalStorage(
        'lastOpenConversationId',
        navigation.currentConversationId,
      );

      return kustomerCore.markRead({
        conversationId: navigation.currentConversationId,
        messageIds: getMessageIdsToMarkAsRead(
          navigation.currentConversationId,
          messages.messageData,
        ),
      });
    }

    return undefined;
  }, [
    navigation.currentConversationId,
    messages.messageData,
    satisfaction,
    pageIsVisible,
  ]);

  useEffect(() => {
    if (!navigation.currentConversationId) return;

    const handleAgentTypingActivity = (response: UserTypingResponse) => {
      const { user, typing, conversationId, typingOpts } = response;

      if (conversationId !== navigation.currentConversationId) return;

      if (typing) {
        setUserTyping({ ...user, ...typingOpts });
        return;
      }

      setUserTyping(undefined);
    };

    kustomerCore.addListener(
      'onAgentTypingActivity',
      handleAgentTypingActivity,
    );

    // eslint-disable-next-line consistent-return
    return () => {
      kustomerCore.removeListener(
        'onAgentTypingActivity',
        handleAgentTypingActivity,
      );
    };
  }, [navigation.currentConversationId]);

  if (forceUpdateState instanceof Error) {
    // this forces the ErrorBoundary to display the error put in state by an async process
    throw forceUpdateState;
  }
  const isInputHidden = () => {
    const convo = getCurrentConversation();
    const conversationMessages = getConversationMessages();
    const initialMessages = getInitialMessages();

    if (
      convo?.isInAssistantMode &&
      (isLastMessageFromCustomer(conversationMessages) ||
        convo?.optimisticMessages?.length)
    ) {
      return true;
    }

    const lastMessageWithTemplateAction = conversationMessages?.length
      ? templateActionHelpers.getLastMessageWithTemplateAction(
          conversationMessages,
        )
      : templateActionHelpers.getLastMessageWithTemplateAction(initialMessages);

    if (lastMessageWithTemplateAction) {
      if (
        lastMessageWithTemplateAction?.meta?.template?.meta?.lockInput === false
      ) {
        return false;
      }
      const templateActionReplyMessage =
        templateActionHelpers.getLastTemplateActionOptimisticReply(
          lastMessageWithTemplateAction,
          getCurrentConversation()?.optimisticMessages,
        ) ||
        templateActionHelpers.getLastTemplateActionReply(
          lastMessageWithTemplateAction,
          conversationMessages,
        );

      if (!templateActionReplyMessage) {
        return true;
      }
    }

    return false;
  };

  const handleRetrySend = (optimisticMessage) => {
    const conversation = getCurrentConversation();

    if (!conversation) {
      return null;
    }
    const { conversationId, isInAssistantMode } = conversation;
    const { attachments, initAssistantPayload } = optimisticMessage;

    const sendMessagePayload = {
      ...optimisticMessage,
      conversationId,
      messageId: undefined,
      attachments: attachments?.map((attachment) => {
        return {
          file: attachment.file,
        };
      }),
      initAssistantPayload: isInAssistantMode
        ? initAssistantPayload
        : undefined,
      assistantOptions: getKustomer()?.startParameters?.assistantOptions,
    };

    return sendMessage(sendMessagePayload, optimisticMessage);
  };

  const renderOptimisticMessages = () => {
    const { currentConversationId } = navigation;

    const conversation = getCurrentConversation();

    if (!currentConversationId || conversation?.ended) {
      return null;
    }

    const { optimisticMessages } = conversations.data[currentConversationId];

    return (
      <Fragment>
        {optimisticMessages.map((message) => {
          return (
            <ChatMessage
              key={message.messageId}
              message={message}
              handleRetrySend={handleRetrySend}
            />
          );
        })}
      </Fragment>
    );
  };

  const renderAssistantInitialMessages = () => {
    const initialMessages = getInitialMessages();
    if (!initialMessages?.length) return null;

    return initialMessages?.map((initialMessage, index) => {
      const isLastMessage = initialMessages.length - 1 === index;

      const message = {
        ...initialMessage,
        createdAt: initialMessage.importedAt,
        sentBy: {
          type: 'user',
          avatarUrl: initAssistantPayload?.attributes?.avatarUrl,
          displayName: initAssistantPayload?.attributes?.publicName,
        },
      };

      return (
        <ChatMessage
          key={message.createdAt}
          message={message}
          showAvatar={isLastMessage}
          showBotIdentifier={chatSettings.showBotIdentifier}
        />
      );
    });
  };

  const renderThread = () => {
    const conversationMessages = getConversationMessages();
    const initialMessages = getInitialMessages();

    if (!conversationMessages) return null;

    let messagesToRender = conversationMessages;

    if (initialMessages?.length) {
      const initialMessageTemplateIds = initialMessages?.map(
        (initialMessage) => {
          return initialMessage.meta.template.id;
        },
      );

      messagesToRender = messagesToRender?.filter((message) => {
        return (
          !message?.meta?.template?.id ||
          !initialMessageTemplateIds?.includes(message.meta.template?.id)
        );
      });
    }

    return (
      <Fragment>
        {messagesToRender?.length ? <div ref={topOfChatRef} /> : null}
        {messagesToRender?.map((currentMessage, index) => {
          const nextMessage = messagesToRender[index + 1];

          const isLastInStreak =
            !nextMessage ||
            currentMessage.sentBy.type !== nextMessage.sentBy.type;

          const showTimestamp =
            !nextMessage ||
            helpers.getTimeDifference(
              currentMessage.createdAt,
              nextMessage.createdAt,
            ) >
              10 * helpers.MINUTE;

          const showAIIdentifier =
            currentMessage?.meta?.template?.meta?.isAiResponse === true;

          return (
            <ChatMessage
              key={currentMessage.messageId}
              message={currentMessage}
              showAvatar={isLastInStreak || showTimestamp}
              showTimestamp={showTimestamp}
              showBotIdentifier={chatSettings.showBotIdentifier}
              showAIIdentifier={showAIIdentifier}
              showNewMessageDivider={showNewMessageDividerIndex === index}
            />
          );
        })}
        {renderOptimisticMessages()}
      </Fragment>
    );
  };

  const renderGetPreviousMessagesLoader = () => {
    return (
      <div
        className={classNames(styles.loaderWrapper, {
          [styles.showLoader]: fetchingHistory,
        })}
      >
        <Loader className={styles.loader} />
      </div>
    );
  };

  const renderDivider = (message: string) => {
    return (
      <div className={styles.chatDividerContainer}>
        <div className={styles.chatDividerLine} />
        <div className={styles.chatDividerLabel}>{message}</div>
        <div className={styles.chatDividerLine} />
      </div>
    );
  };

  const renderChatEndedDivider = () => {
    const currentConversation = getCurrentConversation();

    if (!currentConversation?.ended || fetchingInitialMessages) return null;

    if (currentConversation.deleted) {
      return renderDivider(translations.chatDeletedLabel);
    }

    return renderDivider(translations.chatEndedLabel);
  };

  const renderChatMovedDivider = () => {
    const conversation = getCurrentConversation();

    if (!conversation?.movedToConversationId || fetchingInitialMessages)
      return null;

    return renderDivider(translations.chatConversationHasMoved);
  };

  const renderTemplateActions = (supportedTypes: TemplateType[]) => {
    const currentConversation = getCurrentConversation();

    if (currentConversation?.ended || fetchingInitialMessages) return null;

    return (
      <TemplateActions
        onSubmit={handleSubmit}
        disabled={connection === OFFLINE || isCreatingConversation}
        supportedTypes={supportedTypes}
        initialMessages={initAssistantPayload?.initialMessages}
      />
    );
  };

  const renderFooter = () => {
    const currentConversation = getCurrentConversation();

    if (currentConversation?.ended) {
      const openConversationFound = Object.values(conversations.data).find(
        (conversation) => !conversation.ended,
      );

      if (
        hideNewConversationButtonSettings.onConversationEnd ||
        (chatSettings.singleSessionChat && openConversationFound)
      ) {
        return null;
      }

      return (
        <div className={styles.createConversationContainer}>
          <NewConversationButton className={styles.createConversationButton} />
        </div>
      );
    }

    if (currentConversation?.movedToConversationId) {
      return (
        <div className={styles.createConversationContainer}>
          <NewConversationButton
            navigateToConversationId={currentConversation.movedToConversationId}
            className={styles.createConversationButton}
          />
        </div>
      );
    }

    return (
      <div className={styles.inputContainer}>
        {renderTemplateActions(['quick_replies', 'deflection', 'mll'])}
        <MessageThreadInput
          onSubmit={handleSubmit}
          conversationId={currentConversationId}
          isInputHidden={isInputHidden()}
          chatAvailability={chatAvailability}
          showAttachmentButton={!chatSettings.disableAttachments}
          isSendDisabled={isCreatingConversation || connection === OFFLINE}
          draft={draft}
        />
      </div>
    );
  };

  const renderTypingIndicator = () => {
    if (!userTyping) return null;

    return <ChatMessage userTyping={userTyping} showAvatar={true} />;
  };

  const renderSatisfaction = () => {
    const conversationMessages = getConversationMessages();

    if (!conversationMessages) return null;

    const lastMessage = conversationMessages[conversationMessages.length - 1];
    const satisfactionTimetoken = satisfaction?.timetoken;

    if (
      satisfactionTimetoken &&
      lastMessage?.createdAt &&
      lastMessage?.createdAt < satisfactionTimetoken
    ) {
      return <ChatMessage satisfaction={satisfaction} />;
    }

    return null;
  };

  return (
    <div
      class={classNames(styles.messageThread, {
        [styles.messageThreadMobile]: isMobile,
      })}
    >
      <div
        aria-live="polite"
        class={classNames(styles.chatMessages, {
          [styles.loading]: fetchingHistory,
        })}
        id={CHAT_MESSAGE_THREAD_ID}
        ref={threadRef}
      >
        {/* this empty div is important for "pin to bottom" functionality. see comment in css */}
        <div>
          {renderGetPreviousMessagesLoader()}
          {renderAssistantInitialMessages()}
          {renderThread()}
          {renderTypingIndicator()}
          {renderChatEndedDivider()}
          {renderChatMovedDivider()}
          {renderSatisfaction()}
          {renderTemplateActions(['list', 'answer_button_feedback'])}
          <ScrollToCurrent
            showScrollToCurrent={showScrollToCurrent}
            showNewMessagesText={showNewMessagesText}
          />
          <div ref={bottomOfChatRef} />
        </div>
      </div>
      {renderFooter()}
    </div>
  );
};

export default MessageThread;

import { h } from 'preact';
import { useRef } from 'preact/hooks';
import * as keyboardEventHelpers from 'widget_main/globals/keyboardEvents';
import AttachmentIcon from 'widget_main/globals/assets/paperClip.svg';
import { useTranslations } from 'i18n/hooks';
import { Attachment } from 'core_main/sdk/send_message/types';
import { ALLOWED_MIME_TYPES, MAX_FILE_SIZE, MAX_TOTAL_SIZE } from './constants';
import styles from './attachmentButton.scss';

import messages from './messages';

interface AttachmentButtonProps {
  disabled?: boolean;
  showError: (errorMessage?: string) => void;
  attachments: Attachment[];
  addAttachment: (file: File) => void;
}

const AttachmentButton = ({
  disabled,
  showError,
  attachments,
  addAttachment,
}: AttachmentButtonProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const translations = useTranslations(messages);

  const getCurrentTotalSize = () => {
    let total = 0;

    attachments.forEach((attachment) => {
      if (attachment.file.size) {
        total += attachment.file.size;
      }
    });

    return total;
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    const fileSize = file?.size || MAX_FILE_SIZE;
    if (!file) {
      return;
    }

    if (!ALLOWED_MIME_TYPES.includes(file.type)) {
      showError(translations.fileTypeError);
      return;
    }

    if (fileSize >= MAX_FILE_SIZE) {
      showError(translations.fileSizeError);
      return;
    }

    if (getCurrentTotalSize() + fileSize >= MAX_TOTAL_SIZE) {
      showError(translations.totalFileSizeError);
      return;
    }

    showError(undefined);

    addAttachment(file);

    // reset value so that we can choose the same file again
    e.target.value = '';
  };

  const handlePromptForFileSelect = (e: MouseEvent | KeyboardEvent) => {
    if (!fileInputRef?.current?.click) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    fileInputRef.current.click();
  };

  const handleKeypressForFileSelect = (e: KeyboardEvent) => {
    if (!keyboardEventHelpers.isEnterKey(e)) {
      return;
    }

    handlePromptForFileSelect(e);
  };

  return (
    <label htmlFor="fileUpload">
      <div
        aria-label={translations.attachmentButtonAriaLabel}
        role="button"
        class={styles.attachmentButton}
        tabIndex={0}
        onClick={handlePromptForFileSelect}
        onKeyPress={handleKeypressForFileSelect}
      >
        <AttachmentIcon class={styles.attachmentIcon} />
      </div>
      <input
        class={styles.fileInput}
        disabled={disabled}
        data-kt="fileUpload"
        id="fileUpload"
        ref={fileInputRef}
        type="file"
        onChange={handleFileChange}
      />
    </label>
  );
};

export default AttachmentButton;

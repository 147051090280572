import { createContext } from 'preact';
import { SettingsAttributes } from 'core_main/api/settings/types';
import { CONVERSATIONS_PATH } from 'widget_main/globals/constants';
import { Availability } from 'core_main/sdk/is_chat_available/types';
import { UnreadCountsState } from 'core_main/types';
import {
  NavigationState,
  WidgetActions,
  ConversationsState,
  MessagesState,
  KBState,
  ConnectionState,
  ProactiveChatState,
} from './types';

export const ChatAvailabilityContext = createContext<Availability | undefined>(
  undefined,
);

export const SettingsContext = createContext({} as SettingsAttributes);
export const HideNewConversationButtonSettingsContext = createContext({
  onChatHistory: false,
  onConversationEnd: false,
});
export const NavigationContext = createContext({
  page: CONVERSATIONS_PATH,
} as NavigationState);

export const WidgetActionsContext = createContext({} as WidgetActions);

export const ConversationsContext = createContext({} as ConversationsState);

export const MessagesContext = createContext({} as MessagesState);

export const UnreadCountsContext = createContext({} as UnreadCountsState);

export const ProactiveChatContext = createContext({} as ProactiveChatState);

export const ConnectionContext = createContext<ConnectionState>('online');

export const KBContext = createContext({} as KBState);

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".messageThreadInput__submitButton___I6zgd{border-radius:50%;width:30px;height:30px;display:flex;justify-content:center;align-items:center}.messageThreadInput__submitButton___I6zgd:not(:enabled){background-color:#717070}.messageThreadInput__messageInput___WEWbK{position:relative;min-height:50px;max-height:250px;background-color:#fff;display:flex;flex-direction:column;flex-shrink:0;box-shadow:0 -1px 6px 0 rgba(201,201,201,.3);color:#717070}.messageThreadInput__messageInput___WEWbK.messageThreadInput__hasError___kppkZ{border-top:1px solid red;border-bottom:1px solid red}.messageThreadInput__messageInput___WEWbK.messageThreadInput__isInputHidden___l0L-9{display:none}.messageThreadInput__messageInput___WEWbK:focus-within{color:inherit;box-shadow:0 -2px 6px 0 rgba(201,201,201,.6)}.messageThreadInput__messageInput___WEWbK:focus-within .messageThreadInput__submitButton___I6zgd{background-color:var(--primary-button-background-color)}.messageThreadInput__messageInput___WEWbK:focus-within .messageThreadInput__submitButton___I6zgd:hover:enabled{background-color:var(--primary-button-background-hover-color)}.messageThreadInput__attachmentsPreview___93BEy{display:flex}.messageThreadInput__lowerSection___J5zCP{width:100%;display:flex;align-items:flex-end;padding:0 10px 0 5px}.messageThreadInput__attachmentButtonWrapper___Vc3Jo{padding-bottom:12px}.messageThreadInput__buttonWrapper___jFkm\\+{padding-bottom:10px}.messageThreadInput__textArea___8Uzlh{flex-grow:1;margin:0 5px;min-height:50px;height:50px;max-height:160px;border:0;padding:16px 5px;line-height:1.2}.messageThreadInput__sendIcon___-ChR9{width:15px;height:15px;margin:0 0 0 2px}html[dir=\"rtl\"] .messageThreadInput__sendIcon___-ChR9{transform:rotate(180deg);margin:0 2px 0 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"white": "#fff",
	"primary": "#222",
	"secondary": "#717070",
	"primaryBlue": "#336dec",
	"primaryDarkerBlue": "#005bcc",
	"widgetFrameWidth": "400px",
	"widgetFrameHeight": "calc(100% - 30px)",
	"widgetFrameMaxHeight": "650px",
	"mobileScreenWidth": "550px",
	"mobileScreenHeight": "550px",
	"widgetMinWidth": "400px",
	"widgetMaxWidth": "720px",
	"submitButton": "messageThreadInput__submitButton___I6zgd",
	"messageInput": "messageThreadInput__messageInput___WEWbK",
	"hasError": "messageThreadInput__hasError___kppkZ",
	"isInputHidden": "messageThreadInput__isInputHidden___l0L-9",
	"attachmentsPreview": "messageThreadInput__attachmentsPreview___93BEy",
	"lowerSection": "messageThreadInput__lowerSection___J5zCP",
	"attachmentButtonWrapper": "messageThreadInput__attachmentButtonWrapper___Vc3Jo",
	"buttonWrapper": "messageThreadInput__buttonWrapper___jFkm+",
	"textArea": "messageThreadInput__textArea___8Uzlh",
	"sendIcon": "messageThreadInput__sendIcon___-ChR9"
};
export default ___CSS_LOADER_EXPORT___;
